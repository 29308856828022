import { useMutation, useQuery } from "@tanstack/react-query";
import { Button } from "flowbite-react";
import { useState } from "react";
import { HiPlus } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import { useAlert } from "../../../_contexts/AlertContext";
import { deleteFaq, getFaqs } from "../../../apis";
import Table from "../../../components/Table";
function FAQ() {
  const navigate = useNavigate();
  const { pageNum } = useParams();
  const { setAlert } = useAlert();
  const role = JSON.parse(localStorage.getItem("gp_user")).user.role;
  const [page, setPage] = useState(pageNum || 1);
  const { data, refetch } = useQuery({
    queryKey: ["faqs"],
    queryFn: () => getFaqs(),
  });
  const { mutate: deleteProp } = useMutation({
    mutationKey: ["deleteFaq"],
    mutationFn: deleteFaq,
    onSuccess: () => {
      setAlert({
        type: "success",
        message: "Faq deleted Successfully",
        isOpen: true,
      });
      refetch();
    },
  });

  const headers = [
    {
      key: "question",
      title: "Question",
    },
    {
      key: "answer",
      title: "Answer",
    },

    {
      key: "_action",
      title: "Actions",
      actions: {
        delete: (data) => deleteProp({ id: data?._id }),
        // edit: (data) => navigate(`/admin/settings/update/partner/${data?._id}`),
      },
      role: role,
    },
  ];

  // const checkState = useState([]);

  return (
    <>
      <div className="flex justify-between items-center flex-row md:pb-0 pb-4 md:py-0 py-12">
        <h2 className="md:text-3xl text-2xl font-semibold md:mb-6 mb-0">
          FAQs
        </h2>
        {role !== "user" && (
          <Button
            outline
            gradientDuoTone="greenToBlue"
            pill
            onClick={() => navigate("/admin/create/faq")}
          >
            <HiPlus className="mr-2 h-5 w-5" />
            Add Question
          </Button>
        )}
      </div>
      <div className="overflow-scroll md:overflow-auto scrollbar-hide">
        <Table
          headers={headers}
          data={data?.data?.results}
          // checkState={checkState}
          isLoading={false}
          page={page}
          setPage={setPage}
          total={1}
          // withCheckBoxes
          //
        />
      </div>
    </>
  );
}

export default FAQ;
