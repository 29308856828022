import { useQuery } from "@tanstack/react-query";
import { getContent, getProperties } from "../../apis";
import CardComp from "./components/LandingCards";
export default function RentProperties() {
  const { data, isLoading } = useQuery({
    queryKey: ["rentProperties"],
    queryFn: getProperties("type=rent"),
  });
  const { data: rentData } = useQuery({
    queryKey: ["content"],
    queryFn: () => getContent(),
  });
  return data?.data?.results?.length > 0 ? (
    <section
      id="popular"
      className=" bg-slate-50 md:h-screen md:flex md:flex-col md:justify-center"
      style={{ scrollSnapAlign: "center" }}
    >
      <div className="text-center py-12 md:space-y-2">
        <h2 className="text-2xl md:text-4xl font-semibold text-lime-600">
          {rentData?.data?.rent?.mainText || "Rent Properties"}
        </h2>
        <p className="text-sm md:text-base font-medium">
          {rentData?.data?.rent?.subText ||
            " Whether you seek sprawling land, a charming home, or a cozy apartment, we have it all. Explore your options and find your perfect match."}
        </p>
      </div>
      {!isLoading && <CardComp cards={data?.data?.results} />}
    </section>
  ) : null;
}
