import { useQuery } from "@tanstack/react-query";
import { getContent, getFeaturedProperties } from "../../apis";
import CardComp from "./components/LandingCards";

export default function Featured() {
  const { data, isLoading } = useQuery({
    queryKey: ["featuredProperties"],
    queryFn: getFeaturedProperties,
  });
  const { data: featuredData } = useQuery({
    queryKey: ["content"],
    queryFn: () => getContent(),
  });
  return (
    <section
      id="featured"
      className="md:h-screen md:flex md:flex-col md:justify-center bg-slate-50"
      style={{ scrollSnapAlign: "center" }}
    >
      <div className="text-center py-12 md:space-y-2">
        <h2 className="text-2xl md:text-4xl font-semibold text-lime-600">
          {featuredData?.data?.featured?.mainText || "Featured Properties"}
        </h2>
        <p className="text-sm md:text-base font-medium">
          {featuredData?.data?.featured?.subText ||
            "Handpicked properties by our team."}
        </p>
      </div>
      <CardComp
        cards={isLoading ? [] : data?.data?.results}
        isLoading={isLoading}
      />
    </section>
  );
}
