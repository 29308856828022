import About from "./About";
import Home from "./Home";
import Listing from "./Listing";
import Loading from "./Loading";
import Property from "./Property";

import admin from "./admin";
import Contact from "./contact";
import Tool from "./Tools";

const pages = {
  loading: Loading,
  home: Home,
  about: About,
  contact: Contact,
  listing: Listing,
  property: Property,
  tools: Tool,
  admin: admin,
};

export default pages;
