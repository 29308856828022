import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";
import { BsGrid } from "react-icons/bs";
import { FaList } from "react-icons/fa";
import { useParams, useSearchParams } from "react-router-dom";
import { getProperties } from "../../apis";

import PropertyCard from "../../components/PropertyCard";
export default function Listing() {
  const { type } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [layout, _setLayout] = useState("grid");
  const observer = useRef();
  // const { data, refetch, isLoading } = useInfiniteQuery({
  //   queryKey: ["properties", type, page],
  //   queryFn: getProperties(searchParams.toString()),
  // });

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ["properties", type],
    queryFn: ({ pageParam = 1 }) =>
      getProperties(searchParams.toString(), pageParam)(),
    getNextPageParam: (lastPage) => {
      if (lastPage?.data?.totalPages > lastPage?.data?.page) {
        return lastPage?.data?.page + 1;
      } else return undefined;
    },
  });
  window.log(data, "data");
  const { data: properties } = useQuery({
    queryKey: ["properties"],
    queryFn: getProperties(),
  });
  window.log(properties, "propertieslisting");

  useEffect(() => {
    if (type) setSearchParams({ type });
  }, [type, setSearchParams]);
  useEffect(() => {
    if (searchParams) refetch();
  }, [searchParams, refetch]);

  const lastItemRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isFetchingNextPage, hasNextPage, fetchNextPage]
  );
  window.log(data?.pages[0]?.data?.results.length, "dataLength");

  return (
    <>
      {/* <LayoutBar setLayout={setLayout} layout={layout} /> */}
      <section>
        <div
          className={[
            "md:mx-auto mx-[-0.75rem] gap-4",
            layout === "grid" && "grid grid-cols-1 lg:grid-cols-3 lg:gap-4",
            layout === "list" && "grid grid-cols-1 lg:grid-cols-2 lg:gap-8",
          ].join(" ")}
        >
          {!isLoading &&
            data?.pages.map((page, pageIndex) =>
              page?.data?.results?.map((property, itemIndex) => {
                if (
                  page?.data?.results?.length === itemIndex + 1 &&
                  data?.pages?.length === pageIndex + 1
                ) {
                  return (
                    <div className="flex flex-col gap-y-20">
                      <PropertyCard
                        key={property?._id}
                        property={property}
                        horizontal={layout === "list"}
                        ref={lastItemRef}
                      />
                    </div>
                  );
                } else {
                  return (
                    <PropertyCard
                      key={property?._id}
                      property={property}
                      horizontal={layout === "list"}
                    />
                  );
                }
              })
            )}

          {data?.pages.map((page) => {
            if (page.data?.results?.length === 0) {
              return (
                <div className="w-full h-full grid col-span-3">
                  <h1 className="text-2xl font-semibold text-slate-600 text-center py-8">
                    No Property Found matching with filter criteria.
                  </h1>
                </div>
              );
            }
          })}
        </div>
        {data?.pages[0]?.data?.results.length <= 6 &&
          data?.pages.map((page) => {
            if (
              page.data?.results?.length <= 6 &&
              page.data?.results?.length > 0
            ) {
              return (
                <div className="grid col-span-3">
                  <p className="text-xl text-slate-600 py-8 font-semibold">
                    Explore other available listings to find more options.
                  </p>
                  <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-4">
                    {properties?.data?.results?.map((property) => (
                      <PropertyCard
                        key={property?._id}
                        property={property}
                        horizontal={layout === "list"}
                      />
                    ))}
                  </div>
                </div>
              );
            }
          })}
      </section>
    </>
  );
}

function _LayoutBar({ setLayout, layout }) {
  return (
    <section className="hidden lg:flex justify-end mb-8 ">
      <div className="shadow-big border border-slate-200">
        <button
          className={[
            " hover:bg-lime-500 hover:text-white  p-3",
            "border border-slate-200",
            layout === "list" && "bg-lime-600 text-white",
          ].join(" ")}
          onClick={() => setLayout("list")}
        >
          <FaList className="text-2xl" />
        </button>
        <button
          className={[
            " hover:bg-lime-500 hover:text-white p-3",
            "border border-slate-200",
            layout === "grid" && "bg-lime-600 text-white",
          ].join(" ")}
          onClick={() => setLayout("grid")}
        >
          <BsGrid className="text-2xl" />
        </button>
      </div>
    </section>
  );
}
