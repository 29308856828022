const data = [
	{
		id: 1,
		name: 'Koshi Province',
		districts: [
			{
				id: 1,
				pid: 1,
				name: 'Bhojpur',
				municipalities: [
					{
						id: 1,
						did: 1,
						name: 'Shadanand Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					{
						id: 2,
						did: 1,
						name: 'Bhojpur Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					{
						id: 3,
						did: 1,
						name: 'Hatuwagadhi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					{
						id: 4,
						did: 1,
						name: 'Ramprasad Rai Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					{
						id: 5,
						did: 1,
						name: 'Aamchok Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					{
						id: 6,
						did: 1,
						name: 'Tyamke Maiyum Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					{
						id: 7,
						did: 1,
						name: 'Arun Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					{
						id: 8,
						did: 1,
						name: 'Pauwadungma Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					{
						id: 9,
						did: 1,
						name: 'Salpasilichho Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
				],
			},
			{
				id: 2,
				pid: 1,
				name: 'Dhankuta',
				municipalities: {
					9: {
						id: 10,
						did: 2,
						name: 'Dhankuta Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					10: {
						id: 11,
						did: 2,
						name: 'Pakhribas Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					11: {
						id: 12,
						did: 2,
						name: 'Mahalaxmi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					12: {
						id: 13,
						did: 2,
						name: 'Sangurigadhi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					13: {
						id: 14,
						did: 2,
						name: 'Chaubise Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					14: {
						id: 15,
						did: 2,
						name: 'Sahidbhumi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					15: {
						id: 16,
						did: 2,
						name: 'Chhathar Jorpati Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
				},
			},
			{
				id: 3,
				pid: 1,
				name: 'Ilam',
				municipalities: {
					16: {
						id: 17,
						did: 3,
						name: 'Suryodaya Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					17: {
						id: 18,
						did: 3,
						name: 'Ilam Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					18: {
						id: 19,
						did: 3,
						name: 'Deumai Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					19: {
						id: 20,
						did: 3,
						name: 'Maijogmai Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					20: {
						id: 21,
						did: 3,
						name: 'Phakphokthum Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					21: {
						id: 22,
						did: 3,
						name: 'Mai Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					22: {
						id: 23,
						did: 3,
						name: 'Chulachuli Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					23: {
						id: 24,
						did: 3,
						name: 'Rong Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					24: {
						id: 25,
						did: 3,
						name: 'Mangsebung Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					25: {
						id: 26,
						did: 3,
						name: 'Sandakpur Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
				},
			},
			{
				id: 4,
				pid: 1,
				name: 'Jhapa',
				municipalities: {
					26: {
						id: 27,
						did: 4,
						name: 'Mechinagar Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
					},
					27: {
						id: 28,
						did: 4,
						name: 'Birtamod Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					28: {
						id: 29,
						did: 4,
						name: 'Damak Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					29: {
						id: 30,
						did: 4,
						name: 'Bhadrapur Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					30: {
						id: 31,
						did: 4,
						name: 'Shivasatakshi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					31: {
						id: 32,
						did: 4,
						name: 'Arjundhara Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					32: {
						id: 33,
						did: 4,
						name: 'Gauradaha Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					33: {
						id: 34,
						did: 4,
						name: 'Kankai Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					34: {
						id: 35,
						did: 4,
						name: 'Kamal Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					35: {
						id: 36,
						did: 4,
						name: 'Buddha Shanti Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					36: {
						id: 37,
						did: 4,
						name: 'Kachankawal Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					37: {
						id: 38,
						did: 4,
						name: 'Jhapa Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					38: {
						id: 39,
						did: 4,
						name: 'Barhadashi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					39: {
						id: 40,
						did: 4,
						name: 'Gaurigunj Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					40: {
						id: 41,
						did: 4,
						name: 'Haldibari Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
				},
			},
			{
				id: 5,
				pid: 1,
				name: 'Khotang',
				municipalities: {
					41: {
						id: 42,
						did: 5,
						name: 'Diktel Rupakot Majhuwagadhi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
					},
					42: {
						id: 43,
						did: 5,
						name: 'Halesi Tuwachung Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					43: {
						id: 44,
						did: 5,
						name: 'Khotehang Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					44: {
						id: 45,
						did: 5,
						name: 'Diprung Chuichumma Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					45: {
						id: 46,
						did: 5,
						name: 'Aiselukharka Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					46: {
						id: 47,
						did: 5,
						name: 'Jantedhunga Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					47: {
						id: 48,
						did: 5,
						name: 'Kepilasgadhi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					48: {
						id: 49,
						did: 5,
						name: 'Barahpokhari Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					49: {
						id: 50,
						did: 5,
						name: 'Rawa Besi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					50: {
						id: 51,
						did: 5,
						name: 'Sakela Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
				},
			},
			{
				id: 6,
				pid: 1,
				name: 'Morang',
				municipalities: {
					51: {
						id: 52,
						did: 6,
						name: 'Sundar Haraicha Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					52: {
						id: 53,
						did: 6,
						name: 'Belbari Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					53: {
						id: 54,
						did: 6,
						name: 'Pathari Shanischare Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					54: {
						id: 55,
						did: 6,
						name: 'Ratuwamai Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					55: {
						id: 56,
						did: 6,
						name: 'Urlabari Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					56: {
						id: 57,
						did: 6,
						name: 'Rangeli Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					57: {
						id: 58,
						did: 6,
						name: 'Sunawarshi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					58: {
						id: 59,
						did: 6,
						name: 'Letang Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					59: {
						id: 60,
						did: 6,
						name: 'Biratnagar Metropolitan City',
						wards: [
							1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
						],
					},
					60: {
						id: 61,
						did: 6,
						name: 'Jahada Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					61: {
						id: 62,
						did: 6,
						name: 'Budi Ganga Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					62: {
						id: 63,
						did: 6,
						name: 'Katahari Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					63: {
						id: 64,
						did: 6,
						name: 'Dhanpalthan Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					64: {
						id: 65,
						did: 6,
						name: 'Kanepokhari Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					65: {
						id: 66,
						did: 6,
						name: 'Gramthan Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					66: {
						id: 67,
						did: 6,
						name: 'Kerabari Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					67: {
						id: 68,
						did: 6,
						name: 'Miklajung Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
				},
			},
			{
				id: 7,
				pid: 1,
				name: 'Okhaldhunga',
				municipalities: {
					68: {
						id: 69,
						did: 7,
						name: 'Siddhicharan Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					69: {
						id: 70,
						did: 7,
						name: 'Khiji Demba Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					70: {
						id: 71,
						did: 7,
						name: 'Chisankhugadhi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					71: {
						id: 72,
						did: 7,
						name: 'Molung Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					72: {
						id: 73,
						did: 7,
						name: 'Sunkoshi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					73: {
						id: 74,
						did: 7,
						name: 'Champadevi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					74: {
						id: 75,
						did: 7,
						name: 'Manebhanjyang Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					75: {
						id: 76,
						did: 7,
						name: 'Likhu Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
				},
			},
			{
				id: 8,
				pid: 1,
				name: 'Pachthar',
				municipalities: {
					76: {
						id: 77,
						did: 8,
						name: 'Phidim Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					77: {
						id: 78,
						did: 8,
						name: 'Miklajung Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					78: {
						id: 79,
						did: 8,
						name: 'Phalgunanda Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					79: {
						id: 80,
						did: 8,
						name: 'Hilihang Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					80: {
						id: 81,
						did: 8,
						name: 'Phalelung Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					81: {
						id: 82,
						did: 8,
						name: 'Yangwarak Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					82: {
						id: 83,
						did: 8,
						name: 'Kummayak Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					83: {
						id: 84,
						did: 8,
						name: 'Tumbewa Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
				},
			},
			{
				id: 9,
				pid: 1,
				name: 'Sankhuwasabha',
				municipalities: {
					84: {
						id: 85,
						did: 9,
						name: 'Khandbari Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					85: {
						id: 86,
						did: 9,
						name: 'Chainpur Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					86: {
						id: 87,
						did: 9,
						name: 'Dharmadevi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					87: {
						id: 88,
						did: 9,
						name: 'Panchkhapan Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					88: {
						id: 89,
						did: 9,
						name: 'Madi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					89: {
						id: 90,
						did: 9,
						name: 'Makalu Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					90: {
						id: 91,
						did: 9,
						name: 'Silichong Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					91: {
						id: 92,
						did: 9,
						name: 'Sabhapokhari Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					92: {
						id: 93,
						did: 9,
						name: 'Chichila Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					93: {
						id: 94,
						did: 9,
						name: 'BhotKhola Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
				},
			},
			{
				id: 10,
				pid: 1,
				name: 'Solukhumbu',
				municipalities: {
					94: {
						id: 95,
						did: 10,
						name: 'Solu Dudhkunda Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					95: {
						id: 96,
						did: 10,
						name: 'Mapya Dudhkoshi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					96: {
						id: 97,
						did: 10,
						name: 'Necha Salyan Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					97: {
						id: 98,
						did: 10,
						name: 'Thulung Dudhkoshi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					98: {
						id: 99,
						did: 10,
						name: 'Maha Kulung Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					99: {
						id: 100,
						did: 10,
						name: 'Sotang Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					100: {
						id: 101,
						did: 10,
						name: 'Khumbu PasangLhamu Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					101: {
						id: 102,
						did: 10,
						name: 'Likhu Pike Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
				},
			},
			{
				id: 11,
				pid: 1,
				name: 'Sunsari',
				municipalities: {
					102: {
						id: 103,
						did: 11,
						name: 'BarahaKshetra Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					103: {
						id: 104,
						did: 11,
						name: 'Inaruwa Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					104: {
						id: 105,
						did: 11,
						name: 'Duhabi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					105: {
						id: 106,
						did: 11,
						name: 'Ramdhuni Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					106: {
						id: 107,
						did: 11,
						name: 'Itahari Sub-Metropolitan City',
						wards: [
							1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
							20,
						],
					},
					107: {
						id: 108,
						did: 11,
						name: 'Dharan Sub-Metropolitan City',
						wards: [
							1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
							20,
						],
					},
					108: {
						id: 109,
						did: 11,
						name: 'Koshi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					109: {
						id: 110,
						did: 11,
						name: 'Harinagar Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					110: {
						id: 111,
						did: 11,
						name: 'Bhokraha Narsingh Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					111: {
						id: 112,
						did: 11,
						name: 'Dewangunj Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					112: {
						id: 113,
						did: 11,
						name: 'Gadhi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					113: {
						id: 114,
						did: 11,
						name: 'Barju Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
				},
			},
			{
				id: 12,
				pid: 1,
				name: 'Taplejung',
				municipalities: {
					114: {
						id: 115,
						did: 12,
						name: 'Phungling Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					115: {
						id: 116,
						did: 12,
						name: 'Sirijangha Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					116: {
						id: 117,
						did: 12,
						name: 'Aathrai Triveni Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					117: {
						id: 118,
						did: 12,
						name: 'Pathibhara Yangwarak Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					118: {
						id: 119,
						did: 12,
						name: 'Meringden Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					119: {
						id: 120,
						did: 12,
						name: 'Sidingwa Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					120: {
						id: 121,
						did: 12,
						name: 'Phaktanglung Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					121: {
						id: 122,
						did: 12,
						name: 'Maiwa Khola Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					122: {
						id: 123,
						did: 12,
						name: 'Mikwa Khola Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
				},
			},
			{
				id: 13,
				pid: 1,
				name: 'Terhathum',
				municipalities: {
					123: {
						id: 124,
						did: 13,
						name: 'Myanglung Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					124: {
						id: 125,
						did: 13,
						name: 'Laligurans Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					125: {
						id: 126,
						did: 13,
						name: 'Aathrai Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					126: {
						id: 127,
						did: 13,
						name: 'Phedap Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					127: {
						id: 128,
						did: 13,
						name: 'Chhathar Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					128: {
						id: 129,
						did: 13,
						name: 'Menchayayem Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
				},
			},
			{
				id: 14,
				pid: 1,
				name: 'Udayapur',
				municipalities: {
					129: {
						id: 130,
						did: 14,
						name: 'Triyuga Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
					},
					130: {
						id: 131,
						did: 14,
						name: 'Katari Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					131: {
						id: 132,
						did: 14,
						name: 'Chaudandigadhi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					132: {
						id: 133,
						did: 14,
						name: 'Belaka Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					133: {
						id: 134,
						did: 14,
						name: 'Udayapurgadhi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					134: {
						id: 135,
						did: 14,
						name: 'Rautamai Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					135: {
						id: 136,
						did: 14,
						name: 'Tapli Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					136: {
						id: 137,
						did: 14,
						name: 'Limchungbung Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
				},
			},
		],
	},
	{
		id: 2,
		name: 'Madhesh Province',
		districts: {
			14: {
				id: 15,
				pid: 2,
				name: 'Parsa',
				municipalities: {
					137: {
						id: 138,
						did: 15,
						name: 'Birgunj Metropolitan City',
						wards: [
							1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
							20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
						],
					},
					138: {
						id: 139,
						did: 15,
						name: 'Bahudarmai Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					139: {
						id: 140,
						did: 15,
						name: 'Parsagadhi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					140: {
						id: 141,
						did: 15,
						name: 'Pokhariya Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					141: {
						id: 142,
						did: 15,
						name: 'Bindabasini Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					142: {
						id: 143,
						did: 15,
						name: 'Dhobini Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					143: {
						id: 144,
						did: 15,
						name: 'Chhipaharmai Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					144: {
						id: 145,
						did: 15,
						name: 'Jagarnathpur Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					145: {
						id: 146,
						did: 15,
						name: 'Jirabhawani Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					146: {
						id: 147,
						did: 15,
						name: 'Kalikamai Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					147: {
						id: 148,
						did: 15,
						name: 'Pakaha Mainpur Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					148: {
						id: 149,
						did: 15,
						name: 'Paterwa Sugauli Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					149: {
						id: 150,
						did: 15,
						name: 'Sakhuwa Prasauni Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					150: {
						id: 151,
						did: 15,
						name: 'Thori Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
				},
			},
			15: {
				id: 16,
				pid: 2,
				name: 'Bara',
				municipalities: {
					151: {
						id: 152,
						did: 16,
						name: 'Kalaiya Sub-Metropolitan City',
						wards: [
							1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
							20, 21, 22, 23, 24, 25, 26, 27,
						],
					},
					152: {
						id: 153,
						did: 16,
						name: 'Jitpur Simara Sub-Metropolitan City',
						wards: [
							1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
							20, 21, 22, 23, 24,
						],
					},
					153: {
						id: 154,
						did: 16,
						name: 'Kolhabi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					154: {
						id: 155,
						did: 16,
						name: 'Nijgadh Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
					},
					155: {
						id: 156,
						did: 16,
						name: 'Mahagadhimai Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					156: {
						id: 157,
						did: 16,
						name: 'Simaraungadh Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					157: {
						id: 158,
						did: 16,
						name: 'Pacharauta Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					158: {
						id: 159,
						did: 16,
						name: 'Pheta Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					159: {
						id: 160,
						did: 16,
						name: 'Bishrampur Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					160: {
						id: 161,
						did: 16,
						name: 'Prasauni Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					161: {
						id: 162,
						did: 16,
						name: 'Adarsh Kotwal Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					162: {
						id: 163,
						did: 16,
						name: 'Karaiyamai Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					163: {
						id: 164,
						did: 16,
						name: 'Devtal Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					164: {
						id: 165,
						did: 16,
						name: 'Parwanipur Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					165: {
						id: 166,
						did: 16,
						name: 'Baragadhi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					166: {
						id: 167,
						did: 16,
						name: 'Suwarna Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
				},
			},
			16: {
				id: 17,
				pid: 2,
				name: 'Rautahat',
				municipalities: {
					167: {
						id: 168,
						did: 17,
						name: 'Baudhimai Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					168: {
						id: 169,
						did: 17,
						name: 'Brindaban Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					169: {
						id: 170,
						did: 17,
						name: 'Chandrapur Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					170: {
						id: 171,
						did: 17,
						name: 'Dewahi Gonahi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					171: {
						id: 172,
						did: 17,
						name: 'Gadhimai Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					172: {
						id: 173,
						did: 17,
						name: 'Guruda Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					173: {
						id: 174,
						did: 17,
						name: 'Gaur Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					174: {
						id: 175,
						did: 17,
						name: 'Gujara Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					175: {
						id: 176,
						did: 17,
						name: 'Ishanath Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					176: {
						id: 177,
						did: 17,
						name: 'Katahariya Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					177: {
						id: 178,
						did: 17,
						name: 'Madhav Narayan Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					178: {
						id: 179,
						did: 17,
						name: 'Maulapur Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					179: {
						id: 180,
						did: 17,
						name: 'Paroha Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					180: {
						id: 181,
						did: 17,
						name: 'Phatuwa Bijayapur Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					181: {
						id: 182,
						did: 17,
						name: 'Rajdevi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					182: {
						id: 183,
						did: 17,
						name: 'Rajpur Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					183: {
						id: 184,
						did: 17,
						name: 'Durga Bhagwati Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					184: {
						id: 185,
						did: 17,
						name: 'Yamunamai Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
				},
			},
			17: {
				id: 18,
				pid: 2,
				name: 'Sarlahi',
				municipalities: {
					185: {
						id: 186,
						did: 18,
						name: 'Bagmati Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					186: {
						id: 187,
						did: 18,
						name: 'Balara Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					187: {
						id: 188,
						did: 18,
						name: 'Barahathwa Municipality',
						wards: [
							1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
						],
					},
					188: {
						id: 189,
						did: 18,
						name: 'Godaita Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					189: {
						id: 190,
						did: 18,
						name: 'Hariwan Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					190: {
						id: 191,
						did: 18,
						name: 'Haripur Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					191: {
						id: 192,
						did: 18,
						name: 'Haripurwa Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					192: {
						id: 193,
						did: 18,
						name: 'Ishowrpur Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
					},
					193: {
						id: 194,
						did: 18,
						name: 'Kabilasi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					194: {
						id: 195,
						did: 18,
						name: 'Lalbandi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
					},
					195: {
						id: 196,
						did: 18,
						name: 'Malangawa Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					196: {
						id: 197,
						did: 18,
						name: 'Basbariya Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					197: {
						id: 198,
						did: 18,
						name: 'Bisnu Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					198: {
						id: 199,
						did: 18,
						name: 'Brahampuri Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					199: {
						id: 200,
						did: 18,
						name: 'Chakraghatta Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					200: {
						id: 201,
						did: 18,
						name: 'Chandranagar Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					201: {
						id: 202,
						did: 18,
						name: 'Dhankaul Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					202: {
						id: 203,
						did: 18,
						name: 'Kaudena Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					203: {
						id: 204,
						did: 18,
						name: 'Parsa Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					204: {
						id: 205,
						did: 18,
						name: 'Ramnagar Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
				},
			},
			18: {
				id: 19,
				pid: 2,
				name: 'Siraha',
				municipalities: {
					205: {
						id: 206,
						did: 19,
						name: 'Lahan Municipality',
						wards: [
							1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
							20, 21, 22, 23, 24,
						],
					},
					206: {
						id: 207,
						did: 19,
						name: 'Dhangadhimai Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					207: {
						id: 208,
						did: 19,
						name: 'Siraha Municipality',
						wards: [
							1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
							20, 21, 22,
						],
					},
					208: {
						id: 209,
						did: 19,
						name: 'Golbazar Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
					},
					209: {
						id: 210,
						did: 19,
						name: 'Mirchaiya Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					210: {
						id: 211,
						did: 19,
						name: 'Kalyanpur Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					211: {
						id: 212,
						did: 19,
						name: 'Karjanha Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					212: {
						id: 213,
						did: 19,
						name: 'Sukhipur Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					213: {
						id: 214,
						did: 19,
						name: 'Bhagwanpur Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					214: {
						id: 215,
						did: 19,
						name: 'Aurahi Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					215: {
						id: 216,
						did: 19,
						name: 'Bishnupur Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					216: {
						id: 217,
						did: 19,
						name: 'Bariyarpatti Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					217: {
						id: 218,
						did: 19,
						name: 'Lakshmipur Patari Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					218: {
						id: 219,
						did: 19,
						name: 'Naraha Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					219: {
						id: 220,
						did: 19,
						name: 'SakhuwanankarKatti Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					220: {
						id: 221,
						did: 19,
						name: 'Arnama Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					221: {
						id: 222,
						did: 19,
						name: 'Navarajpur Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
				},
			},
			19: {
				id: 20,
				pid: 2,
				name: 'Dhanusha',
				municipalities: {
					222: {
						id: 223,
						did: 20,
						name: 'Janakpurdham Sub-Metropolitan City',
						wards: [
							1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
							20, 21, 22, 23, 24, 25,
						],
					},
					223: {
						id: 224,
						did: 20,
						name: 'Chhireshwarnath Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					224: {
						id: 225,
						did: 20,
						name: 'Ganeshman Charnath Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					225: {
						id: 226,
						did: 20,
						name: 'Dhanushadham Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					226: {
						id: 227,
						did: 20,
						name: 'Nagarain Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					227: {
						id: 228,
						did: 20,
						name: 'Bideha Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					228: {
						id: 229,
						did: 20,
						name: 'Mithila Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					229: {
						id: 230,
						did: 20,
						name: 'Sahidnagar Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					230: {
						id: 231,
						did: 20,
						name: 'Sabaila Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
					},
					231: {
						id: 232,
						did: 20,
						name: 'Kamala Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					232: {
						id: 233,
						did: 20,
						name: 'MithilaBihari Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					233: {
						id: 234,
						did: 20,
						name: 'Hansapur Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					234: {
						id: 235,
						did: 20,
						name: 'Janaknandani Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					235: {
						id: 236,
						did: 20,
						name: 'Bateshwar Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					236: {
						id: 237,
						did: 20,
						name: 'Mukhiyapatti Musharniya Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					237: {
						id: 238,
						did: 20,
						name: 'Lakshminya Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					238: {
						id: 239,
						did: 20,
						name: 'Aaurahi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					239: {
						id: 240,
						did: 20,
						name: 'Dhanauji Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
				},
			},
			20: {
				id: 21,
				pid: 2,
				name: 'Saptari',
				municipalities: {
					240: {
						id: 241,
						did: 21,
						name: 'Bodebarsain Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					241: {
						id: 242,
						did: 21,
						name: 'Dakneshwori Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					242: {
						id: 243,
						did: 21,
						name: 'Hanumannagar Kankalini Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					243: {
						id: 244,
						did: 21,
						name: 'Kanchanrup Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					244: {
						id: 245,
						did: 21,
						name: 'Khadak Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					245: {
						id: 246,
						did: 21,
						name: 'Shambhunath Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					246: {
						id: 247,
						did: 21,
						name: 'Saptakoshi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					247: {
						id: 248,
						did: 21,
						name: 'Surunga Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					248: {
						id: 249,
						did: 21,
						name: 'Rajbiraj Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
					},
					249: {
						id: 250,
						did: 21,
						name: 'Agnisaira Krishnasavaran Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					250: {
						id: 251,
						did: 21,
						name: 'Balan-Bihul Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					251: {
						id: 252,
						did: 21,
						name: 'Rajgadh Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					252: {
						id: 253,
						did: 21,
						name: 'Bishnupur Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					253: {
						id: 254,
						did: 21,
						name: 'Chhinnamasta Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					254: {
						id: 255,
						did: 21,
						name: 'Mahadeva Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					255: {
						id: 256,
						did: 21,
						name: 'Rupani Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					256: {
						id: 257,
						did: 21,
						name: 'Tilathi Koiladi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					257: {
						id: 258,
						did: 21,
						name: 'Tirhut Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
				},
			},
			21: {
				id: 22,
				pid: 2,
				name: 'Mahottari',
				municipalities: {
					258: {
						id: 259,
						did: 22,
						name: 'Aaurahi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					259: {
						id: 260,
						did: 22,
						name: 'Balawa Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					260: {
						id: 261,
						did: 22,
						name: 'Bardibas Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					261: {
						id: 262,
						did: 22,
						name: 'Bhangaha Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					262: {
						id: 263,
						did: 22,
						name: 'Gaushala Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					263: {
						id: 264,
						did: 22,
						name: 'Jaleshor Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					264: {
						id: 265,
						did: 22,
						name: 'Loharpatti Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					265: {
						id: 266,
						did: 22,
						name: 'Manara Shiswa Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					266: {
						id: 267,
						did: 22,
						name: 'Matihani Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					267: {
						id: 268,
						did: 22,
						name: 'Ramgopalpur Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					268: {
						id: 269,
						did: 22,
						name: 'Ekdara Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					269: {
						id: 270,
						did: 22,
						name: 'Mahottari Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					270: {
						id: 271,
						did: 22,
						name: 'Pipara Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					271: {
						id: 272,
						did: 22,
						name: 'Samsi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					272: {
						id: 273,
						did: 22,
						name: 'Sonama Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
				},
			},
		},
	},
	{
		id: 3,
		name: 'Bagmati',
		districts: {
			22: {
				id: 23,
				pid: 3,
				name: 'Bhaktapur',
				municipalities: {
					273: {
						id: 274,
						did: 23,
						name: 'Bhaktapur Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					274: {
						id: 275,
						did: 23,
						name: 'Changunarayan Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					275: {
						id: 276,
						did: 23,
						name: 'Suryabinayak Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					276: {
						id: 277,
						did: 23,
						name: 'Madhyapur Thimi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
				},
			},
			23: {
				id: 24,
				pid: 3,
				name: 'Chitwan',
				municipalities: {
					277: {
						id: 278,
						did: 24,
						name: 'Bharatpur Metropolitan City',
						wards: [
							1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
							20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
						],
					},
					278: {
						id: 279,
						did: 24,
						name: 'Kalika Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					279: {
						id: 280,
						did: 24,
						name: 'Khairhani Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
					},
					280: {
						id: 281,
						did: 24,
						name: 'Madi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					281: {
						id: 282,
						did: 24,
						name: 'Ratnagar Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
					},
					282: {
						id: 283,
						did: 24,
						name: 'Rapti Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
					},
					283: {
						id: 284,
						did: 24,
						name: 'Ichchhakamana Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
				},
			},
			24: {
				id: 25,
				pid: 3,
				name: 'Dhading',
				municipalities: {
					284: {
						id: 285,
						did: 25,
						name: 'Dhunibeshi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					285: {
						id: 286,
						did: 25,
						name: 'Nilkantha Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					286: {
						id: 287,
						did: 25,
						name: 'Khaniyabas Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					287: {
						id: 288,
						did: 25,
						name: 'Gajuri Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					288: {
						id: 289,
						did: 25,
						name: 'Galchhi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					289: {
						id: 290,
						did: 25,
						name: 'Gangajamuna Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					290: {
						id: 291,
						did: 25,
						name: 'Jwalamukhi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					291: {
						id: 292,
						did: 25,
						name: 'Thakre Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					292: {
						id: 293,
						did: 25,
						name: 'Netrawati Dabjong Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					293: {
						id: 294,
						did: 25,
						name: 'Benighat Rorang Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					294: {
						id: 295,
						did: 25,
						name: 'Rubi Valley Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					295: {
						id: 296,
						did: 25,
						name: 'Siddhalek Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					296: {
						id: 297,
						did: 25,
						name: 'Tripurasundari Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
				},
			},
			25: {
				id: 26,
				pid: 3,
				name: 'Dolakha',
				municipalities: {
					297: {
						id: 298,
						did: 26,
						name: 'Bhimeswor Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					298: {
						id: 299,
						did: 26,
						name: 'Jiri Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					299: {
						id: 300,
						did: 26,
						name: 'Kalinchok Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					300: {
						id: 301,
						did: 26,
						name: 'Melung Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					301: {
						id: 302,
						did: 26,
						name: 'Bigu Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					302: {
						id: 303,
						did: 26,
						name: 'Gaurishankar Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					303: {
						id: 304,
						did: 26,
						name: 'Baiteshowr Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					304: {
						id: 305,
						did: 26,
						name: 'Sailung Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					305: {
						id: 306,
						did: 26,
						name: 'Tamakoshi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
				},
			},
			26: {
				id: 27,
				pid: 3,
				name: 'Kathmandu',
				municipalities: {
					306: {
						id: 307,
						did: 27,
						name: 'Kathmandu Metropolitan City',
						wards: [
							1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
							20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
						],
					},
					307: {
						id: 308,
						did: 27,
						name: 'Gokarneshwar Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					308: {
						id: 309,
						did: 27,
						name: 'Kirtipur Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					309: {
						id: 310,
						did: 27,
						name: 'Kageshwari-Manohara Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					310: {
						id: 311,
						did: 27,
						name: 'Chandragiri Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
					},
					311: {
						id: 312,
						did: 27,
						name: 'Tokha Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					312: {
						id: 313,
						did: 27,
						name: 'Tarakeshwar Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					313: {
						id: 314,
						did: 27,
						name: 'Dakshinkali Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					314: {
						id: 315,
						did: 27,
						name: 'Nagarjun Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					315: {
						id: 316,
						did: 27,
						name: 'Budhalikantha Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
					},
					316: {
						id: 317,
						did: 27,
						name: 'Shankharapur Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
				},
			},
			27: {
				id: 28,
				pid: 3,
				name: 'Kavrepalanchok',
				municipalities: {
					317: {
						id: 318,
						did: 28,
						name: 'Dhulikhel Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					318: {
						id: 319,
						did: 28,
						name: 'Namobuddha Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					319: {
						id: 320,
						did: 28,
						name: 'Panauti Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					320: {
						id: 321,
						did: 28,
						name: 'Panchkhal Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
					},
					321: {
						id: 322,
						did: 28,
						name: 'Banepa Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					322: {
						id: 323,
						did: 28,
						name: 'Mandandeupur Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					323: {
						id: 324,
						did: 28,
						name: 'Khani Khola Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					324: {
						id: 325,
						did: 28,
						name: 'Chauri Deurali Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					325: {
						id: 326,
						did: 28,
						name: 'Temal Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					326: {
						id: 327,
						did: 28,
						name: 'Bethanchok Rural Municipality',
						wards: [1, 2, 3, 4],
					},
					327: {
						id: 328,
						did: 28,
						name: 'Bhumlu Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					328: {
						id: 329,
						did: 28,
						name: 'Mahabharat Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					329: {
						id: 330,
						did: 28,
						name: 'Roshi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
				},
			},
			28: {
				id: 29,
				pid: 3,
				name: 'Lalitpur',
				municipalities: {
					330: {
						id: 331,
						did: 29,
						name: 'Lalitpur Metropolitan City',
						wards: [
							1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
							20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
						],
					},
					331: {
						id: 332,
						did: 29,
						name: 'Mahalaxmi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					332: {
						id: 333,
						did: 29,
						name: 'Godawari Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					333: {
						id: 334,
						did: 29,
						name: 'Konjyosom Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					334: {
						id: 335,
						did: 29,
						name: 'Bagmati Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					335: {
						id: 336,
						did: 29,
						name: 'Mahankal Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
				},
			},
			29: {
				id: 30,
				pid: 3,
				name: 'Makwanpur',
				municipalities: {
					336: {
						id: 337,
						did: 30,
						name: 'Hetauda Sub-Metropolitan City',
						wards: [
							1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
						],
					},
					337: {
						id: 338,
						did: 30,
						name: 'Thaha Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					338: {
						id: 339,
						did: 30,
						name: 'Bhimphedi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					339: {
						id: 340,
						did: 30,
						name: 'Makawanpurgadhi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					340: {
						id: 341,
						did: 30,
						name: 'Manahari Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					341: {
						id: 342,
						did: 30,
						name: 'Raksirang Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					342: {
						id: 343,
						did: 30,
						name: 'Bakaiya Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					343: {
						id: 344,
						did: 30,
						name: 'Bagmati Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					344: {
						id: 345,
						did: 30,
						name: 'Kailash Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					345: {
						id: 346,
						did: 30,
						name: 'Indrasarowar Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
				},
			},
			30: {
				id: 31,
				pid: 3,
				name: 'Nuwakot',
				municipalities: {
					346: {
						id: 347,
						did: 31,
						name: 'Bidur Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
					},
					347: {
						id: 348,
						did: 31,
						name: 'Belkotgadhi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
					},
					348: {
						id: 349,
						did: 31,
						name: 'Kakani Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					349: {
						id: 350,
						did: 31,
						name: 'Panchakanya Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					350: {
						id: 351,
						did: 31,
						name: 'Likhu Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					351: {
						id: 352,
						did: 31,
						name: 'Dupcheshwar Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					352: {
						id: 353,
						did: 31,
						name: 'Shivapuri Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					353: {
						id: 354,
						did: 31,
						name: 'Tadi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					354: {
						id: 355,
						did: 31,
						name: 'Suryagadhi Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					355: {
						id: 356,
						did: 31,
						name: 'Tarkeshwar Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					356: {
						id: 357,
						did: 31,
						name: 'Kispang Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					357: {
						id: 358,
						did: 31,
						name: 'Myagang Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
				},
			},
			31: {
				id: 32,
				pid: 3,
				name: 'Ramechap',
				municipalities: {
					358: {
						id: 359,
						did: 32,
						name: 'Manthali Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					359: {
						id: 360,
						did: 32,
						name: 'Ramechhap Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					360: {
						id: 361,
						did: 32,
						name: 'Umakunda Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					361: {
						id: 362,
						did: 32,
						name: 'Khandadevi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					362: {
						id: 363,
						did: 32,
						name: 'Doramba Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					363: {
						id: 364,
						did: 32,
						name: 'Gokulganga Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					364: {
						id: 365,
						did: 32,
						name: 'LikhuTamakoshi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					365: {
						id: 366,
						did: 32,
						name: 'Sunapati Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
				},
			},
			32: {
				id: 33,
				pid: 3,
				name: 'Rasuwa',
				municipalities: {
					366: {
						id: 367,
						did: 33,
						name: 'Kalika Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					367: {
						id: 368,
						did: 33,
						name: 'Gosaikunda Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					368: {
						id: 369,
						did: 33,
						name: 'Naukunda Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					369: {
						id: 370,
						did: 33,
						name: 'Parbatikunda Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					370: {
						id: 371,
						did: 33,
						name: 'Uttargaya Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
				},
			},
			33: {
				id: 34,
				pid: 3,
				name: 'Sindhuli',
				municipalities: {
					371: {
						id: 372,
						did: 34,
						name: 'Kamalamai Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					372: {
						id: 373,
						did: 34,
						name: 'Dudhauli Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					373: {
						id: 374,
						did: 34,
						name: 'Sunkoshi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					374: {
						id: 375,
						did: 34,
						name: 'Hariharpurgadhi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					375: {
						id: 376,
						did: 34,
						name: 'Tinpatan Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					376: {
						id: 377,
						did: 34,
						name: 'Marin Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					377: {
						id: 378,
						did: 34,
						name: 'Golanjor Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					378: {
						id: 379,
						did: 34,
						name: 'Phikkal Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					379: {
						id: 380,
						did: 34,
						name: 'Ghyanglekh Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
				},
			},
			34: {
				id: 35,
				pid: 3,
				name: 'Sindhupalchok',
				municipalities: {
					380: {
						id: 381,
						did: 35,
						name: 'Chautara Sangachowkgadi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					381: {
						id: 382,
						did: 35,
						name: 'Bahrabise Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					382: {
						id: 383,
						did: 35,
						name: 'Melamchi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
					},
					383: {
						id: 384,
						did: 35,
						name: 'Balephi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					384: {
						id: 385,
						did: 35,
						name: 'Sunkoshi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					385: {
						id: 386,
						did: 35,
						name: 'Indrawati Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					386: {
						id: 387,
						did: 35,
						name: 'Jugal Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					387: {
						id: 388,
						did: 35,
						name: 'Panchpokhari Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					388: {
						id: 389,
						did: 35,
						name: 'Bhotekoshi Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					389: {
						id: 390,
						did: 35,
						name: 'Lisankhu Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					390: {
						id: 391,
						did: 35,
						name: 'Helambu Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					391: {
						id: 392,
						did: 35,
						name: 'Tripurasundari Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
				},
			},
		},
	},
	{
		id: 4,
		name: 'Gandaki',
		districts: {
			35: {
				id: 36,
				pid: 4,
				name: 'Baglung',
				municipalities: {
					392: {
						id: 393,
						did: 36,
						name: 'Baglung Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					393: {
						id: 394,
						did: 36,
						name: 'Dhorpatan Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					394: {
						id: 395,
						did: 36,
						name: 'Galkot Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					395: {
						id: 396,
						did: 36,
						name: 'Jaimuni Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					396: {
						id: 397,
						did: 36,
						name: 'Bareng Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					397: {
						id: 398,
						did: 36,
						name: 'Khathekhola Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					398: {
						id: 399,
						did: 36,
						name: 'Taman Khola Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					399: {
						id: 400,
						did: 36,
						name: 'Tara Khola Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					400: {
						id: 401,
						did: 36,
						name: 'Nishi Khola Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					401: {
						id: 402,
						did: 36,
						name: 'Badigad Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
				},
			},
			36: {
				id: 37,
				pid: 4,
				name: 'Gorkha',
				municipalities: {
					402: {
						id: 403,
						did: 37,
						name: 'Gorkha Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					403: {
						id: 404,
						did: 37,
						name: 'Palungtar Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					404: {
						id: 405,
						did: 37,
						name: 'Sulikot Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					405: {
						id: 406,
						did: 37,
						name: 'Siranchowk Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					406: {
						id: 407,
						did: 37,
						name: 'Ajirkot Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					407: {
						id: 408,
						did: 37,
						name: 'Chumnubri Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					408: {
						id: 409,
						did: 37,
						name: 'Dharche Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					409: {
						id: 410,
						did: 37,
						name: 'Bhimsen Thapa Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					410: {
						id: 411,
						did: 37,
						name: 'Sahid Lakhan Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					411: {
						id: 412,
						did: 37,
						name: 'Aarughat Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					412: {
						id: 413,
						did: 37,
						name: 'Gandaki Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
				},
			},
			37: {
				id: 38,
				pid: 4,
				name: 'Kaski',
				municipalities: {
					413: {
						id: 414,
						did: 38,
						name: 'Pokhara Metropolitan City',
						wards: [
							1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
							20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33,
						],
					},
					414: {
						id: 415,
						did: 38,
						name: 'Annapurna Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					415: {
						id: 416,
						did: 38,
						name: 'Machhapuchchhre Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					416: {
						id: 417,
						did: 38,
						name: 'Madi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					417: {
						id: 418,
						did: 38,
						name: 'Rupa Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
				},
			},
			38: {
				id: 39,
				pid: 4,
				name: 'Lamjung',
				municipalities: {
					418: {
						id: 419,
						did: 39,
						name: 'Besisahar Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					419: {
						id: 420,
						did: 39,
						name: 'Madhya Nepal Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					420: {
						id: 421,
						did: 39,
						name: 'Rainas Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					421: {
						id: 422,
						did: 39,
						name: 'Sundarbazar Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					422: {
						id: 423,
						did: 39,
						name: 'Dordi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					423: {
						id: 424,
						did: 39,
						name: 'Dudhpokhari Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					424: {
						id: 425,
						did: 39,
						name: 'Kwhlosothar Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					425: {
						id: 426,
						did: 39,
						name: 'Marsyangdi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
				},
			},
			39: {
				id: 40,
				pid: 4,
				name: 'Manang',
				municipalities: {
					426: {
						id: 427,
						did: 40,
						name: 'Chame Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					427: {
						id: 428,
						did: 40,
						name: 'Nason Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					428: {
						id: 429,
						did: 40,
						name: 'NarpaBhumi Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					429: {
						id: 430,
						did: 40,
						name: 'Manang Ngisyang Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
				},
			},
			40: {
				id: 41,
				pid: 4,
				name: 'Mustang',
				municipalities: {
					430: {
						id: 431,
						did: 41,
						name: 'Gharpajhong Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					431: {
						id: 432,
						did: 41,
						name: 'Thasang Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					432: {
						id: 433,
						did: 41,
						name: 'Barhagaun Muktichhetra Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					433: {
						id: 434,
						did: 41,
						name: 'Lomanthang Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					434: {
						id: 435,
						did: 41,
						name: 'Lo-Ghekar Damodarkunda Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
				},
			},
			41: {
				id: 42,
				pid: 4,
				name: 'Myagdi',
				municipalities: {
					435: {
						id: 436,
						did: 42,
						name: 'Beni Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					436: {
						id: 437,
						did: 42,
						name: 'Annapurna Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					437: {
						id: 438,
						did: 42,
						name: 'Dhaulagiri Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					438: {
						id: 439,
						did: 42,
						name: 'Mangala Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					439: {
						id: 440,
						did: 42,
						name: 'Malika Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					440: {
						id: 441,
						did: 42,
						name: 'Raghuganga Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
				},
			},
			42: {
				id: 43,
				pid: 4,
				name: 'Nawalpur',
				municipalities: {
					441: {
						id: 442,
						did: 43,
						name: 'Kawasoti Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
					},
					442: {
						id: 443,
						did: 43,
						name: 'Gaindakot Municipality',
						wards: [
							1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
						],
					},
					443: {
						id: 444,
						did: 43,
						name: 'Devachuli Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
					},
					444: {
						id: 445,
						did: 43,
						name: 'Madhya Bindu Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
					},
					445: {
						id: 446,
						did: 43,
						name: 'Baudikali Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					446: {
						id: 447,
						did: 43,
						name: 'Bulingtar Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					447: {
						id: 448,
						did: 43,
						name: 'Binayi Tribeni Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					448: {
						id: 449,
						did: 43,
						name: 'Hupsekot Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
				},
			},
			43: {
				id: 44,
				pid: 4,
				name: 'Parwat',
				municipalities: {
					449: {
						id: 450,
						did: 44,
						name: 'Kushma Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					450: {
						id: 451,
						did: 44,
						name: 'Phalewas Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					451: {
						id: 452,
						did: 44,
						name: 'Jaljala Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					452: {
						id: 453,
						did: 44,
						name: 'Paiyun Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					453: {
						id: 454,
						did: 44,
						name: 'Mahashila Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					454: {
						id: 455,
						did: 44,
						name: 'Modi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					455: {
						id: 456,
						did: 44,
						name: 'Bihadi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
				},
			},
			44: {
				id: 45,
				pid: 4,
				name: 'Syangja',
				municipalities: {
					456: {
						id: 457,
						did: 45,
						name: 'Galyang Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					457: {
						id: 458,
						did: 45,
						name: 'Chapakot Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					458: {
						id: 459,
						did: 45,
						name: 'Putalibazar Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					459: {
						id: 460,
						did: 45,
						name: 'Bheerkot Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					460: {
						id: 461,
						did: 45,
						name: 'Waling Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					461: {
						id: 462,
						did: 45,
						name: 'Arjun Chaupari Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					462: {
						id: 463,
						did: 45,
						name: 'Aandhikhola Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					463: {
						id: 464,
						did: 45,
						name: 'Kaligandaki Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					464: {
						id: 465,
						did: 45,
						name: 'Phedikhola Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					465: {
						id: 466,
						did: 45,
						name: 'Harinas Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					466: {
						id: 467,
						did: 45,
						name: 'Biruwa Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
				},
			},
			45: {
				id: 46,
				pid: 4,
				name: 'Tanahun',
				municipalities: {
					467: {
						id: 468,
						did: 46,
						name: 'Bhanu Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
					},
					468: {
						id: 469,
						did: 46,
						name: 'Bhimad Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					469: {
						id: 470,
						did: 46,
						name: 'Byas Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					470: {
						id: 471,
						did: 46,
						name: 'Suklagandaki Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					471: {
						id: 472,
						did: 46,
						name: 'AnbuKhaireni Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					472: {
						id: 473,
						did: 46,
						name: 'Devghat Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					473: {
						id: 474,
						did: 46,
						name: 'Bandipur Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					474: {
						id: 475,
						did: 46,
						name: 'Rishing Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					475: {
						id: 476,
						did: 46,
						name: 'Ghiring Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					476: {
						id: 477,
						did: 46,
						name: 'Myagde Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
				},
			},
		},
	},
	{
		id: 5,
		name: 'Lumbini',
		districts: {
			46: {
				id: 47,
				pid: 5,
				name: 'Kapilvastu',
				municipalities: {
					477: {
						id: 478,
						did: 47,
						name: 'Kapilvastu Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					478: {
						id: 479,
						did: 47,
						name: 'Banganga Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					479: {
						id: 480,
						did: 47,
						name: 'Buddhabhumi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					480: {
						id: 481,
						did: 47,
						name: 'Shivaraj Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					481: {
						id: 482,
						did: 47,
						name: 'Krishnanagar Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					482: {
						id: 483,
						did: 47,
						name: 'Maharajgunj Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					483: {
						id: 484,
						did: 47,
						name: 'Mayadevi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					484: {
						id: 485,
						did: 47,
						name: 'Yashodhara Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					485: {
						id: 486,
						did: 47,
						name: 'Suddhodan Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					486: {
						id: 487,
						did: 47,
						name: 'Bijaynagar Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
				},
			},
			47: {
				id: 48,
				pid: 5,
				name: 'Parasi',
				municipalities: {
					487: {
						id: 488,
						did: 48,
						name: 'Bardaghat Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
					},
					488: {
						id: 489,
						did: 48,
						name: 'Ramgram Municipality',
						wards: [
							1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
						],
					},
					489: {
						id: 490,
						did: 48,
						name: 'Sunwal Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
					},
					490: {
						id: 491,
						did: 48,
						name: 'Susta Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					491: {
						id: 492,
						did: 48,
						name: 'Palhi Nandan Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					492: {
						id: 493,
						did: 48,
						name: 'Pratappur Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					493: {
						id: 494,
						did: 48,
						name: 'Sarawal Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
				},
			},
			48: {
				id: 49,
				pid: 5,
				name: 'Rupandehi',
				municipalities: {
					494: {
						id: 495,
						did: 49,
						name: 'Butwal Sub-Metropolitan City',
						wards: [
							1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
						],
					},
					495: {
						id: 496,
						did: 49,
						name: 'Devdaha Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					496: {
						id: 497,
						did: 49,
						name: 'Lumbini Sanskritik Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
					},
					497: {
						id: 498,
						did: 49,
						name: 'Sainamaina Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					498: {
						id: 499,
						did: 49,
						name: 'Siddharthanagar Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
					},
					499: {
						id: 500,
						did: 49,
						name: 'Tilottama Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
					},
					500: {
						id: 501,
						did: 49,
						name: 'Gaidahawa Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					501: {
						id: 502,
						did: 49,
						name: 'Kanchan Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					502: {
						id: 503,
						did: 49,
						name: 'Kotahimai Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					503: {
						id: 504,
						did: 49,
						name: 'Marchawari Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					504: {
						id: 505,
						did: 49,
						name: 'Mayadevi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					505: {
						id: 506,
						did: 49,
						name: 'Omsatiya Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					506: {
						id: 507,
						did: 49,
						name: 'Rohini Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					507: {
						id: 508,
						did: 49,
						name: 'Sammarimai Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					508: {
						id: 509,
						did: 49,
						name: 'Siyari Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					509: {
						id: 510,
						did: 49,
						name: 'Suddodhan Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
				},
			},
			49: {
				id: 50,
				pid: 5,
				name: 'Arghakhanchi',
				municipalities: {
					510: {
						id: 511,
						did: 50,
						name: 'Sandhikharka Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					511: {
						id: 512,
						did: 50,
						name: 'Sitganga Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					512: {
						id: 513,
						did: 50,
						name: 'Bhumikasthan Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					513: {
						id: 514,
						did: 50,
						name: 'Chhatradev Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					514: {
						id: 515,
						did: 50,
						name: 'Panini Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					515: {
						id: 516,
						did: 50,
						name: 'Malarani Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
				},
			},
			50: {
				id: 51,
				pid: 5,
				name: 'Gulmi',
				municipalities: {
					516: {
						id: 517,
						did: 51,
						name: 'Resunga Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					517: {
						id: 518,
						did: 51,
						name: 'Musikot Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					518: {
						id: 519,
						did: 51,
						name: 'Rurukshetra Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					519: {
						id: 520,
						did: 51,
						name: 'Chhatrakot Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					520: {
						id: 521,
						did: 51,
						name: 'Gulmidarbar Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					521: {
						id: 522,
						did: 51,
						name: 'Chandrakot Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					522: {
						id: 523,
						did: 51,
						name: 'Satyawati Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					523: {
						id: 524,
						did: 51,
						name: 'Dhurkot Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					524: {
						id: 525,
						did: 51,
						name: 'Kaligandaki Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					525: {
						id: 526,
						did: 51,
						name: 'Isma Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					526: {
						id: 527,
						did: 51,
						name: 'Malika Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					527: {
						id: 528,
						did: 51,
						name: 'Madane Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
				},
			},
			51: {
				id: 52,
				pid: 5,
				name: 'Palpa',
				municipalities: {
					528: {
						id: 529,
						did: 52,
						name: 'Tansen Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					529: {
						id: 530,
						did: 52,
						name: 'Rampur Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					530: {
						id: 531,
						did: 52,
						name: 'Rainadevi Chhahara Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					531: {
						id: 532,
						did: 52,
						name: 'Ripdikot Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					532: {
						id: 533,
						did: 52,
						name: 'Bagnaskali Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					533: {
						id: 534,
						did: 52,
						name: 'Rambha Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					534: {
						id: 535,
						did: 52,
						name: 'Purbakhola Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					535: {
						id: 536,
						did: 52,
						name: 'Nisdi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					536: {
						id: 537,
						did: 52,
						name: 'Mathagadhi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					537: {
						id: 538,
						did: 52,
						name: 'Tinahu Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
				},
			},
			52: {
				id: 53,
				pid: 5,
				name: 'Dang',
				municipalities: {
					538: {
						id: 539,
						did: 53,
						name: 'Ghorahi Sub-Metropolitan City',
						wards: [
							1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
						],
					},
					539: {
						id: 540,
						did: 53,
						name: 'Tulsipur Sub-Metropolitan City',
						wards: [
							1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
						],
					},
					540: {
						id: 541,
						did: 53,
						name: 'Lamahi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					541: {
						id: 542,
						did: 53,
						name: 'Gadhawa Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					542: {
						id: 543,
						did: 53,
						name: 'Rajpur Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					543: {
						id: 544,
						did: 53,
						name: 'Shantinagar Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					544: {
						id: 545,
						did: 53,
						name: 'Rapti Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					545: {
						id: 546,
						did: 53,
						name: 'Banglachuli Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					546: {
						id: 547,
						did: 53,
						name: 'Dangisharan Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					547: {
						id: 548,
						did: 53,
						name: 'Babai Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
				},
			},
			53: {
				id: 54,
				pid: 5,
				name: 'Pyuthan',
				municipalities: {
					548: {
						id: 549,
						did: 54,
						name: 'Sworgadwari Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					549: {
						id: 550,
						did: 54,
						name: 'Pyuthan Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					550: {
						id: 551,
						did: 54,
						name: 'Mandavi Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					551: {
						id: 552,
						did: 54,
						name: 'Sarumarani Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					552: {
						id: 553,
						did: 54,
						name: 'Ayirawati Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					553: {
						id: 554,
						did: 54,
						name: 'Mallarani Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					554: {
						id: 555,
						did: 54,
						name: 'Jhimruk Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					555: {
						id: 556,
						did: 54,
						name: 'Naubahini Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					556: {
						id: 557,
						did: 54,
						name: 'Gaumukhi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
				},
			},
			54: {
				id: 55,
				pid: 5,
				name: 'Rolpa',
				municipalities: {
					557: {
						id: 558,
						did: 55,
						name: 'Rolpa Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					558: {
						id: 559,
						did: 55,
						name: 'Runtigadi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					559: {
						id: 560,
						did: 55,
						name: 'Triveni Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					560: {
						id: 561,
						did: 55,
						name: 'Sunil Smiriti Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					561: {
						id: 562,
						did: 55,
						name: 'Lungri Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					562: {
						id: 563,
						did: 55,
						name: 'Sunchhahari Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					563: {
						id: 564,
						did: 55,
						name: 'Thawang Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					564: {
						id: 565,
						did: 55,
						name: 'Madi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					565: {
						id: 566,
						did: 55,
						name: 'GangaDev Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					566: {
						id: 567,
						did: 55,
						name: 'Pariwartan Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
				},
			},
			55: {
				id: 56,
				pid: 5,
				name: 'Eastern Rukum',
				municipalities: {
					567: {
						id: 568,
						did: 56,
						name: 'Putha Uttarganga Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					568: {
						id: 569,
						did: 56,
						name: 'Bhume Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					569: {
						id: 570,
						did: 56,
						name: 'Sisne Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
				},
			},
			56: {
				id: 57,
				pid: 5,
				name: 'Banke',
				municipalities: {
					570: {
						id: 571,
						did: 57,
						name: 'Nepalgunj Sub-Metropolitan City',
						wards: [
							1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
							20, 21, 22, 23,
						],
					},
					571: {
						id: 572,
						did: 57,
						name: 'Kohalpur Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
					},
					572: {
						id: 573,
						did: 57,
						name: 'Rapti-Sonari Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					573: {
						id: 574,
						did: 57,
						name: 'Narainapur Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					574: {
						id: 575,
						did: 57,
						name: 'Duduwa Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					575: {
						id: 576,
						did: 57,
						name: 'Janaki Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					576: {
						id: 577,
						did: 57,
						name: 'Khajura Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					577: {
						id: 578,
						did: 57,
						name: 'Baijanath Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
				},
			},
			57: {
				id: 58,
				pid: 5,
				name: 'Bardiya',
				municipalities: {
					578: {
						id: 579,
						did: 58,
						name: 'Gulariya Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					579: {
						id: 580,
						did: 58,
						name: 'Rajapur Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					580: {
						id: 581,
						did: 58,
						name: 'Madhuwan Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					581: {
						id: 582,
						did: 58,
						name: 'Thakurbaba Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					582: {
						id: 583,
						did: 58,
						name: 'Basgadhi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					583: {
						id: 584,
						did: 58,
						name: 'Barbardiya Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					584: {
						id: 585,
						did: 58,
						name: 'Badhaiyatal Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					585: {
						id: 586,
						did: 58,
						name: 'Geruwa Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
				},
			},
		},
	},
	{
		id: 6,
		name: 'Karnali',
		districts: {
			58: {
				id: 59,
				pid: 6,
				name: 'Western Rukum',
				municipalities: {
					586: {
						id: 587,
						did: 59,
						name: 'Aathabiskot Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					587: {
						id: 588,
						did: 59,
						name: 'Musikot Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					588: {
						id: 589,
						did: 59,
						name: 'Chaurjahari Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					589: {
						id: 590,
						did: 59,
						name: 'SaniBheri Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					590: {
						id: 591,
						did: 59,
						name: 'Triveni Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					591: {
						id: 592,
						did: 59,
						name: 'Banphikot Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
				},
			},
			59: {
				id: 60,
				pid: 6,
				name: 'Salyan',
				municipalities: {
					592: {
						id: 593,
						did: 60,
						name: 'Kumakh Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					593: {
						id: 594,
						did: 60,
						name: 'Kalimati Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					594: {
						id: 595,
						did: 60,
						name: 'Chhatreshwari Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					595: {
						id: 596,
						did: 60,
						name: 'Darma Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					596: {
						id: 597,
						did: 60,
						name: 'Kapurkot Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					597: {
						id: 598,
						did: 60,
						name: 'Triveni Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					598: {
						id: 599,
						did: 60,
						name: 'Siddha Kumakh Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					599: {
						id: 600,
						did: 60,
						name: 'Bagchaur Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					600: {
						id: 601,
						did: 60,
						name: 'Shaarda Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
					},
					601: {
						id: 602,
						did: 60,
						name: 'Bangad Kupinde Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
				},
			},
			60: {
				id: 61,
				pid: 6,
				name: 'Dolpa',
				municipalities: {
					602: {
						id: 603,
						did: 61,
						name: 'Mudkechula Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					603: {
						id: 604,
						did: 61,
						name: 'Kaike Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					604: {
						id: 605,
						did: 61,
						name: 'She Phoksundo Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					605: {
						id: 606,
						did: 61,
						name: 'Jagadulla Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					606: {
						id: 607,
						did: 61,
						name: 'Dolpo Buddha Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					607: {
						id: 608,
						did: 61,
						name: 'Chharka Tongsong Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					608: {
						id: 609,
						did: 61,
						name: 'Thuli Bheri Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					609: {
						id: 610,
						did: 61,
						name: 'Tripurasundari Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
				},
			},
			61: {
				id: 62,
				pid: 6,
				name: 'Humla',
				municipalities: {
					610: {
						id: 611,
						did: 62,
						name: 'Simkot Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					611: {
						id: 612,
						did: 62,
						name: 'Sarkegad Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					612: {
						id: 613,
						did: 62,
						name: 'Adanchuli Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					613: {
						id: 614,
						did: 62,
						name: 'Kharpunath Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					614: {
						id: 615,
						did: 62,
						name: 'Tanjakot Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					615: {
						id: 616,
						did: 62,
						name: 'Chankheli Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					616: {
						id: 617,
						did: 62,
						name: 'Namkha Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
				},
			},
			62: {
				id: 63,
				pid: 6,
				name: 'Jumla',
				municipalities: {
					617: {
						id: 618,
						did: 63,
						name: 'Tatopani Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					618: {
						id: 619,
						did: 63,
						name: 'Patarasi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					619: {
						id: 620,
						did: 63,
						name: 'Tila Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					620: {
						id: 621,
						did: 63,
						name: 'Kanaka Sundari Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					621: {
						id: 622,
						did: 63,
						name: 'Sinja Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					622: {
						id: 623,
						did: 63,
						name: 'Hima Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					623: {
						id: 624,
						did: 63,
						name: 'Guthichaur Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					624: {
						id: 625,
						did: 63,
						name: 'Chandannath Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
				},
			},
			63: {
				id: 64,
				pid: 6,
				name: 'Kalikot',
				municipalities: {
					625: {
						id: 626,
						did: 64,
						name: 'Khandachakra Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					626: {
						id: 627,
						did: 64,
						name: 'Raskot Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					627: {
						id: 628,
						did: 64,
						name: 'Tilagufa Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					628: {
						id: 629,
						did: 64,
						name: 'Narharinath Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					629: {
						id: 630,
						did: 64,
						name: 'Palata Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					630: {
						id: 631,
						did: 64,
						name: 'Shubha Kalika Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					631: {
						id: 632,
						did: 64,
						name: 'Sanni Triveni Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					632: {
						id: 633,
						did: 64,
						name: 'Pachaljharana Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					633: {
						id: 634,
						did: 64,
						name: 'Mahawai Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
				},
			},
			64: {
				id: 65,
				pid: 6,
				name: 'Mugu',
				municipalities: {
					634: {
						id: 635,
						did: 65,
						name: 'Khatyad Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					635: {
						id: 636,
						did: 65,
						name: 'Soru Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					636: {
						id: 637,
						did: 65,
						name: 'Mugum Karmarong Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					637: {
						id: 638,
						did: 65,
						name: 'Chhayanath Rara Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
				},
			},
			65: {
				id: 66,
				pid: 6,
				name: 'Surkhet',
				municipalities: {
					638: {
						id: 639,
						did: 66,
						name: 'Simta Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					639: {
						id: 640,
						did: 66,
						name: 'Barahatal Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					640: {
						id: 641,
						did: 66,
						name: 'Chaukune Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					641: {
						id: 642,
						did: 66,
						name: 'Chingad Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					642: {
						id: 643,
						did: 66,
						name: 'Gurbhakot Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					643: {
						id: 644,
						did: 66,
						name: 'Birendranagar Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
					},
					644: {
						id: 645,
						did: 66,
						name: 'Bheriganga Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
					},
					645: {
						id: 646,
						did: 66,
						name: 'Panchapuri Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					646: {
						id: 647,
						did: 66,
						name: 'Lekbeshi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
				},
			},
			66: {
				id: 67,
				pid: 6,
				name: 'Dailekh',
				municipalities: {
					647: {
						id: 648,
						did: 67,
						name: 'Dullu Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
					},
					648: {
						id: 649,
						did: 67,
						name: 'Gurans Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					649: {
						id: 650,
						did: 67,
						name: 'Bhairabi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					650: {
						id: 651,
						did: 67,
						name: 'Naumule Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					651: {
						id: 652,
						did: 67,
						name: 'Mahabu Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					652: {
						id: 653,
						did: 67,
						name: 'Thantikandh Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					653: {
						id: 654,
						did: 67,
						name: 'Bhagawatimai Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					654: {
						id: 655,
						did: 67,
						name: 'Dungeshwar Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					655: {
						id: 656,
						did: 67,
						name: 'Aathabis Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					656: {
						id: 657,
						did: 67,
						name: 'Narayan Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					657: {
						id: 658,
						did: 67,
						name: 'Chamunda Bindrasaini Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
				},
			},
			67: {
				id: 68,
				pid: 6,
				name: 'Jajarkot',
				municipalities: {
					658: {
						id: 659,
						did: 68,
						name: 'Chhedagad Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
					},
					659: {
						id: 660,
						did: 68,
						name: 'Bheri Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
					},
					660: {
						id: 661,
						did: 68,
						name: 'Nalgad Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
					},
					661: {
						id: 662,
						did: 68,
						name: 'Junichande Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					662: {
						id: 663,
						did: 68,
						name: 'Kuse Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					663: {
						id: 664,
						did: 68,
						name: 'Barekot Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					664: {
						id: 665,
						did: 68,
						name: 'Shivalaya Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
				},
			},
		},
	},
	{
		id: 7,
		name: 'Sudur Paschimanchal',
		districts: {
			68: {
				id: 69,
				pid: 7,
				name: 'Darchula',
				municipalities: {
					665: {
						id: 666,
						did: 69,
						name: 'Mahakali Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					666: {
						id: 667,
						did: 69,
						name: 'Shailyashikhar Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					667: {
						id: 668,
						did: 69,
						name: 'Naugad Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					668: {
						id: 669,
						did: 69,
						name: 'Malikarjun Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					669: {
						id: 670,
						did: 69,
						name: 'Marma Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					670: {
						id: 671,
						did: 69,
						name: 'Lekam Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					671: {
						id: 672,
						did: 69,
						name: 'Duhun Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					672: {
						id: 673,
						did: 69,
						name: 'Vyans Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					673: {
						id: 674,
						did: 69,
						name: 'Apihimal Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
				},
			},
			69: {
				id: 70,
				pid: 7,
				name: 'Bajhang',
				municipalities: {
					674: {
						id: 675,
						did: 70,
						name: 'Jayaprithvi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					675: {
						id: 676,
						did: 70,
						name: 'Bungal Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					676: {
						id: 677,
						did: 70,
						name: 'Kedarsyu Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					677: {
						id: 678,
						did: 70,
						name: 'Thalara Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					678: {
						id: 679,
						did: 70,
						name: 'Bitthadchir Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					679: {
						id: 680,
						did: 70,
						name: 'Chhabis Pathibhera Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					680: {
						id: 681,
						did: 70,
						name: 'Khaptadchhanna Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					681: {
						id: 682,
						did: 70,
						name: 'Masta Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					682: {
						id: 683,
						did: 70,
						name: 'Durgathali Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					683: {
						id: 684,
						did: 70,
						name: 'Talkot Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					684: {
						id: 685,
						did: 70,
						name: 'Surma Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					685: {
						id: 686,
						did: 70,
						name: 'Saipal Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
				},
			},
			70: {
				id: 71,
				pid: 7,
				name: 'Bajura',
				municipalities: {
					686: {
						id: 687,
						did: 71,
						name: 'Badimalika Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					687: {
						id: 688,
						did: 71,
						name: 'Triveni Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					688: {
						id: 689,
						did: 71,
						name: 'Budhiganga Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					689: {
						id: 690,
						did: 71,
						name: 'Budhinanda Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					690: {
						id: 691,
						did: 71,
						name: 'Khaptad Chhededaha Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					691: {
						id: 692,
						did: 71,
						name: 'Swami Kartik Khapar Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					692: {
						id: 693,
						did: 71,
						name: 'Jagannath Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					693: {
						id: 694,
						did: 71,
						name: 'Himali Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					694: {
						id: 695,
						did: 71,
						name: 'Gaumul Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
				},
			},
			71: {
				id: 72,
				pid: 7,
				name: 'Baitadi',
				municipalities: {
					695: {
						id: 696,
						did: 72,
						name: 'Dashrathchanda Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					696: {
						id: 697,
						did: 72,
						name: 'Patan Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					697: {
						id: 698,
						did: 72,
						name: 'Melauli Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					698: {
						id: 699,
						did: 72,
						name: 'Purchaudi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					699: {
						id: 700,
						did: 72,
						name: 'Dogdakedar Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					700: {
						id: 701,
						did: 72,
						name: 'Dilashaini Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					701: {
						id: 702,
						did: 72,
						name: 'Sigas Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					702: {
						id: 703,
						did: 72,
						name: 'Pancheshwar Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					703: {
						id: 704,
						did: 72,
						name: 'Surnaya Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					704: {
						id: 705,
						did: 72,
						name: 'Shivanath Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
				},
			},
			72: {
				id: 73,
				pid: 7,
				name: 'Doti',
				municipalities: {
					705: {
						id: 706,
						did: 73,
						name: 'Dipayal Silgadhi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					706: {
						id: 707,
						did: 73,
						name: 'Shikhar Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					707: {
						id: 708,
						did: 73,
						name: 'Aadarsha Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					708: {
						id: 709,
						did: 73,
						name: 'Purbichauki Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					709: {
						id: 710,
						did: 73,
						name: 'K.I.Singh Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					710: {
						id: 711,
						did: 73,
						name: 'Jorayal Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					711: {
						id: 712,
						did: 73,
						name: 'Sayal Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					712: {
						id: 713,
						did: 73,
						name: 'Bogatan-Phudsil Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					713: {
						id: 714,
						did: 73,
						name: 'Badikedar Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
				},
			},
			73: {
				id: 74,
				pid: 7,
				name: 'Acham',
				municipalities: {
					714: {
						id: 715,
						did: 74,
						name: 'Ramaroshan Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					715: {
						id: 716,
						did: 74,
						name: 'Chaurpati Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					716: {
						id: 717,
						did: 74,
						name: 'Turmakhand Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					717: {
						id: 718,
						did: 74,
						name: 'Mellekh Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					718: {
						id: 719,
						did: 74,
						name: 'Dhakari Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					719: {
						id: 720,
						did: 74,
						name: 'Bannigadi Jayagad Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					720: {
						id: 721,
						did: 74,
						name: 'Mangalsen Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					721: {
						id: 722,
						did: 74,
						name: 'Kamalbazar Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					722: {
						id: 723,
						did: 74,
						name: 'Sanfebagar Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
					},
					723: {
						id: 724,
						did: 74,
						name: 'Panchadewal Binayak Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
				},
			},
			74: {
				id: 75,
				pid: 7,
				name: 'Dadeldhura',
				municipalities: {
					724: {
						id: 725,
						did: 75,
						name: 'Navadurga Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					725: {
						id: 726,
						did: 75,
						name: 'Aalitaal Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8],
					},
					726: {
						id: 727,
						did: 75,
						name: 'Ganyapadhura Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					727: {
						id: 728,
						did: 75,
						name: 'Bhageshwar Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
					728: {
						id: 729,
						did: 75,
						name: 'Ajaymeru Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					729: {
						id: 730,
						did: 75,
						name: 'Amargadhi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					730: {
						id: 731,
						did: 75,
						name: 'Parshuram Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
				},
			},
			75: {
				id: 76,
				pid: 7,
				name: 'Kanchanpur',
				municipalities: {
					731: {
						id: 732,
						did: 76,
						name: 'Bhimdatta Municipality',
						wards: [
							1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
						],
					},
					732: {
						id: 733,
						did: 76,
						name: 'Punarbas Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					733: {
						id: 734,
						did: 76,
						name: 'Bedkot Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					734: {
						id: 735,
						did: 76,
						name: 'Mahakali Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					735: {
						id: 736,
						did: 76,
						name: 'Shuklaphanta Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					736: {
						id: 737,
						did: 76,
						name: 'Belauri Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					737: {
						id: 738,
						did: 76,
						name: 'Krishnapur Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					738: {
						id: 739,
						did: 76,
						name: 'Laljhadi Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					739: {
						id: 740,
						did: 76,
						name: 'Beldandi Rural Municipality',
						wards: [1, 2, 3, 4, 5],
					},
				},
			},
			76: {
				id: 77,
				pid: 7,
				name: 'Kailali',
				municipalities: {
					740: {
						id: 741,
						did: 77,
						name: 'Janaki Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					741: {
						id: 742,
						did: 77,
						name: 'Kailari Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					742: {
						id: 743,
						did: 77,
						name: 'Joshipur Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					743: {
						id: 744,
						did: 77,
						name: 'Bardagoriya Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					744: {
						id: 745,
						did: 77,
						name: 'Mohanyal Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7],
					},
					745: {
						id: 746,
						did: 77,
						name: 'Chure Rural Municipality',
						wards: [1, 2, 3, 4, 5, 6],
					},
					746: {
						id: 747,
						did: 77,
						name: 'Tikapur Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					747: {
						id: 748,
						did: 77,
						name: 'Ghodaghodi Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					748: {
						id: 749,
						did: 77,
						name: 'Lamkichuha Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					},
					749: {
						id: 750,
						did: 77,
						name: 'Bhajni Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9],
					},
					750: {
						id: 751,
						did: 77,
						name: 'Godawari Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					},
					751: {
						id: 752,
						did: 77,
						name: 'Gauriganga Municipality',
						wards: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					},
					752: {
						id: 753,
						did: 77,
						name: 'Dhangadhi Sub-Metropolitan City',
						wards: [
							1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
						],
					},
				},
			},
		},
	},
];

export default data;
