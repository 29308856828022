import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
function ControlledEditor({
  setValue = null,
  setState = null,
  id = null,
  state = null,
  defaultValue = null,
  error,
}) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (es) => {
    let html = draftToHtml(convertToRaw(es.getCurrentContent()));
    setEditorState({
      editorState: es,
    });
    if (id && setValue) {
      setValue(id, html);
    }
    if (setState) {
      setState(html);
    }
  };
  useEffect(() => {
    if (defaultValue) {
      let blocksFromHTML = convertFromHTML(defaultValue);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState({
        editorState: EditorState.createWithContent(state),
      });
    }
    if (state) {
      let blocksFromHTML = convertFromHTML(state);
      const s = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState({
        editorState: EditorState.createWithContent(s),
      });
    }
  }, [defaultValue, state]);
  return (
    <div>
      <Editor
        editorState={editorState.editorState}
        wrapperClassName={[
          "border border-2 border-slate-200 rounded-xl overflow-hidden",
          error ? "border-red-600" : "",
        ].join(" ")}
        editorClassName={[
          " border-t-2 border-slate-200 min-h-[25vh] bg-slate-100 px-4",
          error ? "border-red-600 bg-red-200" : "",
        ].join(" ")}
        placeholder="Enter Description Here.....(Minimum 100 characters)"
        onEditorStateChange={onEditorStateChange}
      />
      {/* <textarea disabled value={draftToHtml(convertToRaw(editorState.getCurrentContent()))} /> */}
      {error && <p className=" text-red-600">{JSON.stringify(error)}</p>}
    </div>
  );
}
export default ControlledEditor;
