export default function Loading() {
	return (
		<div className="w-screen h-screen grid place-content-center">
			<div className=" w-20 h-12 relative">
				<span className="absolute text-sm top-0 p-0 m-0 text-lime-700 tracking-[1px] animate-text713">
					loading
				</span>
				<span className="bg-lime-900 rounded-xl block h-4 w-4 bottom-0 absolute translate-x-16 animate-loading713 before:absolute before:w-full before:h-full before:bg-lime-300 before:animate-loading2713 before:rounded-[inherit]"></span>
			</div>
		</div>
	);
}
