import { useState } from "react";

export default function UnorderedPagination({ total, onPageChange = null }) {
  const [active, setActive] = useState(0);
  return (
    <div className="flex justify-center w-full gap-x-6 my-4">
      {Array.from({ length: Math.ceil(total / 4) }, (_, i) => i).map((v) => (
        <button
          key={v}
          onClick={() => {
            setActive(v);
            onPageChange && onPageChange(v);
          }}
          className={[
            "w-4 h-2 rounded",
            active === v ? "bg-primary" : "bg-slate-400",
          ].join(" ")}
        />
      ))}
    </div>
  );
}
