import { IoBed, IoCarSport } from 'react-icons/io5';
import { FaKitchenSet, FaSquareParking, FaRoad } from 'react-icons/fa6';
import { MdBathroom } from 'react-icons/md';
import { LiaDatabaseSolid } from 'react-icons/lia';
import { RiMotorbikeFill } from 'react-icons/ri';

export const amenities = [
	'Air Condition',
	'Balcony',
	'Fencing',
	'Garden',
	'Gym',
	'Microwave',
	'Modular Kitchen',
	'Swimming Pool',
	'TV Cable',
	'Washing Machine',
	'Wi-Fi',
	'Drainage',
	'Water Supply',
	'Solar Water',
	'CCTV',
	'Electric Backup',
	'Internet',
	'Lift',
	'Parking',
	'Security Staff',
	'Store Room',
];

export const featureIcons = {
	bedroom: <IoBed />,
	kitchen: <FaKitchenSet />,
	bathroom: <MdBathroom />,
	floors: <LiaDatabaseSolid />,
	parking: <FaSquareParking />,
	bikes: <RiMotorbikeFill />,
	cars: <IoCarSport />,
	roadAccess: <FaRoad />,
};
