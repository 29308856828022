import { Carousel } from "flowbite-react";
import { LightgalleryItem } from "react-lightgallery";

export default function Gallery({
  image,
  className,
  containerClassName,
  groupName,
  handleCarouselChange,
}) {
  if (Array.isArray(image)) {
    return (
      <div className={containerClassName}>
        <Carousel pauseOnHover onSlideChange={handleCarouselChange}>
          {image?.map((img, i) => (
            <LightgalleryItem
              key={i}
              group={groupName}
              itemClassName={
                typeof className === "string" ? className : className(i, img)
              }
              src={img?.src}
            >
              <img
                src={img?.src}
                className="w-full h-full max-h-[60dvh] object-contain object-center"
                alt={img?.title}
              />
            </LightgalleryItem>
          ))}
        </Carousel>
      </div>
    );
  } else {
    return (
      <div>
        <LightgalleryItem
          group={image?.title}
          src={image?.src}
          //   itemClassName={"max-h-[60vh] w-full overflow-hidden"}
        >
          <div className="w-full h-[50vh] overflow-hidden">
            <img src={image?.src} className={className} alt={image?.title} />
          </div>
        </LightgalleryItem>
      </div>
    );
  }
}
