import { useMemo } from "react";

export default function TikTokEmbed({ url }) {
  const embedId = useMemo(() => {
    if (!url) return null;
    // Create a URL object from the string
    if (url.includes("https://www.tiktok.com/@gprealestate/video/")) {
      const urlObj = new URL(url);
      // Split the pathname to find the video ID
      const pathParts = urlObj.pathname.split("/");

      // The video ID is the last part of the path
      const videoId = pathParts[pathParts.length - 1];

      // Return the video ID
      return videoId;
    }
    return null;
  }, [url]);
  return (
    <center>
      {embedId && (
        <iframe
          src={`https://www.tiktok.com/embed/${embedId}`}
          allowfullscreen
          width={"100%"}
          className="aspect-[3_/_4]"
          scrolling="no"
          allow="encrypted-media;"
          title="TikTok Video"
        ></iframe>
      )}
    </center>
  );
}
