import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, FileInput, Label, TextInput } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useAlert } from "../../../../_contexts/AlertContext";
import {
  getPartner,
  patchPartner,
  postPartner,
  upload,
} from "../../../../apis";
const PartnerCreate = () => {
  const navigate = useNavigate();
  const [_fileName, setFileName] = useState("");
  const { setAlert } = useAlert();
  const { partnerId } = useParams();

  const role = JSON.parse(localStorage.getItem("gp_user")).user.role;
  useEffect(() => {
    if (role === "user") return navigate("/admin/user/1");
  }, [role, navigate]);
  const { mutate } = useMutation({
    mutationKey: ["partner", ...(partnerId ? [partnerId] : [])],
    mutationFn: partnerId ? patchPartner : postPartner,
    onSuccess: () => {
      navigate("/admin/settings/list/partner/1");
    },
  });
  const { data } = useQuery({
    queryKey: ["partner", partnerId],
    queryFn: () => getPartner(partnerId),
    enabled: !!partnerId,
  });

  //
  const { register, handleSubmit, setValue } = useForm({
    mode: "onBlur",
    defaultValues: {},
    // resolver: yupResolver(propertySchema()),
  });
  useEffect(() => {
    if (data?.data?.image) {
      //
      setValue("image", data?.data?.image);
    }
  }, [data, setValue]);

  function onSubmit(data) {
    mutate(data);
  }
  //
  return (
    <>
      <form className="md:py-8 py-10 flex flex-col gap-y-8">
        <fieldset className="grid md:grid-cols-2 grid-cols-1 gap-4">
          <legend className="text-2xl font-medium text-lime-700 col-span-2">
            Partner Information
          </legend>
          <div>
            <Label htmlFor="name">Name</Label>
            <TextInput
              type="text"
              placeholder="Enter name of User"
              defaultValue={data?.data?.name}
              {...register("name")}
            />
          </div>

          <div>
            <Label htmlFor="image">Image</Label>
            <FileInput
              accept="image/*"
              id="file-upload"
              onChange={upload("image", setValue, setAlert, setFileName)}
            />
          </div>
        </fieldset>
        <div className="flex justify-end pt-8">
          <Button
            className="w-1/2"
            color="success"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default PartnerCreate;
