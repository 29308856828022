import React, { useEffect, useRef } from "react";

const IntersectionObserverComponent = ({
  children,
  setIsVisible = null,
  threshold = 0.99,
  forwards = false,
  className = "",
}) => {
  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible && setIsVisible(true);
          } else {
            !forwards && setIsVisible && setIsVisible(false);
          }
        });
      },
      { threshold: 0.99, onUnobserve: () => !forwards && setIsVisible(false) }
    );

    let target = targetRef?.current;

    if (target) {
      observer.observe(target);
    }

    // Cleanup the observer when the component is unmounted
    return () => {
      if (target) {
        observer.unobserve(target);
        setIsVisible && setIsVisible(false);
      }
    };
  }, [setIsVisible, threshold, forwards]);

  return (
    <div ref={targetRef} className={className}>
      {children}
    </div>
  );
};

export default IntersectionObserverComponent;
