import { useMutation } from "@tanstack/react-query";
import { Button, Label, TextInput } from "flowbite-react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { postOffer } from "../../../apis";
const OfferCreate = () => {
  const navigate = useNavigate();
  const role = JSON.parse(localStorage.getItem("gp_user")).user.role;
  useEffect(() => {
    if (role === "user") return navigate("/admin/user/1");
  }, [role, navigate]);
  const { mutate } = useMutation({
    mutationKey: ["offers"],
    mutationFn: postOffer,
    onSuccess: () => {
      navigate("/admin/offer");
    },
  });
  //   const { mutate: StatusMutate } = useMutation({
  //     mutationKey: ["status"],
  //     mutationFn: (id) => postStatus(id),
  //     onSuccess: () => {
  //       navigate("/admin/offer");
  //     },
  //   });
  const { register, handleSubmit } = useForm({
    mode: "onBlur",
    defaultValues: {},
    // resolver: yupResolver(propertySchema()),
  });
  function onSubmit(data) {
    mutate(data);
    // postStatus({ id: property, data: status });
    // StatusMutate(status);
  }

  return (
    <>
      <form className="md:py-8 py-10 flex flex-col gap-y-8">
        <fieldset className="grid md:grid-cols-2 grid-cols-1 gap-4">
          <legend className="text-2xl font-medium text-lime-700 col-span-2">
            Offer Information
          </legend>
          <div>
            <Label htmlFor="offer">Offer</Label>
            <TextInput
              type="number"
              placeholder="Enter offer"
              {...register("offer")}
            />
          </div>
          <div>
            <Label htmlFor="propertyId">Property ID</Label>
            <TextInput
              type="text"
              placeholder="Enter property(property ID)"
              {...register("property")}
            />
          </div>
          <div>
            <Label htmlFor="phone">Phone</Label>
            <TextInput
              type="phone"
              placeholder="Enter phone number"
              {...register("phone")}
            />
          </div>
          <div>
            <Label htmlFor="email">Email</Label>
            <TextInput
              type="email"
              placeholder="Enter Email"
              {...register("email")}
            />
          </div>
          <div>
            <Label htmlFor="name">Name</Label>
            <TextInput
              type="text"
              placeholder="Enter Name"
              {...register("name")}
            />
          </div>
        </fieldset>
        <div className="flex justify-end pt-8">
          <Button
            className="w-1/2"
            color="success"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default OfferCreate;
