import { useMutation, useQuery } from "@tanstack/react-query";
import { Avatar } from "flowbite-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAlert } from "../../../../_contexts/AlertContext";
import { deleteCustomer, getCustomers } from "../../../../apis";
import Table from "../../../../components/Table";
function Customer() {
  const { setAlert } = useAlert();
  const { pageNum } = useParams();
  const [page, setPage] = useState(pageNum || 1);
  const { isLoading, data, refetch } = useQuery({
    queryKey: ["customers"],
    queryFn: () => getCustomers(),
  });

  const { mutate: deleteProp } = useMutation({
    mutationKey: ["customer"],
    mutationFn: deleteCustomer,
    onSuccess: () => {
      setAlert({
        type: "Success",
        message: "Customer Deleted Successfully",
        isOpen: true,
      });
      refetch();
    },
  });
  const headers = [
    {
      key: "name",
      title: "Name",
      render: (data) => (
        <div className="flex gap-x-3 items-center">
          <Avatar
            img={data?.image || `/assets/avatar.png`}
            alt={data?.name}
            rounded={false}
          />{" "}
          {data?.name}
        </div>
      ),
    },
    {
      key: "phone",
      title: "Phone",
    },
    {
      key: "email",
      title: "Email",
    },
    {
      key: "type",
      title: "Type",
    },
    {
      key: "_action",
      title: "Actions",
      actions: { delete: (data) => deleteProp(data?._id) },
    },
  ];

  return (
    <>
      <div className="flex justify-between items-center flex-row md:pb-0 pb-4 md:py-0 py-12">
        <h2 className="md:text-3xl text-2xl font-semibold md:mb-6 mb-0 ">
          Customers
        </h2>
        {/* <Button outline gradientDuoTone="greenToBlue" pill onClick={() => navigate('/admin/customer/create')}>
					<HiPlus className="mr-2 h-5 w-5" />
					Add Customer
				</Button> */}
      </div>
      <div className="overflow-scroll md:overflow-auto scrollbar-hide">
        <Table
          headers={headers}
          data={data?.data?.results}
          // checkState={checkState}
          isLoading={isLoading}
          page={page}
          setPage={setPage}
          total={data?.data?.totalPages || 1}
          // withCheckBoxes
          //
        />
      </div>
    </>
  );
}

export default Customer;
