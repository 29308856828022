import { useMutation } from "@tanstack/react-query";
import { Button, Label, TextInput } from "flowbite-react";
import React from "react";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import { postLogin } from "../../apis";

const Login = () => {
  const user = JSON.parse(localStorage.getItem("gp_user"));
  const navigate = useNavigate();

  const { register, handleSubmit } = useForm({
    mode: "onBlur",
  });

  const { mutate } = useMutation({
    mutationKey: ["login"],
    mutationFn: postLogin,
    onSuccess: (data) => {
      localStorage.setItem("gp_user", JSON.stringify(data?.data));
      navigate("/admin");
    },
  });

  async function onLogin(data) {
    try {
      mutate(data);
    } catch (error) {}
  }

  if (user?.user) {
    return <Navigate to="/admin" />;
  }
  return (
    <div className="w-screen h-screen flex items-center justify-center bg-slate-200 -m-4">
      <div className="p-4 bg-white rounded-md">
        <img
          src="/assets/gp_logo.png"
          onClick={() => navigate("/")}
          className="h-16 mx-auto mb-4 cursor-pointer"
          alt="gp realestate"
        />
        <h1 className="font-bold text-center mb-4">
          <span className="block text-sm">Welcome to</span>
          <span className="text-lime-600">GP Real Estate Dashboard Login</span>
          <span className="block"></span>
        </h1>
        <div className="flex flex-col space-y-4">
          <div>
            <Label>Username</Label>
            <TextInput className="w-full" {...register("username")} />
          </div>
          <div>
            <Label>Password</Label>
            <TextInput
              type="password"
              className="w-full"
              {...register("password")}
            />
          </div>
          <div>
            <Button
              className="w-full"
              type="submit"
              onClick={handleSubmit(onLogin)}
            >
              Login
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
