import PropertyInfo from "../Property/index.jsx";
import PropertyCreate from "./Create/PropertyCreate";
import Dashboard from "./Dashboard";
import FAQCreate from "./FAQ/FaqCreate.jsx";
import FAQ from "./FAQ/index.jsx";
import Lead from "./Lead/Lead.jsx";
import LeadDetails from "./Lead/LeadDetails.jsx";
import Login from "./Login";
import Message from "./Message";
import Offer from "./Offer/Offer.jsx";
import OfferCreate from "./Offer/OfferCreate.jsx";
import Property from "./Property";
import SeoCreate from "./SEO/SeoCreate.jsx";
import SEO from "./SEO/index.jsx";
import MyCalendar from "./Settings/Calendar";
import Contents from "./Settings/Contents";
import Customer from "./Settings/Customers";
import PartnerCreate from "./Settings/Partners/PartnerCreate.jsx";
import Partners from "./Settings/Partners/index";
import UserCreate from "./Settings/Users/UserCreate";
import UserInfo from "./Settings/Users/UserInfo.jsx";
import User from "./Settings/Users/index";
import TeamCreate from "./Team/TeamCreate.jsx";
import Teams from "./Team/index.jsx";
export default Object.assign({
  Dashboard,
  Message,
  lead: { lead: Lead, leadDetail: LeadDetails },

  list: {
    property: { property: Property, propertyDetail: PropertyInfo },
    user: { user: User, userDetail: UserInfo },
    contents: Contents,
    customer: Customer,
    partner: Partners,
    offer: Offer,
    team: Teams,
    faq: FAQ,
    seo: SEO,
  },
  create: {
    property: PropertyCreate,
    user: UserCreate,
    partner: PartnerCreate,
    offer: OfferCreate,
    team: TeamCreate,
    faq: FAQCreate,
    seo: SeoCreate,
  },

  edit: {},
  login: Login,
  calender: MyCalendar,
});
