import { useMutation } from "@tanstack/react-query";
import { Button, Label, TextInput } from "flowbite-react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { patchFaq, postFaq } from "../../../apis";
import ControlledEditor from "../../../components/ControlledEditor";
const FAQCreate = () => {
  const navigate = useNavigate();
  // const { setAlert } = useAlert();
  const { faqId } = useParams();

  const role = JSON.parse(localStorage.getItem("gp_user")).user.role;
  useEffect(() => {
    if (role === "user") return navigate("/admin/user/1");
  }, [navigate, role]);
  const { mutate } = useMutation({
    mutationKey: ["faq", ...(faqId ? [faqId] : [])],
    mutationFn: faqId ? patchFaq : postFaq,
    onSuccess: () => {
      navigate("/admin/faq");
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });

  function onSubmit(data) {
    mutate(data);
  }
  return (
    <>
      <form className="md:py-8 py-10 flex flex-col gap-y-8">
        <fieldset className="grid grid-cols-1 gap-4">
          <legend className="text-2xl font-medium text-lime-700 col-span-2">
            Partner Information
          </legend>
          <div>
            <Label htmlFor="question">Question</Label>
            <TextInput
              type="text"
              placeholder="Enter question"
              // defaultValue={data?.data?.name}
              {...register("question")}
            />
          </div>
          <div>
            <Label htmlFor="answer">Answer</Label>

            <ControlledEditor
              id="answer"
              setValue={setValue}
              // defaultValue={data?.data?.answer || ""}
              error={errors?.answer?.message}
            />
          </div>
        </fieldset>
        <div className="flex justify-end pt-8">
          <Button
            className="w-1/2"
            color="success"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default FAQCreate;
