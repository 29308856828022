import { useQuery } from "@tanstack/react-query";
import { getContent, getPopularProperties } from "../../apis";
import CardComp from "./components/LandingCards";
export default function Popular() {
  const { data, isLoading } = useQuery({
    queryKey: ["popularProperties"],
    queryFn: getPopularProperties,
  });
  const { data: popularData } = useQuery({
    queryKey: ["content"],
    queryFn: () => getContent(),
  });
  return (
    <section
      id="popular"
      className=" bg-slate-50 md:h-screen md:flex md:flex-col md:justify-center"
      style={{ scrollSnapAlign: "center" }}
    >
      <div className="text-center py-12 md:space-y-2">
        <h2 className="text-2xl md:text-4xl font-semibold text-lime-600">
          {popularData?.data?.popular?.mainText || "Popular Properties"}
        </h2>
        <p className="text-sm md:text-base font-medium">
          {popularData?.data?.popular?.subText ||
            "Whether you seek sprawling land, a charming home, or a cozy apartment,we have it all. Explore your options and find your perfect match."}
        </p>
      </div>
      {!isLoading && <CardComp cards={data?.data?.results} />}
    </section>
  );
}
