import React from 'react';
import { MdKeyboardReturn } from 'react-icons/md';
import { Link } from 'react-router-dom';

const TermsAndConditions = () => (
	<div className="container mx-auto mt-8 p-4">
		<h2 className="text-3xl font-bold mb-4">Terms and Conditions</h2>

		<p className="mb-4">
			Welcome to gprealestate.com.np, a website operated by GP Real Estate Pvt.
			Ltd. ("GP Real Estate," "we," "us," or "our"). By accessing or using our
			website, you agree to comply with and be bound by the following terms and
			conditions. Please read these terms carefully before using our services.
		</p>

		<section className="mb-6">
			<h3 className="text-xl font-bold mb-2">1. Acceptance of Terms</h3>

			<p>
				By accessing or using gprealestate.com.np, you agree to be bound by
				these Terms and Conditions. If you do not agree with any part of these
				terms, you may not use our services.
			</p>
		</section>

		<section className="mb-6">
			<h3 className="text-xl font-bold mb-2">2. Use of Services</h3>

			<p>
				You agree to use our services for lawful purposes and in a manner
				consistent with all applicable laws and regulations. You may not use our
				services in any way that could interfere with the normal operation of
				the website or harm other users.
			</p>
		</section>

		<section className="mb-6">
			<h3 className="text-xl font-bold mb-2">3. Intellectual Property</h3>

			<p>
				All content on gprealestate.com.np, including text, graphics, logos,
				images, and software, is the property of GP Real Estate or its content
				suppliers and is protected by international copyright laws. You may not
				use, reproduce, modify, distribute, or display any part of this site
				without our prior written consent.
			</p>
		</section>

		<section className="mb-6">
			<h3 className="text-xl font-bold mb-2">4. Privacy Policy</h3>

			<p>
				Your use of our services is also governed by our Privacy Policy. Please
				review our Privacy Policy to understand how we collect, use, and protect
				your personal information.
			</p>
		</section>

		<section className="mb-6">
			<h3 className="text-xl font-bold mb-2">5. Limitation of Liability</h3>

			<p>
				GP Real Estate and its affiliates shall not be liable for any direct,
				indirect, incidental, consequential, or punitive damages arising out of
				your use of, or inability to use, our services. This includes but is not
				limited to, damages for loss of profits, goodwill, data, or other
				intangible losses.
			</p>
		</section>

		<section className="mb-6">
			<h3 className="text-xl font-bold mb-2">6. Changes to Terms</h3>

			<p>
				GP Real Estate reserves the right to update or change these Terms and
				Conditions at any time without prior notice. Changes will be effective
				immediately upon posting to the website. It is your responsibility to
				review these terms periodically for changes.
			</p>
		</section>

		<section className="mb-6">
			<h3 className="text-xl font-bold mb-2">7. Governing Law</h3>

			<p>
				These Terms and Conditions are governed by and construed in accordance
				with the laws of [Your Jurisdiction]. Any disputes relating to these
				terms shall be subject to the exclusive jurisdiction of the courts in
				[Your Jurisdiction].
			</p>
		</section>

		<section className="mb-6">
			<h3 className="text-xl font-bold mb-2">8. Contact Us</h3>

			<p>
				If you have any questions, concerns, or requests regarding these Terms
				and Conditions, please contact us at{' '}
				<a className="text-blue-500" href="mailto:contact@gprealestate.com.np">
					contact@gprealestate.com.np
				</a>
				.
			</p>
		</section>

		<p>
			Thank you for using GP Real Estate services. Your continued use of our
			website constitutes your acceptance of these terms and conditions.
		</p>
		<Link
			to="/"
			className=" underline text-blue-600 flex items-center space-x-2"
		>
			<MdKeyboardReturn />
			<span>Back to Home</span>
		</Link>
	</div>
);

export default TermsAndConditions;
