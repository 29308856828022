import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { getContent, getFaqs } from "../../apis";
export default function FAQ() {
  const [active, setActive] = useState([]);
  const { data } = useQuery({
    queryKey: ["faqs"],
    queryFn: () => getFaqs(),
  });
  const { data: faqData } = useQuery({
    querykey: ["content"],
    queryFn: () => getContent(),
  });
  return (
    <>
      <section
        id="faq"
        className="md:h-screen md:flex md:flex-col md:justify-center"
        style={{ scrollSnapAlign: "center" }}
      >
        <div className={["min-h-[70dvh] grid items-center py-8"].join(" ")}>
          <div className="text-center md:space-y-2 mb-4 md:mb-auto">
            <h2 className="text-2xl md:text-4xl font-semibold mt-8 md:mt-auto text-primary">
              {faqData?.data?.faq?.mainText ||
                "Frequently Asked Questions (FAQ)"}
            </h2>
            <p className="text-sm md:text-base font-medium px-4 md:px-auto">
              {faqData?.data?.faq?.subText ||
                "Find quick answers to common questions about buying, selling, and renting properties. For more personalized assistance, feel free to contact our team."}
            </p>
          </div>

          <div className="mx-auto mb-4 md:mb-auto md:w-2/4 space-y-4 w-11/12">
            {data?.data?.results?.map((d) => (
              <div className="space-y-4">
                <div className="w-full px-4 py-4 shadow-sm rounded-xl border-[1px] border-slate-100">
                  <h2
                    className="text-base font-semibold flex justify-between items-center cursor-pointer"
                    onClick={() => {
                      setActive(
                        active?.includes(d?.id)
                          ? (prev) => prev.filter((v) => v !== d?.id)
                          : (prev) => [...prev, d?.id]
                      );
                    }}
                  >
                    {d?.question}
                    <span>
                      {active.includes(d?.id) ? (
                        <IoIosArrowDown />
                      ) : (
                        <IoIosArrowUp />
                      )}
                    </span>
                  </h2>
                  {active.includes(d?.id) && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: d?.answer,
                      }}
                    ></div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
