import { Button, Label, Select, TextInput } from "flowbite-react";
import { useState } from "react";
import { BiSolidCategoryAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { MdDateRange, MdFilterList } from "react-icons/md";
import { TbCurrencyRupeeNepalese } from "react-icons/tb";
import { useSearchParams } from "react-router-dom";
export default function Sidebar() {
  const [isMenu, setIsMenu] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState({
    budget: "0-1000000000",
  });
  function handleFilterInput(field) {
    return (e) => {
      if (["budgetL", "budgetH"].includes(field)) {
        setFilter((prev) => {
          let temp = { ...prev };
          if (!temp.budget) temp.budget = "0-1000000000";
          const [L, H] = temp.budget.split("-");
          if (field === "budgetL") {
            temp["budget"] = e.target.value + "-" + H;
          } else {
            temp["budget"] = L + "-" + e.target.value;
          }
          return temp;
        });
      } else {
        setFilter((prev) => {
          let temp = { ...prev };
          temp[field] = e.target.value;
          !temp[field] && delete temp[field];
          return temp;
        });
      }
    };
  }

  function handleSearch() {
    const paramsObject = Object.fromEntries(Array.from(searchParams.entries()));
    let queries = Object.assign(paramsObject, filter);
    for (let query in queries) {
      !queries[query] && delete queries[query];
      // if()
    }
    setSearchParams(queries);
  }

  function resetFilter() {
    setSearchParams({});
    setFilter({ name: "" });
  }

  return (
    <>
      <div className="inline-block self-end rounded-lg lg:hidden justify-end p-2 bg-lime-600 text-white shadow mb-2">
        <MdFilterList
          fontSize="30px"
          onClick={() => setIsMenu(!isMenu)}
          className="flex justify-end"
        />
      </div>

      <aside
        className={`min-h-screen min-w-[20vw]  lg:block md:!w-[25vw] w-full shadow-big rounded-2xl ${
          isMenu ? "hidden" : ""
        }`}
      >
        <section className="md:shadow-lg shadow-none p-4">
          <Button
            onClick={handleSearch}
            className="mb-4 flex w-full"
            size="lg"
            color="primary"
          >
            Filter
          </Button>
          <Button
            className="w-full bg-transparent text-red-600 mb-4 hover:text-white hover:bg-red-600  border-red-400 border-2"
            onClick={resetFilter}
            color="primary"
            size="lg"
          >
            Reset
          </Button>
          <fieldset className="space-y-4">
            <TextInput
              sizing="lg"
              placeholder="Search Name"
              onChange={handleFilterInput("name")}
              value={filter?.name}
              icon={FaSearch}
            />
            <Select
              sizing="lg"
              onChange={handleFilterInput("district")}
              icon={FaLocationDot}
            >
              <option value="" selected disabled>
                Location
              </option>
              <option value="kathmandu">Kathmandu</option>
              <option value="lalitpur">Lalitpur</option>
              <option value="bharatpur">Bharatpur</option>
              <option value="chitwan">Chitwan</option>
            </Select>
            <Select
              sizing="lg"
              onChange={handleFilterInput("category")}
              icon={BiSolidCategoryAlt}
            >
              <option value="" selected disabled>
                Category
              </option>
              <option value="house">House</option>
              <option value="apartment">Apartment</option>
              <option value="land">Land</option>
            </Select>
            <Select
              sizing="lg"
              onChange={handleFilterInput("subCategory")}
              icon={BiSolidCategoryAlt}
            >
              <option value="" selected disabled>
                Subcategory
              </option>
              <option value="Bungalow">Bungalow</option>
              <option value="Commercial house">Commercial House</option>
              <option value="Duplex">Duplex</option>
              <option value="2 BHK">2 BHK</option>
              <option value="Mansion">Mansion</option>
              <option value="Triplex">Triplex</option>
              <option value="Villa">Villa</option>
              <option value="3 BHK">3 BHK</option>
              <option value="4 BHK">4 BHK</option>
              <option value="Others">Others</option>
              <option value="Commercial Land">Commercial Land</option>
              <option value="Residential Land">Residental Land</option>
              <option value="Apartment">Apartment</option>
              <option value="Flat">Flat</option>
              <option value="Office Space">Office Space</option>
              <option value="Shop Space">shopSpace</option>
            </Select>
            <Select sizing="lg" onChange={handleFilterInput("type")}>
              <option value="" selected disabled>
                Type
              </option>
              <option value="sale">Sale</option>
              <option value="rent">Rent</option>
            </Select>
            <Select sizing="lg" onChange={handleFilterInput("ownerShip")}>
              <option value="" selected disabled>
                Ownership
              </option>
              <option value="Individual">Individual</option>
              <option value="Institutional">Institutional</option>
            </Select>
            <Select onChange={handleFilterInput("furnishedStatus")} sizing="lg">
              <option value="" selected disabled>
                Furnished Status
              </option>
              <option value="furnished">Furnished</option>
              <option value="semiFurnished">Semi Furnished</option>
              <option value="noFurnished">Not Furnished</option>
            </Select>
            <Select onChange={handleFilterInput("area.type")} sizing="lg">
              <option value="" selected disabled>
                Area Type
              </option>
              <option value="Hilly">Hilly</option>
              <option value="Terai">Terai</option>
              <option value="Metric">Metric</option>
            </Select>
            <div className="grid grid-cols-2 gap-2">
              <Label className="col-span-2">Built Year</Label>
              <TextInput
                icon={MdDateRange}
                placeholder="From"
                min={2000}
                onChange={handleFilterInput("builtYear.year")}
                max={new Date(Date.now()).getFullYear()}
                type="number"
              />
            </div>
            <div className="grid grid-cols-2 gap-2">
              <Label className="col-span-2">Price Range</Label>
              <TextInput
                icon={TbCurrencyRupeeNepalese}
                onChange={handleFilterInput("budgetL")}
                type="number"
                step={100000}
              />
              <TextInput
                icon={TbCurrencyRupeeNepalese}
                onChange={handleFilterInput("budgetH")}
                type="number"
                step={100000}
              />
            </div>

            {/* <Select onChange={handleFilterInput("bathroom")} sizing="lg">
              <option value="" selected disabled>
                Bathrooms
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5+">5+</option>
            </Select>
            <Select onChange={handleFilterInput("bedroom")} sizing="lg">
              <option value="" selected disabled>
                Bedrooms
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5+">5+</option>
            </Select>
            <Select onChange={handleFilterInput("parking")} sizing="lg">
              <option value="" selected disabled>
                Parking
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5+">5+</option>
            </Select> */}
          </fieldset>

          <Button
            onClick={handleSearch}
            className="mt-4 flex w-full"
            size="lg"
            color="primary"
          >
            Filter
          </Button>
        </section>
      </aside>
    </>
  );
}
