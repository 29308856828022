// Admin
import AdminSidebar from './AdminLayout/Sidebar';
import AdminLayout from './AdminLayout';

import DefaultLayout from './DefaultLayout';
import Header from './DefaultLayout/Header';
import Footer from './DefaultLayout/Footer';
// Listing
import ListingLayout from './ListingLayout';
import ListingSidebar from './ListingLayout/Sidebar';

const layouts = {
	// default
	Default: DefaultLayout,
	defaultHeader: Header,
	defaultFooter: Footer,
	// listing
	listing: ListingLayout,
	listingSidebar: ListingSidebar,
	// admin
	admin: AdminLayout,
	adminSidebar: AdminSidebar,
};

export default layouts;
