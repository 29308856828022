import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, FileInput, Label, TextInput } from "flowbite-react";
import React, { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useAlert } from "../../../../_contexts/AlertContext";
import isEmpty from "../../../../_helpers/isEmpty.js";
import { getContent, postContent, upload } from "../../../../apis";
export default function Contents() {
  const { data, isLoading } = useQuery({
    queryKey: ["content"],
    queryFn: getContent,
  });

  const [defaultData, setDefaultData] = React.useState(data?.data);
  const {
    register: heroRegister,
    handleSubmit: handleHeroSubmit,
    // setValue: setHeroValue,
    formState: { errors: heroErrors },
  } = useForm({
    mode: "all",
    // values: { ...data?.data?.[0] },
  });

  const {
    register: featureRegister,
    handleSubmit: handleFeatureSubmit,
    // setValue: setFeatureValue,
    formState: { errors: featureErrors },
  } = useForm({
    mode: "all",
  });
  const {
    register: popularRegister,
    handleSubmit: handlePopularSubmit,
    formState: { errors: popularErrors },
  } = useForm({
    mode: "all",
  });
  const {
    register: premiumRegister,
    handleSubmit: handlePremiumSubmit,
    formState: { errors: premiumErrors },
  } = useForm({
    mode: "all",
  });
  const {
    register: rentRegister,
    handleSubmit: handleRentSubmit,
    formState: { errors: rentErrors },
  } = useForm({
    mode: "all",
  });
  const {
    register: partnerRegister,
    handleSubmit: handlePartnerSubmit,
    formState: { errors: partnerErrors },
  } = useForm({
    mode: "all",
  });

  const {
    register: faqRegister,
    handleSubmit: handleFaqSubmit,
    formState: { errors: faqErrors },
  } = useForm({
    mode: "all",
  });
  const {
    register: aboutRegister,
    handleSubmit: handleAboutSubmit,
    setValue: setAboutValue,
    formState: { errors: aboutErrors },
  } = useForm({
    mode: "all",
  });

  const {
    register: citiesRegister,
    handleSubmit: handleCitiesSubmit,
    // setValue: setCitiesValue,
    formState: { errors: citiesErrors },
  } = useForm({
    mode: "all",
  });

  const {
    register: whyUsRegister,
    handleSubmit: handleWhyUsSubmit,
    // setValue: setWhyUsValue,
    formState: { errors: whyUsErrors },
  } = useForm({
    mode: "all",
  });

  const {
    register: organizationRegister,
    handleSubmit: handleOrganizationSubmit,
    control,
    formState: { errors: organizationErrors },
  } = useForm({
    mode: "all",
    defaultValues: {
      organization: {
        telephone: defaultData?.organization?.telephone || [],
      },
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "organization.telephone",
  });
  useEffect(() => {
    setDefaultData(data?.data);
  }, [data]);

  const { setAlert } = useAlert();

  const { mutate } = useMutation({
    mutationKey: ["content"],
    mutationFn: postContent,
    onSuccess: () => {
      setAlert({
        isOpen: true,
        message: "Content Updated Successfully",
        type: "success", // success info failure warning
      });
    },
    onError: (error) => {
      setAlert({
        isOpen: true,
        message: error?.message,
        type: "failure", // success info failure warning
      });
    },
  });

  async function onSubmit(data) {
    window.log(data, "organizationData");
    let body = { ...data };
    for (let key in body) {
      for (let k in body[key]) {
        if (!body[key][k]) delete body[key][k];
      }
      if (isEmpty(body[key])) delete body[key];
    }

    mutate(body);
  }
  return (
    <div>
      <h1 className="text-3xl text-lime-600 font-semibold">Contents</h1>
      {/* Hero */}
      <fieldset className="my-4 p-4 border-2 border-slate-300 rounded-2xl">
        <legend className="text-xl text-lime-600 px-2">Hero Section</legend>
        <div className="space-y-4">
          <div>
            <Label>Main Text</Label>
            <TextInput
              placeholder="eg: Find Your Dream Home"
              defaultValue={defaultData?.hero?.mainText}
              color={heroErrors?.hero?.mainText && "failure"}
              {...heroRegister("hero.mainText")}
            />
          </div>
          <div>
            <Label>Sub Text</Label>
            <TextInput
              placeholder="eg: From as low as $10 per day with limited time offer discounts."
              color={heroErrors?.hero?.subText && "failure"}
              defaultValue={defaultData?.hero?.subText}
              {...heroRegister("hero.subText")}
            />
          </div>
          {/* <div>
            <Label>Background Image</Label>
            <FileInput onChange={upload("hero.image", setValue)} />
          </div> */}
          <Button disabled={isLoading} onClick={handleHeroSubmit(onSubmit)}>
            Submit
          </Button>
        </div>
      </fieldset>
      {/* Featured */}
      <fieldset className="my-4 p-4 border-2 border-slate-300 rounded-2xl">
        <legend className="text-xl text-lime-600 px-2">Featured Section</legend>
        <div className="space-y-4">
          <div>
            <Label>Main Text</Label>
            <TextInput
              placeholder="eg: Featured Properties"
              {...featureRegister("featured.mainText")}
              color={featureErrors?.featured?.mainText && "failure"}
              defaultValue={defaultData?.featured?.mainText}
            />
          </div>
          <div>
            <Label>Sub Text</Label>
            <TextInput
              placeholder="eg: Handpicked properties by our team."
              {...featureRegister("featured.subText")}
              color={featureErrors?.featured?.subText && "failure"}
              defaultValue={defaultData?.featured?.subText}
            />
          </div>
          <Button disabled={isLoading} onClick={handleFeatureSubmit(onSubmit)}>
            Submit
          </Button>
        </div>
      </fieldset>
      {/* popular */}
      <fieldset className="my-4 p-4 border-2 border-slate-300 rounded-2xl">
        <legend className="text-xl text-lime-600 px-2">Popular Section</legend>
        <div className="space-y-4">
          <div>
            <Label>Main Text</Label>
            <TextInput
              placeholder="eg: Popular Properties"
              {...popularRegister("popular.mainText")}
              color={popularErrors?.popular?.mainText && "failure"}
              defaultValue={defaultData?.popular?.mainText}
            />
          </div>
          <div>
            <Label>Sub Text</Label>
            <TextInput
              placeholder="eg: Handpicked properties by our team."
              {...popularRegister("popular.subText")}
              color={popularErrors?.popular?.subText && "failure"}
              defaultValue={defaultData?.popular?.subText}
            />
          </div>
          <Button disabled={isLoading} onClick={handlePopularSubmit(onSubmit)}>
            Submit
          </Button>
        </div>
      </fieldset>
      {/* premium */}
      <fieldset className="my-4 p-4 border-2 border-slate-300 rounded-2xl">
        <legend className="text-xl text-lime-600 px-2">Premium Section</legend>
        <div className="space-y-4">
          <div>
            <Label>Main Text</Label>
            <TextInput
              placeholder="eg: Premium Properties"
              {...premiumRegister("premium.mainText")}
              color={premiumErrors?.premium?.mainText && "failure"}
              defaultValue={defaultData?.premium?.mainText}
            />
          </div>
          <div>
            <Label>Sub Text</Label>
            <TextInput
              placeholder="eg: Handpicked properties by our team."
              {...premiumRegister("premium.subText")}
              color={premiumErrors?.premium?.subText && "failure"}
              defaultValue={defaultData?.premium?.subText}
            />
          </div>
          <Button disabled={isLoading} onClick={handlePremiumSubmit(onSubmit)}>
            Submit
          </Button>
        </div>
      </fieldset>
      {/* rent */}
      <fieldset className="my-4 p-4 border-2 border-slate-300 rounded-2xl">
        <legend className="text-xl text-lime-600 px-2">Rent Section</legend>
        <div className="space-y-4">
          <div>
            <Label>Main Text</Label>
            <TextInput
              placeholder="eg: Premium Properties"
              {...rentRegister("rent.mainText")}
              color={rentErrors?.rent?.mainText && "failure"}
              defaultValue={defaultData?.rent?.mainText}
            />
          </div>
          <div>
            <Label>Sub Text</Label>
            <TextInput
              placeholder="eg: Handpicked properties by our team."
              {...rentRegister("rent.subText")}
              color={rentErrors?.rent?.subText && "failure"}
              defaultValue={defaultData?.rent?.subText}
            />
          </div>
          <Button disabled={isLoading} onClick={handleRentSubmit(onSubmit)}>
            Submit
          </Button>
        </div>
      </fieldset>

      {/* partner */}
      <fieldset className="my-4 p-4 border-2 border-slate-300 rounded-2xl">
        <legend className="text-xl text-lime-600 px-2">Partner Section</legend>
        <div className="space-y-4">
          <div>
            <Label>Main Text</Label>
            <TextInput
              placeholder="eg: Partner Properties"
              {...partnerRegister("partner.mainText")}
              color={partnerErrors?.partner?.mainText && "failure"}
              defaultValue={defaultData?.partner?.mainText}
            />
          </div>
          <div>
            <Label>Sub Text</Label>
            <TextInput
              placeholder="eg: Handpicked properties by our team."
              {...partnerRegister("partner.subText")}
              color={partnerErrors?.partner?.subText && "failure"}
              defaultValue={defaultData?.partner?.subText}
            />
          </div>
          <Button disabled={isLoading} onClick={handlePartnerSubmit(onSubmit)}>
            Submit
          </Button>
        </div>
      </fieldset>
      {/* faq */}
      <fieldset className="my-4 p-4 border-2 border-slate-300 rounded-2xl">
        <legend className="text-xl text-lime-600 px-2">FAQs Section</legend>
        <div className="space-y-4">
          <div>
            <Label>Main Text</Label>
            <TextInput
              placeholder="eg: Partner Properties"
              {...faqRegister("faq.mainText")}
              color={faqErrors?.faq?.mainText && "failure"}
              defaultValue={defaultData?.faq?.mainText}
            />
          </div>
          <div>
            <Label>Sub Text</Label>
            <TextInput
              placeholder="eg: Handpicked properties by our team."
              {...faqRegister("faq.subText")}
              color={faqErrors?.faq?.subText && "failure"}
              defaultValue={defaultData?.faq?.subText}
            />
          </div>
          <Button disabled={isLoading} onClick={handleFaqSubmit(onSubmit)}>
            Submit
          </Button>
        </div>
      </fieldset>
      {/* Cities */}
      <fieldset className="my-4 p-4 border-2 border-slate-300 rounded-2xl">
        <legend className="text-xl text-lime-600 px-2">Cities Section</legend>
        <div className="space-y-4">
          <div>
            <Label>Main Text</Label>
            <TextInput
              placeholder="eg: Cities We Serve"
              {...citiesRegister("cities.mainText")}
              color={citiesErrors?.cities?.mainText && "failure"}
              defaultValue={defaultData?.cities?.mainText}
            />
          </div>
          <div>
            <Label>Sub Text</Label>
            <TextInput
              placeholder="eg: Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui, esse."
              {...citiesRegister("cities.subText")}
              color={citiesErrors?.cities?.subText && "failure"}
              defaultValue={defaultData?.cities?.subText}
            />
          </div>
          <Button disabled={isLoading} onClick={handleCitiesSubmit(onSubmit)}>
            Submit
          </Button>
        </div>
      </fieldset>
      {/* Why Us */}
      <fieldset className="my-4 p-4 border-2 border-slate-300 rounded-2xl">
        <legend className="text-xl text-lime-600 px-2">Why Us Section</legend>
        <div className="space-y-4">
          <div>
            <Label>Main Text</Label>
            <TextInput
              placeholder="eg: Why Choose Us"
              {...whyUsRegister("whyUs.mainText")}
              color={whyUsErrors?.whyUs?.mainText && "failure"}
              defaultValue={defaultData?.whyUs?.mainText}
            />
          </div>
          <div>
            <Label>Sub Text</Label>
            <TextInput
              placeholder="eg: We provide full service at every step."
              {...whyUsRegister("whyUs.subText")}
              color={whyUsErrors?.whyUs?.subText && "failure"}
              defaultValue={defaultData?.whyUs?.subText}
            />
          </div>
          <Button disabled={isLoading} onClick={handleWhyUsSubmit(onSubmit)}>
            Submit
          </Button>
        </div>
      </fieldset>
      {/* About us */}
      <fieldset className="my-4 p-4 border-2 border-slate-300 rounded-2xl">
        <legend className="text-xl text-lime-600 px-2">About Us</legend>
        <div className="space-y-4">
          <div>
            <Label>Main Text</Label>
            <TextInput
              placeholder="eg: About Us"
              {...aboutRegister("aboutUs.mainText")}
              color={aboutErrors?.aboutUs?.mainText && "failure"}
              defaultValue={defaultData?.aboutUS?.mainText}
            />
          </div>
          <div>
            <Label>Sub Text</Label>
            <TextInput
              placeholder="eg:About UsLorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci,id!"
              {...aboutRegister("aboutUs.subText")}
              color={aboutErrors?.aboutUs?.subText && "failure"}
              defaultValue={defaultData?.aboutUs?.subText}
            />
          </div>
          <div>
            <Label>Content Main Text</Label>
            <TextInput
              placeholder="eg:Our Mission is to find Best Property"
              {...aboutRegister("aboutUs.contentMainText")}
              color={aboutErrors?.aboutUs?.contentMainText && "failure"}
              defaultValue={defaultData?.aboutUs?.contentMainText}
            />
          </div>
          <div>
            <Label>Content</Label>
            <TextInput
              placeholder="eg:Lorem ipsum dolor sit, amet consectetur adipisicing elit....."
              {...aboutRegister("aboutUs.content")}
              color={aboutErrors?.aboutUs?.content && "failure"}
              defaultValue={defaultData?.aboutUs?.content}
            />
          </div>

          <div>
            <Label>Landing Image</Label>
            <FileInput onChange={upload("hero.image", setAboutValue)} />
          </div>
          <div>
            <Label>Content Image</Label>
            <FileInput onChange={upload("hero.image", setAboutValue)} />
          </div>
          <Button disabled={isLoading} onClick={handleAboutSubmit(onSubmit)}>
            Submit
          </Button>
        </div>
      </fieldset>
      {/* Organization Details*/}
      <fieldset className="my-4 p-4 border-2 border-slate-300 rounded-2xl">
        <legend className="text-xl text-lime-600 px-2">
          Organization Section
        </legend>
        <div className="space-y-4">
          <div>
            <Label>Email</Label>
            <TextInput
              type="email"
              placeholder="example@email.com"
              {...organizationRegister("organization.email")}
              color={organizationErrors?.organization?.email && "failure"}
              defaultValue={defaultData?.organization?.email}
            />
          </div>
          <div>
            <Label>location</Label>
            <TextInput
              placeholder="location"
              {...organizationRegister("organization.location")}
              color={organizationErrors?.organization?.location && "failure"}
              defaultValue={defaultData?.organization?.location}
            />
          </div>
          <div>
            <Label>facebook</Label>
            <TextInput
              placeholder="facebook"
              {...organizationRegister("organization.facebook")}
              color={organizationErrors?.organization?.facebook && "failure"}
              defaultValue={defaultData?.organization?.facebook}
            />
          </div>
          <div>
            <Label>instagram</Label>
            <TextInput
              placeholder="instagram"
              {...organizationRegister("organization.instagram")}
              color={organizationErrors?.organization?.instagram && "failure"}
              defaultValue={defaultData?.organization?.instagram}
            />
          </div>
          <div>
            <Label>tiktok</Label>
            <TextInput
              placeholder="tiktok"
              {...organizationRegister("organization.tiktok")}
              color={organizationErrors?.organization?.tiktok && "failure"}
              defaultValue={defaultData?.organization?.tiktok}
            />
          </div>
          <div>
            <Label>youtube</Label>
            <TextInput
              placeholder="youtube"
              {...organizationRegister("organization.youtube")}
              color={organizationErrors?.organization?.youtube && "failure"}
              defaultValue={defaultData?.organization?.youtube}
            />
          </div>
          <div>
            <Label>Telephone</Label>
            <div className="grid grid-cols-2 gap-4 pb-4">
              {fields?.map((field, i) => (
                <div
                  key={field?.id}
                  className="flex gap-x-2 items-center justify-center"
                >
                  <TextInput
                    type="tel"
                    placeholder="9812345678"
                    className="flex-grow"
                    {...organizationRegister(`organization.telephone.${i}`)}
                  />

                  <Button
                    className="bg-red-600 hover:!bg-red-700 transition duration-150 ease-in-out"
                    onClick={() => remove(i)}
                  >
                    Delete
                  </Button>
                </div>
              ))}
              {window.log(fields, "fields")}
            </div>
            <Button onClick={() => append("")}>append</Button>
          </div>
          <Button
            disabled={isLoading}
            onClick={handleOrganizationSubmit(onSubmit)}
          >
            Submit
          </Button>
        </div>
      </fieldset>
    </div>
  );
}
