import { Alert } from "flowbite-react";
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  IoMdCheckmarkCircleOutline,
  IoMdCloseCircleOutline,
  IoMdInformationCircleOutline,
  IoMdWarning,
} from "react-icons/io";

const init = {
  isOpen: false,
  message: "This is Successful Message",
  type: "success", // success info failure warning
};

export const AlertContext = createContext(init);

export function useAlert() {
  const { alert, setAlert, alerts, setAlerts } = useContext(AlertContext);
  return { alert, setAlert, alerts, setAlerts };
}

export default function AlertContextProvider({ children }) {
  const [alert, setAlert] = useState(init);
  const [alerts, setAlerts] = useState([]);
  useEffect(() => {
    let timeout;
    if (alert?.isOpen) {
      timeout = setTimeout(() => {
        setAlert(init);
      }, 5000);
    }
    return () => timeout && clearTimeout(timeout);
  }, [alert.isOpen]);
  useEffect(() => {
    let timeout;
    if (alerts?.length) {
      timeout = setTimeout(() => {
        setAlerts([]);
      }, 5000);
    }
    return () => timeout && clearTimeout(timeout);
  }, [alerts?.length]);
  return (
    <AlertContext.Provider value={{ alert, setAlert, alerts, setAlerts }}>
      {children}
      <div
        className={[
          "fixed top-8 left-[calc(50%_-_12.5vw)] z-50 shadow w-[25vw]",
          alert?.isOpen && "animate__animated animate__shakeX animated__slow",
        ].join(" ")}
      >
        {alert?.isOpen && (
          <Alert
            className=""
            withBorderAccent
            color={alert.type}
            icon={
              alert.type === "info"
                ? IoMdInformationCircleOutline
                : alert.type === "failure"
                ? IoMdCloseCircleOutline
                : alert.type === "success"
                ? IoMdCheckmarkCircleOutline
                : IoMdWarning
            }
            onDismiss={() => setAlert(init)}
          >
            <span>{alert?.message}</span>
          </Alert>
        )}
        {alerts.length > 0 &&
          alerts?.map((alert, index) => (
            <Alert
              key={index}
              className="mb-2"
              withBorderAccent
              color={alert.type}
              icon={
                alert.type === "info"
                  ? IoMdInformationCircleOutline
                  : alert.type === "failure"
                  ? IoMdCloseCircleOutline
                  : alert.type === "success"
                  ? IoMdCheckmarkCircleOutline
                  : IoMdWarning
              }
              onDismiss={() => setAlerts(alerts.filter((_, i) => i !== index))}
            >
              <span>{alert?.message}</span>
            </Alert>
          ))}
      </div>
    </AlertContext.Provider>
  );
}
