import { Outlet } from "react-router-dom";
import layouts from "..";
import ScrollToTop from "../../components/ScrollToTop";
import Sidebar from "./Sidebar";
export default function ListingLayout() {
  return (
    <div className="">
      <ScrollToTop />

      <layouts.defaultHeader hideHead={false} />
      <main className="flex gap-x-8 px-6 py-20 flex-col md:flex-row md:px-16">
        <Sidebar />
        <section className="grow">
          <Outlet />
        </section>
      </main>
      <layouts.defaultFooter />
    </div>
  );
}
