export default function MyCalender() {
  return (
    <div className="h-full flex flex-col items-stretch">
      {/* <p>Calendar</p> */}
      <div className="grow">
        <iframe
          title="calendar"
          src="https://calendar.google.com/calendar/embed?src=realestategp46%40gmail.com&ctz=Asia%2FKathmandu"
          style={{ border: 0 }}
          className="w-full h-full"
          width="800"
          height="600"
          frameborder="0"
          scrolling="no"
        ></iframe>
      </div>
    </div>
  );
}
