import { useMutation, useQuery } from "@tanstack/react-query";
import { Badge, Button } from "flowbite-react";
import { useState } from "react";
import { HiPlus } from "react-icons/hi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAlert } from "../../_contexts/AlertContext";
import { deleteProperty, getProperties, patchProperty } from "../../apis";
import Table from "../../components/Table";
function Property() {
  const navigate = useNavigate();
  const { pageNum } = useParams();
  const [page, setPage] = useState(parseInt(pageNum) || 1);
  const { setAlert } = useAlert();
  const { isLoading, data, refetch } = useQuery({
    queryKey: ["properties", page],
    queryFn: getProperties("", page),
  });
  const { mutate } = useMutation({
    mutationKey: ["property"],
    mutationFn: patchProperty,
    onSuccess: () => {
      refetch();
    },
  });
  const { mutate: deleteProp } = useMutation({
    mutationKey: ["property"],
    mutationFn: deleteProperty,
    onSuccess: () => {
      setAlert({
        type: "success",
        message: "Property Deleted Successfully",
        isOpen: true,
      });
      refetch();
    },
  });
  window.log(data?.data, "hello");

  const headers = [
    {
      key: "name",
      title: "Name",
      className: "w-64",
      render: (property) => (
        <>
          <Link
            to={`/admin/property/list/details/${property?._id}`}
            className={`${property?.sold && "line-through line"}`}
          >
            {property?.name}
          </Link>
          {property?.sold && (
            <Badge className="w-fit" color="failure">
              Sold
            </Badge>
          )}
        </>
      ),
    },
    {
      key: "propertyId",
      title: "Property ID",
      className: "w-32",
    },
    {
      key: "category",
      title: "Category",
    },
    {
      key: "image",
      title: "Image",
      render: (property) => (
        <div>
          <img
            className="w-20 rounded-xl object-contain object-center"
            src={property?.images?.[0]?.src}
            alt={property?.images?.title}
          />
        </div>
      ),
    },
    {
      key: "type",
      title: "Type",
    },
    {
      key: "desc",
      title: "Description",
      render: (data) => (
        <p className="text-slate-600 text-ellipsis line-clamp-2">
          <p dangerouslySetInnerHTML={{ __html: data?.description }} />
        </p>
      ),
    },
    {
      key: "_action",
      title: "Actions",
      className: "w-52",
      actions: {
        featured: (data) => {
          mutate({ id: data?._id, data: { featured: !data?.featured } });
        },
        view: null,
        edit: (data) => {
          navigate(`/admin/property/update/${data?._id}`);
        },

        delete: (data) => {
          deleteProp({ id: data?._id });
        },
        sold: (data) => {
          mutate({ id: data?._id, data: { sold: !data?.sold } });
        },
      },
    },
  ];
  // const checkState = useState([]);
  return (
    <div className="md:w-full max-w-screen space-y-4 md:py-0 py-12">
      <div className="flex justify-between items-center md:flex-row">
        <h2 className="md:text-3xl text-2xl font-semibold md:mb-6 mb-0">
          Properties
        </h2>
        <Button
          outline
          gradientDuoTone="greenToBlue"
          pill
          onClick={() => navigate("/admin/property/create")}
        >
          <HiPlus className="mr-2 h-5 w-5" />
          Add Property
        </Button>
      </div>
      <div className="w-full overflow-scroll md:overflow-auto scrollbar-hide">
        {window.log(page, "page")}
        {window.log(data?.data?.totalResults, "total")}

        <Table
          headers={headers}
          data={data?.data?.results}
          isLoading={isLoading}
          page={page}
          setPage={setPage}
          total={data?.data?.totalResults}
          onPageChange={(p) => navigate(`/admin/property/list/${p}`)}
        />
      </div>
    </div>
  );
}

export default Property;
