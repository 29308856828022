import axios from "axios";
const url = process.env.REACT_APP_BACKEND_URL;
const upload_url = process.env.REACT_APP_FILE_URL;

// axios instance
const instance = axios.create({
  baseURL: url,
  timeout: 8000,
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem("gp_user");
      return null;
    }
    return Promise.reject(error);
  }
);

instance.interceptors.request.use((config) => {
  let token = JSON.parse(localStorage.getItem("gp_user"))?.tokens?.access
    ?.token;
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

export const getProperties =
  (queries, page = 1) =>
  () =>
    instance
      .get(`/property?${queries}${queries ? `&page=${page}` : `page=${page}`}`)
      .then(async (res) => {
        return await res?.data;
      });

export const getProperty = (id) =>
  instance.get(`property/${id}`).then((res) => res?.data);

export const getPropertyFromPropertyCode = (code) => () =>
  instance
    .get(`property/preview?propertyCode=${code}`)
    .then((res) => res?.data);

export const getMe = () => instance.get("user/me").then((res) => res?.data);

export const getUsers = () => instance.get("user").then((res) => res?.data);

export const getFeaturedProperties = () =>
  instance.get("/property/featured").then((res) => res?.data);
export const getPopularProperties = () =>
  instance.get("/property/popular").then((res) => res?.data);

export const getSimilarProperties =
  ({ id }) =>
  () =>
    instance.get(`/property/similar/${id}`).then((res) => res?.data);

export const postProperty = (data) => instance.post("property", data);
export const postUser = (data) => instance.post("/user", data);
export const deleteUser = ({ id }) => instance.delete("user/" + id);
export const getStats = () =>
  instance.get("/misc/whyus-stats").then((res) => res?.data);
export const patchProperty = ({ id, data }) =>
  instance.patch("property/" + id, data).then((res) => res?.data);

export const deleteProperty = ({ id }) => instance.delete("property/" + id);

export const postLogin = (data) => instance.post("auth/login", data);

//offers

export const getOffers = () => instance.get("offer").then((res) => res?.data);
export const postOffer = (data) => instance.post("offer", data);
export const getSingleOffer = (id) =>
  instance.get(`offer/${id}`).then((res) => res?.data);
export const patchOffer = ({ id, data }) =>
  instance.patch("offer/" + id, data).then((res) => res?.data);
export const deleteOffer = ({ id }) => instance.delete("offer/" + id);

//partners

export const getPartners = () =>
  instance.get("partner").then((res) => res?.data);
export const postPartner = (data) => instance.post("partner", data);
export const getPartner = (id) =>
  instance.get(`partner/${id}`).then((res) => res?.data);
export const patchPartner = ({ id, data }) =>
  instance.patch(`partner/` + id, data).then((res) => res?.data);
export const deletePartner = ({ id }) => instance.delete("partner/" + id);
export const postStatus = ({ id, data }) =>
  instance.post(`offer/${id}/status`, data);

// lead
export const getLeads = () => instance.get("lead").then((res) => res?.data);
export const postLead = ({ customerId, data }) =>
  instance.post(`lead/${customerId}/lead`, data);
export const getLeadStatus = ({ id }) =>
  instance.get(`lead/${id}/lead`).then((res) => res?.data);
// faq
export const getFaqs = () => instance.get("faq").then((res) => res?.data);
export const postFaq = (data) => instance.post("faq", data);
export const patchFaq = ({ id, data }) =>
  instance.patch(`faq/${id}`, data).then((res) => res?.data);
export const deleteFaq = ({ id }) => instance.delete("faq/" + id);
// team
export const getTeams = () => instance.get("team").then((res) => res?.data);
export const getTeam = (id) =>
  instance.get(`team/${id}`).then((res) => res?.data);
export const postTeam = (data) => instance.post("team", data);
export const deleteTeam = ({ id }) => instance.delete("team/" + id);
export const patchTeam = ({ id, data }) =>
  instance.patch(`team/${id}`, data).then((res) => res?.data);

export const getContent = () =>
  instance.get("content").then((res) => res?.data);

export const postContent = (data) => instance.post("content", data);

export const patchContent = ({ id, data }) => {
  return instance.patch("content/" + id, data).then((res) => res?.data);
};
export const getAbout = () => instance.get("about").then((res) => res?.data);

export const getCustomers = () =>
  instance.get("customer").then((res) => res?.data);

export const postCustomer = (data) => instance.post("customer", data);
export const deleteCustomer = (id) => instance.delete("customer/" + id);
export const getCustomerChat =
  (page = 1) =>
  () =>
    instance.get(`/chat?page=${page}`).then(async (res) => {
      return await res?.data;
    });
// export const getCustomerChat = () =>
//   instance.get("chat").then((res) => res?.data);
export const getSingleChat = (id) =>
  instance.get(`chat/${id}`).then((res) => res?.data);
export const postSingleChat = ({ id, data }) =>
  instance.post(`chat/${id}`, data);

export function getMessage(customerId) {
  return async () =>
    await instance.get(`chat/${customerId}`).then((res) => res?.data?.data);
}

//

//

// file upload
export function upload(id, setValue, setAlert, setState) {
  return async (e) => {
    try {
      const formData = new FormData();
      formData.append("name", e.target.files[0]?.name);
      formData.append("file", e.target.files[0]);
      const response = await axios.post(upload_url, formData);
      setValue && id && setValue(id, response?.data?.data?.url);
      setState && setState(response);
    } catch (error) {
      setAlert &&
        setAlert({
          isOpen: true,
          message: error?.message,
          type: "failure",
        });
    }
  };
}

export function multiUpload(
  id,
  setValue,
  setAlert,
  { onUpload, beforeUpload, prev = [] }
) {
  return async (e) => {
    try {
      beforeUpload && (await beforeUpload(e));
      const formData = new FormData();
      for (let i = 0; i < e.target.files.length; i++) {
        formData.append(`file`, e.target.files[i]);
      }

      const response = await axios.post(upload_url + "-multiple", formData);
      setValue &&
        id &&
        setValue(
          id,
          response?.data?.data
            ?.map((img) => ({
              title: img?.file,
              src: img?.url,
              // view: img?.view,
            }))
            .concat(prev)
        );
      onUpload && onUpload(response);
    } catch (error) {
      setAlert &&
        setAlert({
          isOpen: true,
          message: error?.message,
          type: "failure",
        });
    }
  };
}
