import { useMutation, useQuery } from "@tanstack/react-query";
import { Badge, Button, Label, Modal, Select, Textarea } from "flowbite-react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { useAlert } from "../../../_contexts/AlertContext";
import FullDate from "../../../_helpers/date";
import { getOffers, postStatus } from "../../../apis";
import Table from "../../../components/Table";
const Offer = () => {
  const { isLoading, data, refetch } = useQuery({
    queryKey: ["offers"],
    queryFn: () => getOffers(),
  });
  const { register, handleSubmit } = useForm({
    mode: "onBlur",
    defaultValues: "",
  });
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState("");
  function onCloseModal() {
    setOpenModal(false);
  }
  const { mutate: postProp } = useMutation({
    mutationKey: ["postStatus"],
    mutationFn: postStatus,
    onSuccess: () => {
      setAlert({
        type: "success",
        message: "Offer accepted successfully",
        isOpen: true,
      });
      refetch();
    },
  });
  const onSubmit = (data) => {
    postProp({ id: id, data: data });
    setOpenModal(false);
  };

  const dataOffer = data?.data?.results?.map((d) => ({
    _id: d?._id,
    property: d?.property?._id,
    date: FullDate(d?.createdAt),
    offer: d?.offer,
    name: d?.property?.name,
    propertyId: d?.property?.propertyId,
    user: d?.customer?.name,
    status: d?.status,
    email: d?.customer?.email,
  }));
  const { pageNum } = useParams();
  const { setAlert } = useAlert();
  const role = JSON.parse(localStorage.getItem("gp_user")).user.role;
  const [page, setPage] = useState(pageNum || 1);

  // const { mutate: deleteProp } = useMutation({
  //   mutationKey: ["deleteOffer"],
  //   mutationFn: deleteOffer,
  //   onSuccess: () => {
  //     setAlert({
  //       type: "success",
  //       message: "Offer rejected Successfully",
  //       isOpen: true,
  //     });
  //     refetch();
  //   },
  // });

  const headers = [
    {
      key: "date",
      title: "Date",
    },
    {
      key: "name",
      title: "Name",
      render: (data) => (
        <Link to={`/admin/property/list/details/${data?.property}`}>
          {data?.name}
        </Link>
      ),
    },
    {
      key: "propertyId",
      title: "Property ID",
    },
    {
      key: "user",
      title: "User",
    },
    {
      key: "offer",
      title: "Offer",
    },
    {
      key: "status",
      title: "Status",
      render: (data) => (
        <Badge
          color={`${
            data?.status === "pending"
              ? "gray"
              : data?.status === "rejected"
              ? "failure"
              : "success"
          }`}
          className="flex justify-center p-2"
        >
          {data?.status}
        </Badge>
      ),
    },
    {
      key: "email",
      title: "Email",
    },
    {
      key: "_action",
      title: "Actions",
      actions: {
        add: (data) => {
          setOpenModal(true);
          setId(data?._id);
        },
        // delete: (data) => deleteProp({ id: data?._id }),
      },
      role: role,
      // actions: { delete: null },
    },
  ];
  const tab = [
    {
      label: "All",
      content: (
        <div className="overflow-scroll md:overflow-auto scrollbar-hide">
          <Table
            headers={headers}
            data={dataOffer}
            // checkState={checkState}
            isLoading={isLoading}
            page={page}
            setPage={setPage}
            total={data?.data?.totalPages || 1}
            navigateLink={{ link: `/admin/settings/user/details`, id: true }}
            // withCheckBoxes
            //
          />
        </div>
      ),
    },
    {
      label: "Pending",
      content: (
        <div className="overflow-scroll md:overflow-auto scrollbar-hide">
          <Table
            headers={headers}
            data={dataOffer?.filter((d) => d?.status === "pending")}
            // checkState={checkState}
            isLoading={isLoading}
            page={page}
            setPage={setPage}
            total={data?.data?.totalPages || 1}
            navigateLink={{ link: `/admin/settings/user/details`, id: true }}
            // withCheckBoxes
            //
          />
        </div>
      ),
    },
    {
      label: "Rejected",
      content: (
        <div className="overflow-scroll md:overflow-auto scrollbar-hide">
          <Table
            headers={headers}
            data={dataOffer?.filter((d) => d?.status === "rejected")}
            // checkState={checkState}
            isLoading={isLoading}
            page={page}
            setPage={setPage}
            total={data?.data?.totalPages || 1}
            navigateLink={{ link: `/admin/settings/user/details`, id: true }}
            // withCheckBoxes
            //
          />
        </div>
      ),
    },
    {
      label: "Accepted",
      content: (
        <div className="overflow-scroll md:overflow-auto scrollbar-hide">
          <Table
            headers={headers}
            data={dataOffer?.filter((d) => d?.status === "accepted")}
            // checkState={checkState}
            isLoading={isLoading}
            page={page}
            setPage={setPage}
            total={data?.data?.totalPages || 1}
            navigateLink={{ link: `/admin/settings/user/details`, id: true }}
            // withCheckBoxes
            //
          />
        </div>
      ),
    },
  ];
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabClick = (index) => {
    setActiveTabIndex(index);
  };
  const Tab = ({ isActive, label, onClick }) => {
    return (
      <button
        className={`px-4 py-2 focus:outline-none ${
          isActive ? "border-b-2 border-blue-500 font-bold" : "text-gray-500"
        }`}
        onClick={onClick}
      >
        {label}
      </button>
    );
  };
  return (
    <div className="w-full mx-auto mt-4">
      <div className="flex border-b border-gray-200 justify-between">
        <h2 className="text-2xl font-semibold rounded-xl py-2 px-4text-primary">
          Offers
        </h2>

        <div>
          {tab.map((tab, index) => (
            <Tab
              key={index}
              isActive={index === activeTabIndex}
              label={tab.label}
              onClick={() => handleTabClick(index)}
            />
          ))}
        </div>

        <Modal show={openModal} size="md" onClose={onCloseModal} popup>
          {/* {} */}
          <Modal.Header>Change Status</Modal.Header>
          <Modal.Body>
            <form className="space-y-6">
              <div>
                <div className="mb-2 block">
                  <Label value="Change Status" />
                </div>
                <Select {...register("status")}>
                  <option value="pending">Pending</option>
                  <option value="accepted">Accepted</option>
                  <option value="rejected">Rejected</option>
                </Select>
              </div>
              <div>
                <div className="mb-2 block">
                  <Label value="Remark" />
                </div>
                <Textarea id="remark" {...register("remarks")} />
              </div>
              <div className="flex gap-x-2">
                <Button color="primary" onClick={handleSubmit(onSubmit)}>
                  Change Status
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
      <div className="p-4">
        {tab[activeTabIndex] && tab[activeTabIndex].content}
      </div>
    </div>
  );
};

export default Offer;
