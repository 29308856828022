import { useMutation } from "@tanstack/react-query";
import { Button, FileInput, Label, Select, TextInput } from "flowbite-react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { postUser } from "../../../../apis";
const UserCreate = () => {
  const navigate = useNavigate();
  const role = JSON.parse(localStorage.getItem("gp_user")).user.role;
  useEffect(() => {
    if (role === "user") return navigate("/admin/user/1");
  }, [role, navigate]);
  const { mutate } = useMutation({
    mutationKey: ["users"],
    mutationFn: postUser,
    onSuccess: () => {
      navigate("/admin/user/1");
    },
  });
  const { register, handleSubmit } = useForm({
    mode: "onBlur",
    defaultValues: {},
    // resolver: yupResolver(propertySchema()),
  });
  function onSubmit(data) {
    mutate(data);
  }

  return (
    <>
      <form className="md:py-8 py-10 flex flex-col gap-y-8">
        <fieldset className="grid md:grid-cols-2 grid-cols-1 gap-4">
          <legend className="text-2xl font-medium text-lime-700 col-span-2">
            User Information
          </legend>
          <div>
            <Label htmlFor="name">Name</Label>
            <TextInput
              type="text"
              placeholder="Enter name of User"
              {...register("name")}
            />
          </div>
          <div>
            <Label htmlFor="email">Email</Label>
            <TextInput
              type="email"
              placeholder="Enter email"
              {...register("email")}
            />
          </div>
          <div>
            <Label htmlFor="phone">Phone</Label>
            <TextInput
              type="phone"
              placeholder="Enter phone number"
              {...register("phone")}
            />
          </div>
          <div>
            <Label htmlFor="image">Image</Label>
            <FileInput accept="image/*" />
          </div>
          <div>
            <Label>Password</Label>
            <TextInput
              type="password"
              placeholder="Enter password"
              {...register("password")}
            />
          </div>
          <div>
            <Label>Role</Label>
            <Select {...register("role")}>
              <option value="user">User</option>
              <option value="admin">Admin</option>
            </Select>
          </div>
        </fieldset>
        <div className="flex justify-end pt-8">
          <Button
            className="w-1/2"
            color="success"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default UserCreate;
