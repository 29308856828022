import { Pagination } from "flowbite-react";

export default function PaginationComponent({
  currentPage = 1,
  setCurrentPage,
  totalPages = 1,
  onPageChange = () => {},
}) {
  global.log("pagination", currentPage, totalPages);
  const onChange = (page) => {
    onPageChange && onPageChange(page);
    return setCurrentPage(page);
  };

  return (
    <div className="flex overflow-x-auto pt-4">
      <Pagination
        // layout="table"
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onChange}
        showIcons
      />
    </div>
  );
}
