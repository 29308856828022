import { Card } from "flowbite-react";
import React from "react";
import { featureIcons } from "../_constants";
import commaSeparatedNum from "../_helpers/commaSeparatedNum";

const PropertyCard = React.forwardRef(function PropertyCard(
  { property, horizontal = false },
  ref
) {
  return (
    <div ref={ref}>
      <Card
        horizontal={horizontal}
        className={[
          "md:border border-none md:space-x-0 space-x-[-1rem] md:space-y-0 space-y-[-1rem]",
          // '!shadow-big',
          "shadow-lg",
          "rounded-3xl overflow-hidden",
          "hover:scale-[1.025] transition-transform duration-150",
        ].join(" ")}
        renderImage={() => (
          <img
            className={[
              "aspect-[4_/_3] rounded-md",
              horizontal ? "w-1/2" : "",
            ].join(" ")}
            src={property?.images[0]?.src}
            alt={property?.images[0]?.title}
          />
        )}
        href={`/property/${property?.slug || property?._id}`}
      >
        <div className="flex flex-col mb-[-0.75rem] -m-3">
          <small className="text-sm text-rose-700 font-bold capitalize">
            {property?.type || "Bungalow"}
          </small>

          <h5 className="text-lg leading-5 font-semibold tracking-tight text-lime-700 dark:text-white">
            <p>{property?.name}</p>
          </h5>
          <div className="flex items-center justify-between">
            <span className="font-bold text-slate-600 dark:text-white">
              {property?.price?.unit ? property?.price?.unit + ". " : "Rs. "}
              {commaSeparatedNum(property?.price?.value)}
            </span>
          </div>
          <p className="text-sm text-slate-600">
            {property?.location?.district}, {property?.location?.province}
          </p>
          <div className="grid grid-cols-3 ">
            {Object.keys(property?.details)
              // ?.slice(0, 3)
              .map((key) => {
                let details = { ...property?.details };
                details.parking = [
                  featureIcons?.bikes,
                  <span>{details?.parking?.bikes || 0}</span>,
                  featureIcons.cars,
                  <span>{details?.parking?.cars || 0}</span>,
                ];
                details.roadAccess = [
                  ...(details.roadAccess?.access
                    ? [
                        details?.roadAccess?.width,
                        " ",
                        details?.roadAccess?.unit,
                      ]
                    : ["No"]),
                ];
                return (
                  <PropertyDetailBox details={details} key={key} k={key} />
                );
              })}
          </div>
        </div>
      </Card>
    </div>
  );
});

function PropertyDetailBox({ details, k }) {
  return (
    details?.[k] && (
      <div className=" bg-white border border-slate-100 rounded">
        <div className={["flex p-2 items-center justify-between"].join(" ")}>
          <span className="text-xl text-slate-500">{featureIcons?.[k]}</span>
          <span className=" text-xs flex space-x-1 items-center font-semibold text-slate-500">
            {details[k]}
          </span>
        </div>
      </div>
    )
  );
}

export default PropertyCard;
