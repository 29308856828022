import { useQuery } from "@tanstack/react-query";
import { getContent, getProperties } from "../../apis";
import CardComp from "./components/LandingCards";
export default function Premium() {
  const { data, isLoading } = useQuery({
    queryKey: ["premiumProperties"],
    queryFn: getProperties("premium=true"),
  });
  const { data: premiumData } = useQuery({
    queryKey: ["content"],
    queryFn: () => getContent(),
  });

  return (
    <section
      id="premium"
      className=" bg-slate-50 md:h-screen md:flex md:flex-col md:justify-center"
      style={{ scrollSnapAlign: "center" }}
    >
      <div className="text-center py-12 md:space-y-2">
        <h2 className="text-2xl md:text-4xl font-semibold text-lime-600">
          {premiumData?.data?.premium?.mainText || "Premium Properties"}
        </h2>
        <p className="text-sm md:text-base font-medium">
          {premiumData?.data?.premium?.subText ||
            "Explore a rare selection of premium properties offering unparalleled value and exclusivity."}
        </p>
      </div>
      {!isLoading && <CardComp cards={data?.data?.results} />}
    </section>
  );
}
