import { Avatar, Sidebar } from "flowbite-react";
import { useState } from "react";
import { FaCalendar, FaFacebookMessenger, FaPeopleCarry } from "react-icons/fa";
import { FaQuestion } from "react-icons/fa6";
import {
  // HiArrowSmRight,
  HiChartPie,
  HiHome,
  // HiTable,
  HiOutlineLogout,
  // HiInbox,
  HiShoppingBag,
  HiUser,
} from "react-icons/hi";

import { MdLocalOffer } from "react-icons/md";
import { RiTeamFill } from "react-icons/ri";

import { HiHomeModern } from "react-icons/hi2";
import { IoMenu, IoPeopleCircle } from "react-icons/io5";

import { MdContentCopy, MdLeaderboard } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

function SidebarComponent() {
  const navigate = useNavigate();
  const [isMenu, setIsMenu] = useState(false);
  const user = JSON.parse(localStorage.getItem("gp_user"));
  function logout() {
    localStorage.removeItem("gp_user");
    navigate("/login");
  }
  return (
    <>
      <Sidebar
        className={`md:min-h-[calc(100dvh_-_1.5rem)] min-w-[20vw] shadow-big rounded-3xl overflow-hidden m-3 sticky top-4 md:block hidden`}
      >
        <div className="flex justify-between">
          <Sidebar.Logo
            href="/admin"
            img="/assets/gp_logo.png"
            className=" text-primary-shades-700"
          >
            GP Real Estate
          </Sidebar.Logo>
        </div>
        <Sidebar.Items className={`h-full flex flex-col`}>
          <Sidebar.ItemGroup>
            <Sidebar.Item href="/admin/dashboard" icon={HiChartPie}>
              Dashboard
            </Sidebar.Item>
            <Sidebar.Item href="/admin/property/list/1" icon={HiHomeModern}>
              Property
            </Sidebar.Item>
            {/* <Sidebar.Item href="/admin/seo" icon={HiHomeModern}>
              Seo
            </Sidebar.Item> */}
            <Sidebar.Item href="/admin/message" icon={FaFacebookMessenger}>
              Message
            </Sidebar.Item>
            <Sidebar.Item href="/admin/lead" icon={MdLeaderboard}>
              Lead
            </Sidebar.Item>

            <Sidebar.Item href="/admin/calender" icon={FaCalendar}>
              Calender
            </Sidebar.Item>
            <Sidebar.Item href="/admin/offer" icon={MdLocalOffer}>
              Offer
            </Sidebar.Item>
            <Sidebar.Item href="/admin/team" icon={RiTeamFill}>
              Team
            </Sidebar.Item>
            <Sidebar.Item href="/admin/faq" icon={FaQuestion}>
              FAQs
            </Sidebar.Item>
            <Sidebar.Collapse icon={HiShoppingBag} label="Settings">
              <Sidebar.Item href="/admin/user/1" icon={HiUser}>
                Users
              </Sidebar.Item>
              <Sidebar.Item
                href="/admin/settings/list/contents"
                icon={MdContentCopy}
              >
                Contents
              </Sidebar.Item>

              <Sidebar.Item
                href="/admin/settings/list/customers"
                icon={IoPeopleCircle}
              >
                Customers
              </Sidebar.Item>
              <Sidebar.Item
                href="/admin/settings/list/partner/1"
                icon={FaPeopleCarry}
              >
                Partners
              </Sidebar.Item>
            </Sidebar.Collapse>
          </Sidebar.ItemGroup>
          <Sidebar.ItemGroup>
            <Sidebar.Item href="/" icon={HiHome}>
              Home
            </Sidebar.Item>
            <Sidebar.Item
              className="cursor-pointer"
              icon={HiOutlineLogout}
              onClick={logout}
            >
              Log Out
            </Sidebar.Item>
          </Sidebar.ItemGroup>
          <Sidebar.ItemGroup>
            <Sidebar.Item>
              <div className="flex items-center space-x-2">
                <Avatar />
                <div>
                  <p className="font-bold text-lime-600">{user?.user?.name}</p>
                  <p className="text-xs text-slate-600">{user?.user?.email}</p>
                  <p className="text-xs text-slate-400">{user?.user?.role}</p>
                </div>
              </div>
            </Sidebar.Item>
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>
      <div className="w-full block md:hidden fixed z-20">
        <div className="flex justify-between bg-white p-2">
          <h1 className="flex">
            <img
              src="/assets/gp_logo.png"
              alt="logo"
              className="h-9"
              width="40px"
            />
            <span className="text-base sm:inline self-center whitespace-nowrap  font-semibold dark:text-white">
              GP Real Estate Services
            </span>
          </h1>
          <div>
            {isMenu ? (
              <RxCross2 fontSize={"30px"} onClick={() => setIsMenu(!isMenu)} />
            ) : (
              <IoMenu fontSize={"30px"} onClick={() => setIsMenu(!isMenu)} />
            )}
          </div>
        </div>

        <Sidebar
          className={`md:min-h-[calc(100dvh_-_1.5rem)] min-h-full md:min-w-[20vw] min-w-full overflow-hidden fixed z-40 ${
            isMenu ? "left-0" : "left-[-400px]"
          }`}
        >
          <Sidebar.Items className={`h-full md:flex md:flex-col`}>
            <Sidebar.ItemGroup>
              <Sidebar.Item href="/admin/dashboard" icon={HiChartPie}>
                Dashboard
              </Sidebar.Item>
              <Sidebar.Item href="/admin/property/list/1" icon={HiHomeModern}>
                Property
              </Sidebar.Item>
              <Sidebar.Item href="/admin/message" icon={FaFacebookMessenger}>
                Message
              </Sidebar.Item>
              <Sidebar.Item href="/admin/lead" icon={MdLeaderboard}>
                Lead
              </Sidebar.Item>

              <Sidebar.Collapse icon={HiShoppingBag} label="Settings">
                <Sidebar.Item href="/admin/user/1" icon={HiUser}>
                  Users
                </Sidebar.Item>
                <Sidebar.Item
                  href="/admin/settings/list/contents"
                  icon={MdContentCopy}
                >
                  Contents
                </Sidebar.Item>
                <Sidebar.Item
                  href="/admin/settings/list/customers"
                  icon={IoPeopleCircle}
                >
                  Customer
                </Sidebar.Item>
              </Sidebar.Collapse>
            </Sidebar.ItemGroup>
            <Sidebar.ItemGroup>
              <Sidebar.Item href="/" icon={HiHome}>
                Home
              </Sidebar.Item>
              <Sidebar.Item
                className="cursor-pointer"
                icon={HiOutlineLogout}
                onClick={logout}
              >
                Log Out
              </Sidebar.Item>
            </Sidebar.ItemGroup>
            <Sidebar.ItemGroup>
              <Sidebar.Item>
                <div className="flex items-center space-x-2">
                  <Avatar />
                  <div>
                    <p className="font-bold text-lime-600">
                      {user?.user?.name}
                    </p>
                    <p className="text-xs text-slate-600">
                      {user?.user?.email}
                    </p>
                    <p className="text-xs text-slate-400">{user?.user?.role}</p>
                  </div>
                </div>
              </Sidebar.Item>
            </Sidebar.ItemGroup>
          </Sidebar.Items>
        </Sidebar>
      </div>
    </>
  );
}

export default SidebarComponent;
