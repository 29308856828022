import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getContent } from "../../apis";
import IntersectionObserverComponent from "../../components/IntersectionObserver";
export default function Cities() {
  const { data } = useQuery({
    queryKey: ["content"],
    queryFn: getContent(),
  });
  const cities = [
    {
      key: 1,
      title: "Kathmandu",
      alt: "",
      image: "/assets/kathmandu.jpg",
    },
    {
      key: 2,
      title: "Lalitpur",
      alt: "",
      image: "/assets/lalitpur.jpg",
    },
    {
      key: 3,
      title: "Bharatpur",
      alt: "",
      image: "/assets/bharatpur.jpg",
    },
    {
      key: 4,
      title: "Pokhara",
      alt: "",
      image: "/assets/pokhara.jpg",
    },
  ];
  return (
    <>
      <section
        id="cities"
        // className="md:min-h-screen"
        style={{ scrollSnapAlign: "center" }}
      >
        <div className="text-center py-16 md:space-y-2">
          <h2 className="text-2xl md:text-4xl font-semibold text-primary">
            {data?.data?.cities?.mainText || "Cities We Serve"}
          </h2>
          <p className="text-sm md:text-base font-medium px-4 md:px-auto">
            {data?.data?.cities?.subText ||
              "We proudly serve a variety of cities across Nepal, including Kathmandu, Lalitpur, Bharatpur, Pokhara and so on. Find your dream property in the perfect location!"}
          </p>
        </div>
        <div className="w-full md:w-4/5 mx-auto grid md:grid-cols-3 pb-16 gap-2 grid-cols-1 md:px-0 px-3">
          {cities?.map((city, i) => (
            <ImageCard
              key={city?.key}
              className={[i % 3 === 0 && "md:col-span-2"].join(" ")}
              title={city?.title}
              src={city?.image}
              alt={city?.alt || city?.title}
            />
          ))}
        </div>
      </section>
    </>
  );
}

function ImageCard({ src, alt = "", title, className = "" }) {
  const [_isVisible, setIsVisible] = useState(false);
  return (
    <>
      <IntersectionObserverComponent
        setIsVisible={setIsVisible}
        threshold={0.5}
        className={[
          "rounded-lg overflow-hidden",
          // isVisible ? "animate__animated animate__zoomIn" : "",
          className,
        ].join(" ")}
      >
        <Link
          to={`/listing?district=${title}`}
          className={[" relative  h-min shadow", className].join(" ")}
        >
          <div className="relative hover:scale-125 transition-all duration-500">
            <img
              className="relative h-[30vmin] w-full object-cover object-center rounded-lg"
              loading="lazy"
              src={src}
              alt={alt}
            />
            <div className="absolute bg-gradient-to-t from-black/70 inset-0" />
          </div>
          <p className="absolute bottom-4 left-4 drop-shadow font-bold md:text-2xl text-white">
            {title}
          </p>
        </Link>
      </IntersectionObserverComponent>
    </>
  );
}
