import React, { useMemo } from "react";

export default function YoutubeEmbed({ url = "" }) {
  const extractedEmbedId = useMemo(() => {
    if (!url) return null;

    // Check if the URL is from youtu.be
    if (url.includes("youtu.be")) {
      const embedId = url.split("/").pop().split("?")[0];
      window.log(embedId);
      return embedId;
    }

    // Check if the URL is from youtube.com
    const startIndex = url.indexOf("v=") + 2;
    const endIndex = url.indexOf("&", startIndex);
    const embedId =
      endIndex === -1
        ? url.substring(startIndex)
        : url.substring(startIndex, endIndex);
    return embedId;
  }, [url]);
  return (
    <div className="rounded-3xl shadow-big overflow-clip">
      <iframe
        width="800"
        height="450"
        className="w-full aspect-video"
        src={`https://www.youtube.com/embed/${extractedEmbedId}`}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  );
}
