export default function EMICalculator(
  loanAmounts, // number principal amount
  loanRate, // number rate
  loanTenure, // number number of months/year
  period // string month or year
) {
  const p = parseFloat(loanAmounts);
  const r = parseFloat(loanRate) / 1200; // Monthly interest rate
  const n =
    period === "month" ? parseFloat(loanTenure) : parseFloat(loanTenure) * 12;
  //    const [p,r,n]=[pareseFloat(principal),parseFloat(interestRate),parseFloat(tenure)]
  if (p && r && n) {
    const emi = (p * r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
    // p*r*(1+r)^n/((1+r)^(n-1)) formula to calculate EMI
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const totalInterest = emi * n - p;
    let tableEmi = [];
    let interest = r * loanAmounts;
    let totalLoanAmount = loanAmounts;
    let principal = emi - interest;
    let payment = emi;
    let balance = loanAmounts - principal;

    let d = new Date(Date.now());
    let month = d.getMonth();
    let year = d.getFullYear();
    for (let i = 0; i < n; i++) {
      tableEmi.push({
        interest: Math.ceil(interest),
        payment: Math.ceil(payment),
        principal: Math.ceil(principal),
        balance: Math.ceil(balance),
        month: months[month],
        year: year,
      });
      if (i == loanTenure - 2) {
        payment = emi - (Math.ceil(emi) * loanTenure - emi * loanTenure);
      }
      // calculations for table values
      interest = r * balance;
      principal = emi - interest;
      totalLoanAmount = balance;
      balance = totalLoanAmount - principal;
      month = month + 1;

      if (month >= 12) {
        year = year + 1;
        month = 0;
      }
    }
    return {
      emi: Math.ceil(emi.toFixed(2)),
      monthlyBasis: tableEmi,
      totalInterest: Math.ceil(totalInterest.toFixed(2)),
      totalPayment: Math.ceil(parseFloat(totalInterest.toFixed(2)) + p),
      principal: p,
    };
  } else {
    return "";
  }
}
