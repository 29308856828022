import { useQuery } from "@tanstack/react-query";
import { getContent, getSimilarProperties } from "../../apis";
import CardComp from "./components/LandingCards";
export default function SimilarProperties({ similarId }) {
  const { data, isLoading } = useQuery({
    queryKey: ["similarProperties"],
    queryFn: getSimilarProperties({ id: similarId }),
  });
  const { data: similarData } = useQuery({
    queryKey: ["content"],
    queryFn: () => getContent(),
  });
  return (
    <section
      id="similar"
      className="bg-slate-50 md:h-screen md:flex md:flex-col md:py-16"
      style={{ scrollSnapAlign: "center" }}
    >
      <div className="text-center py-12 md:space-y-2">
        <h2 className="text-2xl md:text-4xl font-semibold text-lime-600">
          {similarData?.data?.similar?.mainText || "Similar Properties"}
        </h2>
        <p className="text-sm md:text-base font-medium">
          {similarData?.data?.similar?.subText ||
            "Whether you seek sprawling land, a charming home, or a cozy apartment, we have it all. Explore your options and find your perfect match."}
        </p>
      </div>
      {!isLoading && <CardComp cards={data?.data?.results} />}
    </section>
  );
}
