import { createContext, useContext, useReducer } from 'react';

const FilterContext = createContext({});

function filterReducer(state, action) {
	switch (action.type) {
		case 'filter':
			return {
				...state,
				...action.payload,
			};

		default:
			return state;
	}
}

export default function FilterProvider({ children }) {
	const [state, dispatch] = useReducer(filterReducer);

	function onFilter(data) {
		dispatch({ type: 'filter', payload: data });
	}

	return (
		<FilterContext.Provider value={{ filter: state, onFilter }}>
			{children}
		</FilterContext.Provider>
	);
}

export function useFilter() {
	const context = useContext(FilterContext);
	return context;
}
