import {
  Button,
  Label,
  Modal,
  Select,
  TextInput,
  Textarea,
} from "flowbite-react";
import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa6";
import { IoIosArrowBack, IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
const LeadDetails = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState("");

  const [taskModal, setTaskModal] = useState(false);
  const [activityModal, setActivityModal] = useState(false);
  const closedTaskModal = () => {
    setTaskModal(false);
    setActivityModal(false);
  };
  return (
    <div className="w-full h-min-screen overflow-auto p-2 space-y-4">
      <h1 className="text-slate-800 font-semibold flex items-center gap-x-2">
        {" "}
        <FaArrowLeft
          fontSize="20px"
          className="cursor-pointer"
          onClick={() => navigate("/admin/lead")}
        />{" "}
        Narayan Shrestha
      </h1>

      <div className="flex text-sm">
        <div className="flex basis-[16%] flex-col shadow-md space-y-4 p-4">
          <h2 className="text-slate-800 font-semibold text-sm">Contact Info</h2>
          <div className="space-y-4">
            <p className="flex flex-col">
              <span className="text-slate-700">Full Name</span>
              <span>Narayan Shrestha</span>
            </p>
            <p className="flex flex-col">
              <span className="text-slate-700">Phone</span>
              <span>9834567890</span>
            </p>
            <p className="flex flex-col">
              <span className="text-slate-700">Email</span>
              <span>Narayan@123gmail.com</span>
            </p>
            <p className="flex flex-col">
              <span className="text-slate-700">Address</span>
              <span>Kathmandu</span>
            </p>
          </div>
        </div>
        <div className="flex basis-1/2 flex-grow flex-col p-4 space-y-4">
          <div className="flex justify-between">
            <h2 className="text-slate-800 font-semibold">Timeline</h2>
            <div className="flex gap-x-2">
              <Button color="primary" onClick={() => setTaskModal(true)}>
                {" "}
                + Add Task
              </Button>
              <Modal show={taskModal} onClose={closedTaskModal} size="lg">
                <Modal.Header>Add Task</Modal.Header>
                <Modal.Body>
                  <form className="space-y-4">
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="task" value="Task/Service" />
                      </div>
                      <Select id="task">
                        <option value="">Others</option>
                        <option value="">Property Listing</option>
                        <option value="">Property Update</option>
                        <option value="">Agency Profile Update</option>
                        <option value="">Profile delete</option>
                        <option value="">Site visit</option>
                      </Select>
                    </div>
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="assign" value="Assign To" />
                      </div>
                      <Select id="assign">
                        <option value="">bishal gaihre</option>
                        <option value="">avee adhikari</option>
                      </Select>
                    </div>
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="property" value="Property" />
                      </div>
                      <Select id="property">
                        <option value="">HS2403</option>
                        <option value="">HS2346</option>
                      </Select>
                    </div>
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="date" value="Date/Time" />
                      </div>
                      <TextInput id="date" type="date" required />
                    </div>
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="remark" value="Remark" />
                      </div>
                      <Textarea id="remark" />
                    </div>

                    <div className="flex gap-x-2">
                      <Button color="primary" type="submit">
                        Add Task
                      </Button>
                      <Button className="bg-transparent text-slate-950 border-2 border-red-600 hover:!bg-red-600 hover:text-slate-100 transition-all duration-150 ease-in-out">
                        Cancel
                      </Button>
                    </div>
                  </form>
                </Modal.Body>
              </Modal>
              <Button color="primary" onClick={() => setActivityModal(true)}>
                + Add Activity
              </Button>
              <Modal show={activityModal} onClose={closedTaskModal} size="lg">
                <Modal.Header>Add an Activity</Modal.Header>
                <Modal.Body>
                  <form className="space-y-4">
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="activityTask" value="Task/Service" />
                      </div>
                      <Select id="activityTask">
                        <option value="">Others</option>
                        <option value="">New Contact</option>
                        <option value="">Follow Up</option>
                      </Select>
                    </div>
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="purpose" value="Purpose" />
                      </div>
                      <Select id="purpose">
                        <option value="">Agency registration</option>
                        <option value="">Meeting</option>
                        <option value="">Property Buy</option>
                        <option value="">Property Sell</option>
                        <option value="">Site Visit</option>
                        <option value="">Property Listing</option>
                        <option value="">Property Update</option>
                        <option value="">Property Promotion</option>
                      </Select>
                    </div>
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="medium" value="Medium" />
                      </div>
                      <Select id="medium">
                        <option value="">Inbound Call</option>
                        <option value="">Inbound Message</option>
                        <option value="">Inbound SMS</option>
                        <option value="">Inbound Email</option>
                        <option value="">Outbound Call</option>
                        <option value="">Outbound Message</option>
                        <option value="">Outbound SMS</option>
                        <option value="">Outbound Email</option>
                      </Select>
                    </div>
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="status" value="Status" />
                      </div>
                      <Select id="status">
                        <option value="">Update</option>
                        <option value="">Pending</option>
                        <option value="">On Hold</option>
                        <option value="">In Process</option>
                        <option value="">Rejected</option>
                        <option value="">Low Budget</option>
                        <option value="">Closed</option>
                      </Select>
                    </div>
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="propertyActivity" value="Property" />
                      </div>
                      <Select id="propertyActivity">
                        <option value="">HS2403</option>
                        <option value="">HS2346</option>
                      </Select>
                    </div>

                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="remark" value="Remark" />
                      </div>
                      <Textarea id="remark" />
                    </div>

                    <div className="flex gap-x-2">
                      <Button color="primary" type="submit">
                        Add Activity
                      </Button>
                      <Button className="bg-transparent text-slate-950 border-2 border-red-600 hover:!bg-red-600 hover:text-slate-100 transition-all duration-150 ease-in-out">
                        Cancel
                      </Button>
                    </div>
                  </form>
                </Modal.Body>
              </Modal>
            </div>
          </div>
          <div>
            <div
              className="border-1 bg-slate-100 flex items-center gap-x-4 px-4 py-2 rounded-t-md hover:bg-slate-200 cursor-pointer transition-all duration-150 ease-in-out"
              onClick={() => setShow("#NH15723")}
            >
              {!show ? (
                <IoIosArrowBack fontSize="20px" />
              ) : (
                <IoIosArrowDown fontSize="20px" />
              )}
              <div>
                <p>Site Visit</p>
                <p>18/03/2024,07:24 PM</p>
              </div>
            </div>
            {show === "#NH15723" && (
              <div className="flex gap-x-6 border-[1px] border-slate-100 pl-4 py-2 rounded-b-md">
                <div className="space-y-1">
                  <p>Enquired Property</p>
                  <p>Property Id</p>
                  <p>Message</p>
                  <p>Added By</p>
                  <p>Assign By</p>
                  <p>Assign Date/Time</p>
                </div>
                <div className="space-y-1">
                  <p className="text-lime-700">
                    Cheap land on sale in Ghusel, Lalitpur
                  </p>
                  <p className="text-slate-700">#NH15723</p>
                  <p className="text-slate-700">Interested to buy land</p>
                  <p>Nryn Shrestha</p>
                  <p>Nryn Shrestha</p>
                  <p className="text-slate-700">23/03/2024, 08:30 AM</p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="shadow-md flex basis-1/5 flex-col space-y-4 p-4">
          <h2 className="text-slate-800 font-semibold text-sm">Other Info</h2>
          <div className="flex gap-x-2">
            <div className="space-y-4">
              <p className="text-slate-700">Last Activity</p>
              <p className="text-slate-700">Created On</p>
            </div>
            <div className="space-y-4">
              <p className="text-slate-700">18/03/2024, 07:24 PM</p>
              <p className="text-slate-700">18/03/2024, 07:24 PM</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadDetails;
