import React from 'react';
import { MdKeyboardReturn } from 'react-icons/md';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => (
	<div className="container mx-auto mt-8 p-4">
		<h2 className="text-3xl font-bold mb-4">Privacy Policy</h2>

		<p className="mb-2">Effective Date: [Insert Date]</p>

		<p className="mb-4">
			Thank you for visiting gprealestate.com.np, a website operated by GP Real
			Estate Pvt. Ltd. ("GP Real Estate," "we," "us," or "our"). At GP Real
			Estate, we are committed to protecting your privacy and ensuring the
			security of your personal information. This Privacy Policy outlines the
			types of information we may collect when you use our website, how we use
			and protect that information, and your rights regarding your personal
			data.
		</p>

		<section className="mb-6">
			<h3 className="text-xl font-bold mb-2">1. Information We Collect:</h3>

			<p>
				a. <strong>Personal Information:</strong>
			</p>

			<ul className="list-disc pl-4 mb-4">
				<li>
					When you visit gprealestate.com.np, we may collect personal
					information such as your name, email address, phone number, and any
					other information you voluntarily provide through forms on our
					website.
				</li>
			</ul>

			<p>
				b. <strong>Non-Personal Information:</strong>
			</p>

			<ul className="list-disc pl-4">
				<li>
					We may also collect non-personal information, such as IP addresses,
					browser type, and device information, to enhance the performance and
					functionality of our website.
				</li>
			</ul>
		</section>

		<section className="mb-6">
			<h3 className="text-xl font-bold mb-2">
				2. How We Use Your Information:
			</h3>

			<p>
				a. <strong>Personal Information:</strong>
			</p>

			<ul className="list-disc pl-4 mb-4">
				<li>
					Communicate with you about our services and respond to your inquiries.
				</li>
				<li>
					Display and advertise real estate properties on behalf of our clients.
				</li>
				<li>
					Send newsletters, updates, and promotional materials if you have opted
					in to receive them.
				</li>
			</ul>

			<p>
				b. <strong>Non-Personal Information:</strong>
			</p>

			<ul className="list-disc pl-4">
				<li>
					Non-personal information is used for website analytics, to improve our
					website's functionality, and for security purposes.
				</li>
			</ul>
		</section>

		<section className="mb-6">
			<h3 className="text-xl font-bold mb-2">3. Sharing Your Information:</h3>

			<p>
				a. <strong>Third-Party Service Providers:</strong>
			</p>

			<ul className="list-disc pl-4 mb-4">
				<li>
					We may share your information with third-party service providers who
					assist us in operating our website, conducting our business, or
					servicing you.
				</li>
			</ul>

			<p>
				b. <strong>Legal Compliance:</strong>
			</p>

			<ul className="list-disc pl-4 mb-4">
				<li>
					We may disclose your information to comply with applicable laws,
					regulations, or legal processes.
				</li>
			</ul>

			<p>
				c. <strong>Business Transactions:</strong>
			</p>

			<ul className="list-disc pl-4">
				<li>
					In the event of a merger, acquisition, or sale of all or part of our
					assets, your information may be transferred as part of the
					transaction.
				</li>
			</ul>
		</section>

		<section className="mb-6">
			<h3 className="text-xl font-bold mb-2">4. Security:</h3>

			<p>
				We take reasonable measures to protect the confidentiality and security
				of your personal information. However, no method of transmission over
				the internet or electronic storage is completely secure. Therefore,
				while we strive to protect your personal information, we cannot
				guarantee its absolute security.
			</p>
		</section>

		<section className="mb-6">
			<h3 className="text-xl font-bold mb-2">5. Your Rights:</h3>

			<p>
				You have the right to access, correct, or delete your personal
				information. If you wish to exercise these rights, please contact us at{' '}
				<a className="text-blue-500" href="mailto:contact@gprealestate.com.np">
					contact@gprealestate.com.np
				</a>
				.
			</p>
		</section>

		<section className="mb-6">
			<h3 className="text-xl font-bold mb-2">6. Cookies:</h3>

			<p>
				We use cookies to enhance your experience on our website. You can adjust
				your browser settings to reject cookies, but this may affect the
				functionality of our site.
			</p>
		</section>

		<section className="mb-6">
			<h3 className="text-xl font-bold mb-2">
				7. Updates to the Privacy Policy:
			</h3>

			<p>
				We may update this Privacy Policy from time to time to reflect changes
				in our practices or for other operational, legal, or regulatory reasons.
				Please review this Privacy Policy periodically.
			</p>
		</section>

		<section className="mb-6">
			<h3 className="text-xl font-bold mb-2">8. Contact Us:</h3>

			<p>
				If you have any questions, concerns, or requests regarding this Privacy
				Policy or our practices, please contact us at{' '}
				<a className="text-blue-500" href="mailto:contact@gprealestate.com.np">
					contact@gprealestate.com.np
				</a>
				.
			</p>
		</section>

		<p>
			By using gprealestate.com.np, you agree to the terms of this Privacy
			Policy.
		</p>
		<Link
			to="/"
			className=" underline text-blue-600 flex items-center space-x-2"
		>
			<MdKeyboardReturn />
			<span>Back to Home</span>
		</Link>
	</div>
);

export default PrivacyPolicy;
