import { useQuery } from "@tanstack/react-query";
import { Button } from "flowbite-react";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getContent } from "../../apis";
import Search from "./components/Search";
export default function Hero() {
  const { data } = useQuery({
    queryKey: ["content"],
    queryFn: getContent,
  });
  useEffect(() => {
    if (vidRef?.current) {
      vidRef?.current?.play();
    }
  }, []);
  const vidRef = useRef(null);
  const navigate = useNavigate();
  return (
    <section
      className={[
        "h-[45vh] md:h-[100dvh] w-screen overflow-hidden relative isolate",
        "flex flex-col justify-center md:justify-around items-center",
      ].join(" ")}
      style={{ scrollSnapAlign: "center" }}
    >
      <video
        ref={vidRef}
        autoPlay={true}
        loop={true}
        preload="auto"
        controls={false}
        muted
        playsinline="playsinline"
        className="w-full h-full object-cover -z-10 absolute"
      >
        <source src="/gp.mp4" type="video/mp4" />
      </video>
      <div />
      <div className="text-white mb-4 md:mb-0 md:py-0 pt-16 w-4/5 text-center">
        <h1 className="text-2xl md:text-5xl font-bold">
          {data?.data?.hero?.mainText || (
            <>
              <span>Welcome to </span>
              <span className="uppercase text-primary drop-shadow">
                GP Real Estate
              </span>
            </>
          )}
        </h1>
        <p className="text-sm md:text-2xl font-semibold md:mt-4 animate-write-text">
          {data?.data?.hero?.subText}
        </p>
      </div>
      <div className="absolute inset-0 -z-10 bg-gradient-to-t from-black to-transparent" />
      <Search />
      <Button
        size="sm"
        className="md:hidden block p-1 bg-blue-700"
        onClick={() => navigate("/listing")}
      >
        <span className="font-semibold">Get started</span>
      </Button>
      <div />
    </section>
  );
}
