import { useMutation, useQuery } from "@tanstack/react-query";
import { Button } from "flowbite-react";
import { useState } from "react";
import { HiPlus } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import { useAlert } from "../../../../_contexts/AlertContext";
import { deletePartner, getPartners } from "../../../../apis";
import Table from "../../../../components/Table";
function Partners() {
  const navigate = useNavigate();
  const { pageNum } = useParams();
  const { setAlert } = useAlert();
  const role = JSON.parse(localStorage.getItem("gp_user")).user.role;
  const [page, setPage] = useState(pageNum || 1);
  const { data, refetch } = useQuery({
    queryKey: ["partners"],
    queryFn: () => getPartners(),
  });
  const { mutate: deleteProp } = useMutation({
    mutationKey: ["deletePartner"],
    mutationFn: deletePartner,
    onSuccess: () => {
      setAlert({
        type: "success",
        message: "Partner Deleted Successfully",
        isOpen: true,
      });
      refetch();
    },
  });

  const headers = [
    {
      key: "name",
      title: "Name",
    },
    {
      key: "image",
      title: "Image",
      render: (partnerData) => (
        <div>
          <img
            className="w-20 rounded-xl object-contain object-center"
            src={partnerData?.image}
            alt={partnerData?.images?.title}
          />
        </div>
      ),
    },

    {
      key: "_action",
      title: "Actions",
      actions: {
        delete: (data) => deleteProp({ id: data?._id }),
        // edit: (data) => navigate(`/admin/settings/update/partner/${data?._id}`),
      },
      role: role,
    },
  ];

  // const checkState = useState([]);

  return (
    <>
      <div className="flex justify-between items-center flex-row md:pb-0 pb-4 md:py-0 py-12">
        <h2 className="md:text-3xl text-2xl font-semibold md:mb-6 mb-0">
          Partners
        </h2>
        {role !== "user" && (
          <Button
            outline
            gradientDuoTone="greenToBlue"
            pill
            onClick={() => navigate("/admin/settings/create/partner")}
          >
            <HiPlus className="mr-2 h-5 w-5" />
            Add Partner
          </Button>
        )}
      </div>
      <div className="overflow-scroll md:overflow-auto scrollbar-hide">
        <Table
          headers={headers}
          data={data?.data?.results}
          // checkState={checkState}
          isLoading={false}
          page={page}
          setPage={setPage}
          total={1}
          // withCheckBoxes
          //
        />
      </div>
    </>
  );
}

export default Partners;
