import { Checkbox, Table as TableFB } from "flowbite-react";
import {
  MdAdd,
  MdDarkMode,
  MdDelete,
  MdEdit,
  MdLightMode,
} from "react-icons/md";
import Loader from "./Loader";
import Pagination from "./Pagination";
function Table(props) {
  const {
    hoverable = true,
    headers = [],
    withCheckBoxes = false,
    data = [],
    checkState = [],
    onSelection = null,
    isLoading = false,
    page,
    setPage,
    total,
    onPageChange = () => {},
  } = props;
  const [selected, setSelected] = checkState;
  if (withCheckBoxes && (!selected || !setSelected))
    return <>Pass prop: checkState=[state, setState] in Table component </>;

  function onCheck(d) {
    return (e) => {
      if (!e.target.checked) {
        setSelected((prev) => prev.filter((v) => v?._id !== d?._id));
        onSelection && onSelection(selected.filter((v) => v?._id !== d?._id));
      } else {
        setSelected((prev) => [d, ...prev]);
        onSelection && onSelection([d, ...selected]);
      }
    };
  }

  function onCheckAll(e) {
    if (!e.target.checked) {
      setSelected([]);
      onSelection && onSelection([]);
    } else {
      setSelected([...data]);
      onSelection && onSelection([...data]);
    }
  }

  return (
    <>
      <TableFB hoverable={hoverable}>
        <TableFB.Head>
          {withCheckBoxes && (
            <TableFB.HeadCell>
              <Checkbox
                defaultChecked={selected?.length === data?.length}
                checked={selected?.length === data?.length}
                onChange={onCheckAll}
              />
            </TableFB.HeadCell>
          )}
          {headers?.map((head, i) => (
            <TableFB.HeadCell
              key={head?.key ?? head?._id ?? i}
              className={head?.className}
            >
              {head?.title}
            </TableFB.HeadCell>
          ))}
        </TableFB.Head>
        <TableFB.Body>
          {isLoading && (
            <TableFB.Row>
              <TableFB.Cell
                colSpan={withCheckBoxes ? +headers.length + 1 : headers.length}
                className="py-16"
              >
                <Loader />
              </TableFB.Cell>
            </TableFB.Row>
          )}
          {!isLoading && data?.length > 0
            ? data?.map((d, i) => (
                <TableFB.Row key={d?.key ?? d?._id ?? i}>
                  {withCheckBoxes && (
                    <TableFB.Cell>
                      <Checkbox
                        onChange={onCheck(d)}
                        defaultChecked={selected?.find(
                          (v) => v?._id === d?._id
                        )}
                        checked={selected?.find((v) => v?._id === d?._id)}
                      />
                    </TableFB.Cell>
                  )}
                  {headers?.map((head, index) => (
                    <TableFB.Cell
                      className={head?.className}
                      key={head?.key ?? head?._id ?? index}
                    >
                      <TableData data={d} head={head} dataIndex={i} />
                    </TableFB.Cell>
                  ))}
                </TableFB.Row>
              ))
            : !isLoading && (
                <TableFB.Row>
                  <TableFB.Cell
                    className="h-[25dvh]"
                    colSpan={
                      withCheckBoxes ? headers?.length + 1 : headers?.length
                    }
                  >
                    <div className="grid place-content-center h-full w-full text-center">
                      No data
                    </div>
                  </TableFB.Cell>
                </TableFB.Row>
              )}
        </TableFB.Body>
      </TableFB>
      {!isLoading && (
        <Pagination
          currentPage={page}
          setCurrentPage={setPage}
          totalPages={Math.ceil(total / 10)}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
}

function TableData({ data, head, dataIndex }) {
  if (head?.render) {
    return head?.render(data, dataIndex);
  }

  switch (head?.key) {
    case "_action":
      return (
        <div className="space-x-1">
          {Object.keys(head?.actions)?.map((action, index) =>
            data?.role === "admin" || head?.role === "user" ? (
              ""
            ) : (
              <Action
                key={index}
                type={action}
                data={data}
                onClick={head?.actions[action]}
              />
            )
          )}
        </div>
      );
    default:
      return <>{data[head?.key]}</>;
  }
}

export default Table;

function Action({ type, data, onClick }) {
  function handleClick() {
    onClick && onClick(data);
  }
  switch (type) {
    case "view":
      return null;

    case "add":
      return (
        <button
          className={["p-1 rounded-full shadow-md text-cyan-600"].join(" ")}
          onClick={handleClick}
        >
          <MdAdd />
        </button>
      );
    case "sold":
      return (
        <button
          className={["p-1 rounded-md shadow-md text-cyan-600"].join(" ")}
          onClick={handleClick}
        >
          {data?.sold ? "Sold" : "Unsold"}
        </button>
      );
    case "edit":
      return (
        <button
          className={["p-1 rounded-full shadow-md text-cyan-600"].join(" ")}
          onClick={handleClick}
        >
          <MdEdit />
        </button>
      );
    case "delete":
      return (
        <button
          className={["p-1 rounded-full shadow-md text-rose-600"].join(" ")}
          onClick={handleClick}
        >
          <MdDelete />
        </button>
      );
    case "featured":
      return (
        <button
          title="feature"
          className={[
            "p-1 rounded-full shadow-md",
            data?.featured && "bg-lime-600 text-white",
          ].join(" ")}
          onClick={handleClick}
        >
          {data?.featured ? <MdLightMode /> : <MdDarkMode />}
        </button>
      );
    default:
      return null;
  }
}
