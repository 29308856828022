import { BsLightningChargeFill } from "react-icons/bs";
import { FaCity, FaUsers } from "react-icons/fa";
import { MdAddHome, MdMapsHomeWork } from "react-icons/md";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
function Dashboard() {
  const data = [
    { name: "House", value: 500 },
    { name: "Apartment", value: 200 },
    { name: "Land", value: 600 },
  ];

  const barData = [
    { name: "Jan", lead: 4000 },
    { name: "Feb", lead: 3000 },
    { name: "Mar", lead: 2000 },
    { name: "Apr", lead: 2780 },
    { name: "May", lead: 1890 },
    { name: "Jun", lead: 2390 },
    { name: "Jul", lead: 3490 },
    { name: "Aug", lead: 2500 },
    { name: "Sep", lead: 4500 },
    { name: "Oct", lead: 3200 },
    { name: "Nov", lead: 3800 },
    { name: "Dec", lead: 4700 },
  ];

  const lineData = [
    {
      name: "Jan",
      revenue: 4000,
      sale: 2400,
    },
    {
      name: "Feb",
      revenue: 3000,
      sale: 1398,
    },
    {
      name: "Mar",
      revenue: 2000,
      sale: 9800,
    },
    {
      name: "Apr",
      revenue: 2780,
      sale: 3908,
    },
    {
      name: "May",
      revenue: 1890,
      sale: 4800,
    },
    {
      name: "Jun",
      revenue: 2390,
      sale: 3800,
    },
    {
      name: "Jul",
      revenue: 3490,
      sale: 4300,
    },
    {
      name: "Aug",
      revenue: 2500,
      sale: 2300,
    },
    {
      name: "Sep",
      revenue: 4500,
      sale: 3200,
    },
    {
      name: "Oct",
      revenue: 3200,
      sale: 2800,
    },
    {
      name: "Nov",
      revenue: 3800,
      sale: 3400,
    },
    {
      name: "Dec",
      revenue: 4700,
      sale: 3900,
    },
  ];
  const PropertiesList = [
    {
      title: "Properties for Sale",
      value: "648",
      icon: <BsLightningChargeFill className="text-3xl text-green-500" />,
    },
    {
      title: "Properties for Rent",
      value: "238",
      icon: <MdAddHome className="text-3xl text-green-500" />,
    },
    {
      title: "Total Customer",
      value: "245",
      icon: <FaUsers className="text-3xl text-green-500" />,
    },
    {
      title: "Total Properties",
      value: "456",
      icon: <MdMapsHomeWork className="text-3xl text-green-500" />,
    },
    {
      title: "Total City",
      value: "30",
      icon: <FaCity className="text-3xl text-green-500" />,
    },
  ];
  return (
    <>
      <main className="bg-slate-50 min-h-screen pt-4">
        <h1 className="text-3xl font-semibold pl-4 mb-4 border-b-2">
          Dashboard
        </h1>
        <div className="grid md:grid-cols-5 grid-cols-1 gap-6 md:pt-0 px-4 mx-auto w-full">
          {/* <PieChartComponent data={data} title="Sales by Property Type" /> */}

          {PropertiesList.map((d, i) => (
            <div
              className="w-full bg-white shadow-md h-fit p-5 rounded-md flex justify-between items-center"
              key={i}
            >
              <div className="space-y-1">
                <p className="text-lg font-semibold text-slate-600">
                  {d?.title}
                </p>
                <p className="text-2xl font-bold text-slate-800">{d?.value}</p>
              </div>
              <div className="bg-slate-100 h-fit p-2 rounded-md">{d?.icon}</div>
            </div>
          ))}
          {/* <div className="col-span-3 grid"> */}
          <LineChartComponent
            data={lineData}
            title="Revenue Analytic"
            dataKey={["revenue", "sale"]}
          />
          <PieChartComponent data={data} title="Sales by Property Type" />
          <MapComponent />
          <ListPropertiesComp title={"Top Properties List"} />
          <ListPropertiesComp title={"Latest Sales Properties"} />
          {/* <ListPropertiesComp title={"Recent Message"} /> */}
          <RecentMessagePropertiesComp title={"Recent Message"} />
          <div className="col-span-4 mb-8">
            <BarChartComponent data={barData} title="Sales in month" />
          </div>
          {/* </div> */}
        </div>
      </main>
    </>
  );
}

export default Dashboard;

const PieChartComponent = ({ data, title = "" }) => {
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <>
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor={"middle"}
          dominantBaseline="central"
          className="text-lg font-bold"
        >
          {`${(percent * 100).toFixed(0)}% `}
        </text>
        {/* <text
          x={x}
          y={y + 16}
          fill="white"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
        >
          {data[index]?.name}
        </text> */}
      </>
    );
  };
  return (
    <ResponsiveContainer
      width="100%"
      height={500}
      className="shadow-md rounded-3xl col-span-2 grid bg-white"
    >
      <p className="p-4 text-slate-700 font-semibold text-lg">{title}</p>

      <PieChart width="100%" className="!h-[80%]">
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius="80%"
          innerRadius="50%"
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};
const LineChartComponent = ({ data, title, dataKey = null }) => {
  window.log(title);
  return (
    <ResponsiveContainer
      width="100%"
      height={500}
      className="shadow-md rounded-3xl col-span-3 grid bg-white"
    >
      <p className="p-4 text-lg font-semibold text-slate-700">{title}</p>

      <LineChart
        width="100%"
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        className="!h-[88%]"
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={"name"} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey={dataKey[0]}
          stroke="#8884d8"
          strokeWidth={2}
          // strokeDasharray="5 5"
        />
        <Line
          type="monotone"
          dataKey={dataKey[1]}
          stroke="#82ca9d"
          strokeWidth={2}
          // strokeDasharray="3 4 5 2"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const MapComponent = () => {
  const mapList = [
    {
      id: "1",
      name: "kathmandu",
      position: [27.71320948087147, 85.32098217738728],
      salesNumber: 40,
    },
    {
      id: "2",
      name: "lalitpur",
      position: [27.662784228321968, 85.32469612079684],
      salesNumber: 30,
    },
    {
      id: "3",
      name: "bharatpur",
      position: [27.670860541465718, 85.42944478130991],
      salesNumber: 20,
    },
    {
      id: "4",
      name: "pokhara",
      position: [28.209126582193797, 83.98888752384435],
      salesNumber: 10,
    },
    {
      id: "5",
      name: "chitwan",
      position: [27.557754108722314, 84.38780987420614],
      salesNumber: 5,
    },
  ];
  const positio = [28.54930948024086, 84.5466351408502];
  return (
    <div className="p-2 col-span-3 grid bg-white rounded-md shadow-md">
      <p className="text-lg font-semibold text-slate-700 pb-2">
        Most Sales Location
      </p>
      <MapContainer
        center={positio}
        zoom={7}
        className="w-full h-[50vh] rounded-xl z-10"
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {mapList?.map((v) => (
          <>
            <Marker position={v?.position}>
              <Popup>
                <div className="flex flex-col items-center justify-center font-semibold">
                  <span>{v?.name}</span>
                  <span>{v?.salesNumber}</span>
                </div>
              </Popup>
            </Marker>
          </>
        ))}
      </MapContainer>
      {/* <div className="flex justify-between py-6 pl-2 pr-6">
        {mapList?.map((v) => (
          <div className="relative">
            <p className="bg-green-400 p-2 rounded-md text-white font-semibold">
              {v?.name}
            </p>
            <p className="absolute top-[-1rem] right-[-2rem] bg-slate-50/80 shadow-lg rounded-full w-10 h-10 font-semibold p-1 text-center">
            40
            </p>
          </div>
        ))}
      </div> */}
    </div>
  );
};

const RecentMessagePropertiesComp = ({ title }) => {
  return (
    <div className="p-2 col-span-2 grid bg-white rounded-md shadow-md ">
      <p className="text-lg font-semibold text-slate-700 pb-2">{title}</p>
      <div className="space-y-4 h-[30rem] overflow-auto px-2">
        {Array.from({ length: 8 }, (i) => i).map(() => (
          <div className="flex justify-between items-center cursor-pointer border-b-2 pb-4">
            <div className="flex gap-x-2 items-center justify-center">
              <div className="w-12 h-12">
                <img
                  src="/assets/bharatpur.jpg"
                  alt=""
                  className="rounded-full w-full h-full object-cover"
                />
              </div>
              <div>
                <p className="text-slate-700 font-semibold">Bishal Gaihre</p>
                <p className="text-slate-500 font-semibold">
                  Hello its me bishal..
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const ListPropertiesComp = ({ title }) => {
  return (
    <div className="p-2 col-span-2 grid bg-white rounded-md shadow-md ">
      <p className="text-lg font-semibold text-slate-700 pb-2">{title}</p>
      <div className="space-y-4 h-[30rem] overflow-auto">
        {Array.from({ length: 8 }, (i) => i).map(() => (
          <div className="flex justify-between items-center cursor-pointer">
            <div className="flex gap-x-2 items-center justify-center">
              <div className="w-16 h-16">
                <img
                  src="/assets/bharatpur.jpg"
                  alt=""
                  className="rounded-md w-full h-full object-cover"
                />
              </div>
              <div className="space-y-2">
                <p className="text-slate-700 font-semibold">House</p>
                <p className="text-slate-500">Kathmandu Nepal</p>
              </div>
            </div>
            <div>
              <p className="bg-green-200 px-3 py-1 rounded-lg text-slate-700">
                sale
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
const BarChartComponent = ({ data, title, dataKey = null }) => {
  return (
    <ResponsiveContainer
      width="100%"
      height={600}
      className="shadow-big rounded-3xl"
    >
      <p className="p-4">{title}</p>
      <BarChart
        width="100%"
        height={400}
        data={data}
        margin={{
          right: 32,
          left: 32,
          bottom: 32,
        }}
        className="!h-[95%]"
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={dataKey || "name"} />
        <YAxis />
        <Tooltip />
        <Legend />
        {Object.entries(data?.[0] || {})
          .map((entry) => {
            if (entry[0] === (dataKey || "name")) return null;
            else {
              return entry;
            }
          })
          .map(
            (entry) =>
              entry && (
                <Bar
                  dataKey={entry?.[0]}
                  fill={getRandomColor()}
                  activeBar={<Rectangle stroke="blue" />}
                />
              )
          )}
      </BarChart>
    </ResponsiveContainer>
  );
};

function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

// import React from "react";

// const data = [
//   {
//     name: "Page A",
//     uv: 4000,
//     pv: 2400,
//     amt: 2400,
//   },
//   {
//     name: "Page B",
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     name: "Page C",
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     name: "Page D",
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
//   {
//     name: "Page E",
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//   },
//   {
//     name: "Page F",
//     uv: 2390,
//     pv: 3800,
//     amt: 2500,
//   },
//   {
//     name: "Page G",
//     uv: 3490,
//     pv: 4300,
//     amt: 2100,
//   },
// ];

// <ResponsiveContainer width="100%" height="100%">
//   <BarChart width={150} height={40} data={data}>
//     <Bar dataKey="uv" fill="#8884d8" />
//   </BarChart>
// </ResponsiveContainer>;
