import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import {
  FaBuildingColumns,
  FaHandHoldingHeart,
  FaPeopleCarryBox,
  FaPeopleGroup,
} from "react-icons/fa6";
import { getContent, getStats } from "../../apis";
import Intersection from "../../components/IntersectionObserver";
export default function WhyUs() {
  const { data } = useQuery({
    queryKey: ["whyUs"],
    queryFn: getStats,
  });
  const { data: whyUsData } = useQuery({
    queryKey: ["content"],
    queryFn: () => getContent(),
  });
  const stats = [
    {
      key: 1,
      stat: data?.data?.clients,
      stat_title: "Clients",
      icon: (props) => <FaPeopleGroup {...props} />,
      color: "text-indigo-500",
    },
    {
      key: 2,
      stat: data?.data?.properties,
      stat_title: "Properties",
      icon: (props) => <FaBuildingColumns {...props} />,
      color: "text-emerald-500",
    },
    {
      key: 3,
      stat: data?.data?.reviews,
      stat_title: "Reviews",
      icon: (props) => <FaHandHoldingHeart {...props} />,
      color: "text-rose-500",
    },
    {
      key: 4,
      stat: data?.data?.partner,
      stat_title: "Partners",
      icon: (props) => <FaPeopleCarryBox {...props} />,
      color: "text-yellow-500",
    },
  ];

  return (
    <>
      <section
        id="why-us"
        className="md:h-screen md:flex md:flex-col md:justify-center"
        style={{ scrollSnapAlign: "center" }}
      >
        <div className={["min-h-[70dvh] grid items-center py-8"].join(" ")}>
          <div className="text-center md:space-y-2 mb-4 md:mb-auto">
            <h2 className="text-2xl md:text-4xl font-semibold mt-8 md:mt-auto text-primary">
              {whyUsData?.data?.whyUs?.mainText || "Why Choose Us"}
            </h2>
            <p className="text-sm md:text-base font-medium px-4 md:px-auto">
              {whyUsData?.data?.whyUs?.subText ||
                "Our dedicated team works closely with you to understand your specific requirements and goals."}
            </p>
          </div>
          <div className="w-4/5 mx-auto grid grid-cols-2 md:grid-cols-4 gap-4 mb-4 md:mb-auto">
            {stats.map((stat) => (
              <Stat key={stat?.key} stat={stat} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
function Stat({ stat }) {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    let interval = null;
    if (isIntersecting) {
      interval = setInterval(() => {
        if (+ref?.current?.innerText === +stat?.stat) return;
        else ref.current.innerText++;
      }, 1000 / stat?.stat);
    }
    return () => interval && clearInterval(interval);
  }, [stat, isIntersecting]);

  return (
    <Intersection setIsVisible={setIsIntersecting} threshold={0.9}>
      <div className="text-center md:aspect-square flex flex-col items-center justify-center rounded-xl">
        <div className="rounded-full aspect-square grid place-content-center mb-8 w-max p-8 shadow-2xl relative isolate">
          <stat.icon
            className={[
              "text-[35px] md:text-[70px]",
              "hover:scale-125 transition-transform duration-300",
              isIntersecting && "animate__animated animate__jackInTheBox",
              stat?.color,
            ].join(" ")}
          />
          <div className="absolute inset-0 bg-gradient-to-b from-white to-black opacity-30 blur-3xl -z-10 " />
        </div>
        <h1 className="text-2xl md:text-4xl font-bold" ref={ref}>
          {/* {stat?.stat} */}0
        </h1>
        <h2
          className={["text-base md:text-2xl font-semibold ", stat?.color].join(
            " "
          )}
        >
          {stat?.stat_title}
        </h2>
      </div>
    </Intersection>
  );
}
