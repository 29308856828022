import { Label, Select } from "flowbite-react";
import React, { useEffect, useState } from "react";
import localLevels from "../_data/localLevels";

export default function AddressSelect({
  ids = [],
  setValue = null,
  defaultValues = null,
  error,
}) {
  const [address, setAddress] = useState({
    province: null,
    district: null,
    municipal: null,
    ward: null,
  });
  function handleSelect(type) {
    return (e) => {
      if (type !== "ward") {
        setAddress((prev) => ({ ...prev, [type]: JSON.parse(e.target.value) }));
      } else {
        setAddress((prev) => ({ ...prev, [type]: e.target.value }));
      }
      let index = ["province", "district", "municipal", "ward"];
      ids?.forEach((id, i) => {
        if (index[i] === type) {
          if (type !== "ward") {
            setValue(id, JSON.parse(e.target.value)?.name);
          } else {
            setValue(id, e.target.value);
          }
        } else {
          if (index[i] === "ward") setValue(id, address.ward);
          else setValue(id, address[index[i]]?.name);
        }
      });
    };
  }

  useEffect(() => {
    let province = null,
      district = null,
      municipal = null,
      ward = null;
    if (defaultValues) {
      province = localLevels.find((p) => p?.name === defaultValues?.province);
      if (province) {
        district = Object.values(province?.districts)?.find(
          (d) => d?.name === defaultValues?.district
        );
        if (district) {
          municipal = Object.values(district?.municipalities)?.find(
            (m) => m?.name === defaultValues?.municipal
          );
          if (municipal) {
            ward = defaultValues?.ward;
          }
        }
      }
    }
    setAddress({
      province: province,
      district: district,
      municipal: municipal,
      ward: ward,
    });
  }, [defaultValues]);

  return (
    <>
      <div>
        <Label>Province</Label>
        <Select
          onChange={handleSelect("province")}
          value={JSON.stringify(address.province)}
          helperText={error?.province?.message}
          color={error?.province && "failure"}
        >
          <option value="">Select Province</option>
          {localLevels?.map((province) => (
            <option key={province?.id} value={JSON.stringify(province)}>
              {province?.name}
            </option>
          ))}
        </Select>
      </div>
      {address?.province && (
        <div>
          <Label>District</Label>
          <Select
            onChange={handleSelect("district")}
            disabled={!address.province}
            value={JSON.stringify(address.district)}
            helperText={error?.district?.message}
            color={error?.district && "failure"}
          >
            <option value="">Select District</option>
            {address.province &&
              address.province?.districts &&
              Object.values(address.province?.districts)?.map((district, i) => (
                <option key={i} value={JSON.stringify(district)}>
                  {district?.name}
                </option>
              ))}
          </Select>
          {error?.district && (
            <span className="text-red-600">This field is required.</span>
          )}
        </div>
      )}
      {address?.district && (
        <div>
          <Label>Municipality</Label>
          <Select
            disabled={!address.district}
            onChange={handleSelect("municipal")}
            helperText={error?.municipal?.message}
            color={error?.municipal && "failure"}
            value={JSON.stringify(address.municipal)}
          >
            <option value="">Select Municipality</option>
            {address.district &&
              address.district?.municipalities &&
              Object.values(address.district?.municipalities)?.map(
                (municipal, i) => (
                  <option key={i} value={JSON.stringify(municipal)}>
                    {municipal?.name}
                  </option>
                )
              )}
          </Select>
          {error?.municipal && (
            <span className="text-red-600">This field is required.</span>
          )}
        </div>
      )}
      {address?.municipal && (
        <div>
          <Label>Wards</Label>
          <Select
            disabled={!address.municipal}
            onChange={handleSelect("ward")}
            helperText={error?.ward?.message}
            color={error?.ward && "failure"}
            value={address.ward}
          >
            <option value="">Select Ward</option>
            {address.municipal &&
              address.municipal?.wards &&
              address.municipal?.wards?.map((ward, i) => (
                <option key={i} value={ward}>
                  {ward}
                </option>
              ))}
          </Select>
          {error?.ward && (
            <span className="text-red-600">This field is required.</span>
          )}
        </div>
      )}
    </>
  );
}
