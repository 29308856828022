import { Button, Label, Radio, Table, TextInput } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

import EMICalculator from "../../_helpers/EMICalculator";
import commaSeparatedNum from "../../_helpers/commaSeparatedNum";
const Tool = ({
  initialState = {
    principal: "100000000",
    interestRate: "9",
    tenure: "6",
  },
}) => {
  const [emi, setEmi] = useState("");
  const [period, setPeriod] = useState("month");
  const headers = [
    {
      key: "year",
      title: "Year",
    },
    {
      key: "principal",
      title: "Principal",
    },
    {
      key: "interest",
      title: "Interest",
    },
    {
      key: "totalPayment",
      title: "Total Payment",
    },
    {
      key: "balance",
      title: "Balance",
    },
  ];

  const [EmiParameter, setEmiParameter] = useState(initialState);
  const handleEMI = () => {
    setEmi(
      EMICalculator(
        EmiParameter?.principal,
        EmiParameter?.interestRate,
        EmiParameter?.tenure,
        period
      )
    );
  };
  useEffect(() => {
    setEmi(
      EMICalculator(
        EmiParameter?.principal,
        EmiParameter?.interestRate,
        EmiParameter?.tenure,
        period
      )
    );
  }, []);
  const data = [
    { name: "Principal Amount", value: emi?.principal },
    { name: "Total Interest", value: emi?.totalInterest },
  ];

  return (
    <div className="w-[80vw] min-h-[100dvh] mx-auto pt-32">
      <h1 className="text-xl font-bold text-lime-600 pl-16">EMI Calculator</h1>
      <div className="flex">
        <div className="flex flex-col md:space-y-8 h-max flex-1">
          <div className="w-5/6 flex flex-col space-y-4 md:space-y-6 md:mx-auto md:p-6 px-6 py-4">
            <form className="flex flex-col min-w-sm space-y-4">
              <div className="flex flex-col gap-x-8 gap-y-4">
                <div className="grow">
                  <TextInput
                    id="principalAmt"
                    type="number"
                    value={EmiParameter?.principal}
                    className="grow flex"
                    addon="NPR"
                    onChange={(e) =>
                      setEmiParameter({
                        ...EmiParameter,
                        principal: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="grow">
                  <TextInput
                    id="interestRate"
                    type="number"
                    value={EmiParameter?.interestRate}
                    className="flex grow"
                    addon="%"
                    onChange={(e) =>
                      setEmiParameter({
                        ...EmiParameter,
                        interestRate: e.target.value,
                      })
                    }
                  />
                </div>

                <fieldset className="flex max-w-md flex-col gap-2 text-slate-700">
                  <legend className="mb-2">Tenure type</legend>
                  <div className="flex gap-x-4">
                    <div className="flex items-center gap-2 ">
                      <Radio
                        id="months"
                        name="tenure"
                        value="months"
                        onClick={() => setPeriod("month")}
                        checked={period === "month" && true}
                      />
                      <Label htmlFor="china" className="text-slate-700">
                        Months
                      </Label>
                    </div>
                    <div className="flex items-center gap-2">
                      <Radio
                        id="years"
                        name="tenure"
                        value="years"
                        onClick={() => setPeriod("year")}
                        checked={period === "year" && true}
                      />
                      <Label htmlFor="years" className="text-slate-700">
                        Years
                      </Label>
                    </div>
                  </div>
                </fieldset>
                <div className="grow">
                  <TextInput
                    id="tenure"
                    type="number"
                    value={EmiParameter?.tenure}
                    className="flex grow"
                    onChange={(e) =>
                      setEmiParameter({
                        ...EmiParameter,
                        tenure: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-x-4 ">
                <Button
                  color={"primary"}
                  onClick={handleEMI}
                  className="w-full"
                >
                  Calculate EMI
                </Button>
                <button
                  type="reset"
                  onClick={() => {
                    setEmiParameter(initialState);
                    setEmi("");
                  }}
                  className="w-full border-[1px] border-black px-4 py-1 rounded-lg"
                >
                  Reset
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="flex-1 flex flex-col">
          <div className="p-12 space-y-4">
            <div className="space-y-4">
              <div className="flex flex-col">
                <span className="text-gray-500">Loan EMI:</span>
                <span className="font-bold ">
                  Rs. {commaSeparatedNum(emi?.emi)}
                </span>
              </div>
              <hr />
            </div>
            <div className="space-y-4">
              <div className="flex flex-col">
                <span className="text-gray-500">Total Interest Payable:</span>
                <span className="font-bold">
                  Rs. {commaSeparatedNum(emi?.totalInterest)}
                </span>
              </div>
              <hr />
            </div>
            <div className="flex flex-col">
              <span className="text-gray-500">Total Payment:</span>
              <span className="font-bold">
                Rs. {commaSeparatedNum(emi?.totalPayment)}
              </span>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <PieChartComponent data={data} title="Emi Calculation" />
        </div>
      </div>
      <div className="space-y-4 pt-12">
        <h2 className="font-semibold text-green-500">
          Monthly breakdown of EMI in Principal and Interest
        </h2>
        <TableEmi headers={headers} data={emi?.monthlyBasis} />
      </div>
    </div>
  );
};

const PieChartComponent = ({ data }) => {
  const COLORS = ["#65A30D", "#00C49F"];

  const RADIAN = Math.PI / 180;
  const _renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <>
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
        >
          {`${(percent * 100).toFixed(0)}% `}
        </text>
        <text
          x={x}
          y={y + 16}
          fill="white"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
        >
          {data[index]?.name}
        </text>
      </>
    );
  };
  return (
    <ResponsiveContainer
      width="100%"
      // height="100%"
      height={350}
      className=""
    >
      <div className="flex justify-between items-center p-2">
        {/* <div>
          <p className="font-bold pl-2 uppercase text-slate-600">{title}</p>
        </div> */}
        <div className="flex gap-x-6 justify-center pl-8">
          <div className="flex items-center gap-x-1">
            <p className="w-8 h-2 bg-[#00C49F]"></p>
            Total Interest
          </div>
          <div className="flex items-center gap-x-1">
            <p className="w-8 h-2 bg-[#65A30D]"></p>
            Principal loan Amount
          </div>
        </div>
      </div>
      <PieChart height="100%" width="100%">
        <Pie
          data={data}
          cx="50%"
          cy="40%"
          // labelLine={false}
          // label={renderCustomizedLabel}
          outerRadius="80%"
          fill="#8884d8"
          innerRadius={60}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip isAnimationActive={true} />
      </PieChart>
    </ResponsiveContainer>
  );
};
const TableEmi = ({ headers, data }) => {
  const [year, setYear] = useState("");
  // const [multipleShow, setMultipleShow] = useState(null);
  const groupedData = React.useMemo(() => {
    const yearData = {};
    data?.forEach((item) => {
      const year = item.year;
      if (!yearData[year]) {
        yearData[year] = {
          data: [],
          totalPayment: 0,
          totalPrincipal: 0,
          totalInterest: 0,
          totalBalance: 0,
        };
      }
      yearData[year].data.push(item);
      yearData[year].totalPayment += item.payment;
      yearData[year].totalPrincipal += item.principal;
      yearData[year].totalInterest += item.interest;
      yearData[year].totalBalance += item.balance;
    });

    const grouped = Object.entries(yearData).map(
      ([
        year,
        { data, totalPayment, totalPrincipal, totalInterest, totalBalance },
      ]) => ({
        year,
        data,
        totalPayment,
        totalPrincipal,
        totalInterest,
        totalBalance,
      })
    );
    return grouped;
  }, [data]);

  return (
    <div className="overflow-x-auto">
      <Table>
        <Table.Head className="text-gray-500">
          {headers?.map((head) => (
            <Table.HeadCell key={head?.key}>{head?.title}</Table.HeadCell>
          ))}
        </Table.Head>
        {}

        <Table.Body className="divide-y">
          {groupedData?.map((d) => (
            <>
              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 text-black">
                <Table.Cell
                  className="flex gap-x-1"
                  onClick={() => {
                    setYear(d.year === year ? "" : d.year);
                  }}
                >
                  {year !== d.year ? (
                    <CiCirclePlus className="text-xl" />
                  ) : (
                    <CiCircleMinus className="text-xl" />
                  )}
                  {d.year}
                </Table.Cell>

                <Table.Cell>
                  Rs. {commaSeparatedNum(d?.totalPrincipal)}
                </Table.Cell>

                <Table.Cell>
                  Rs. {commaSeparatedNum(d?.totalInterest)}
                </Table.Cell>
                <Table.Cell>
                  Rs. {commaSeparatedNum(d?.totalPayment)}
                </Table.Cell>
                <Table.Cell>
                  Rs. {commaSeparatedNum(d?.totalBalance)}
                </Table.Cell>
              </Table.Row>
              {year === d.year &&
                d?.data?.map((item, index) => (
                  <Table.Row key={index} className="text-black bg-gray-100">
                    <Table.Cell>{item?.month}</Table.Cell>
                    <Table.Cell>
                      Rs. {commaSeparatedNum(item?.principal)}
                    </Table.Cell>
                    <Table.Cell>
                      Rs. {commaSeparatedNum(item?.interest)}
                    </Table.Cell>
                    <Table.Cell>
                      Rs. {commaSeparatedNum(item?.payment)}
                    </Table.Cell>
                    <Table.Cell>
                      Rs. {commaSeparatedNum(item?.balance)}
                    </Table.Cell>
                  </Table.Row>
                ))}
            </>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};
export default Tool;
