import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, FileInput, Label, TextInput } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useAlert } from "../../../_contexts/AlertContext";
import { getTeam, patchTeam, postTeam, upload } from "../../../apis";
const TeamCreate = () => {
  const navigate = useNavigate();
  const [_fileName, setFileName] = useState("");
  const { setAlert } = useAlert();
  const { teamId } = useParams();

  const role = JSON.parse(localStorage.getItem("gp_user")).user.role;
  useEffect(() => {
    if (role === "user") return navigate("/admin/user/1");
  }, [role, navigate]);
  const { mutate } = useMutation({
    mutationKey: ["teams", ...(teamId ? [teamId] : [])],
    mutationFn: teamId ? patchTeam : postTeam,
    onSuccess: () => {
      navigate("/admin/team");
    },
  });
  const { data } = useQuery({
    queryKey: ["team", teamId],
    queryFn: () => getTeam(teamId),
    enabled: !!teamId,
  });

  const { register, handleSubmit, setValue } = useForm({
    mode: "onBlur",
    defaultValues: {},
    // resolver: yupResolver(propertySchema()),
  });
  useEffect(() => {
    if (data?.data?.image) {
      //
      setValue("image", data?.data?.image);
    }
  }, [data, setValue]);

  function onSubmit(data) {
    mutate(teamId ? { data, id: teamId } : data);
  }
  //
  return (
    <>
      <form className="md:py-8 py-10 flex flex-col gap-y-8">
        <fieldset className="grid md:grid-cols-2 grid-cols-1 gap-4">
          <legend className="text-2xl font-medium text-lime-700 col-span-2">
            Team Information
          </legend>
          <div>
            <Label htmlFor="name">Name</Label>
            <TextInput
              type="text"
              placeholder="Enter name of User"
              defaultValue={data?.data?.name}
              {...register("name")}
            />
          </div>

          <div>
            <Label htmlFor="image">Image</Label>
            <FileInput
              accept="image/*"
              id="file-upload"
              onChange={upload("image", setValue, setAlert, setFileName)}
            />
          </div>
          <div>
            <Label htmlFor="position">Position</Label>
            <TextInput
              type="text"
              placeholder="Enter position of user"
              defaultValue={data?.data?.position}
              {...register("position")}
            />
          </div>
          <div>
            <Label htmlFor="position">Phone Number</Label>
            <TextInput
              type="tel"
              placeholder="Enter Phone Number"
              defaultValue={data?.data?.phoneNumber}
              {...register("phone")}
            />
          </div>
        </fieldset>
        <div className="flex justify-end pt-8">
          <Button
            className="w-1/2"
            color="success"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default TeamCreate;
