import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop";
import Footer from "./Footer";
import Header from "./Header";

const DefaultLayout = () => {
  const mainRef = useRef(null);
  const containerRef = useRef(null);
  const location = useLocation();
  const pathname = location.pathname === "/";

  useEffect(() => {
    if (location.pathname) {
      mainRef.current.scrollTo(0, 0);
      mainRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [location.pathname]);

  const [hideHeader, setHideHeader] = useState(pathname);
  useEffect(() => {
    const target = mainRef?.current;
    function hideHeaderFn() {
      if (target) {
        if (mainRef?.current.scrollTop === 0) setHideHeader(true);
        else setHideHeader(false);
      }
    }
    target && target?.addEventListener("scroll", hideHeaderFn);
    return () => target && target?.removeEventListener("scroll", hideHeaderFn);
  }, []);

  useEffect(() => {
    let container = containerRef.current;
    function mouseOverFn(e) {
      if (e.clientY < 100) {
        setHideHeader(false);
      }
    }
    if (container) {
      container.addEventListener("mouseover", mouseOverFn);
    }
    return () => container?.removeEventListener("mouseover", mouseOverFn);
  }, []);
  return (
    <div className="relative" ref={containerRef}>
      <ScrollToTop />
      <Header
        contentRef={pathname ? mainRef : null}
        hideHead={
          ["/about", "/emi-calc", /\/contact/g].includes(location.pathname)
            ? false
            : hideHeader
        }
      />
      <main
        ref={mainRef}
        className="relative h-screen overflow-auto ScrollbarHide"
        style={{
          ...(window.matchMedia("(min-width: 768px)").matches && {
            scrollSnapType: `${
              ["about", "emi-calc", "contact"].includes(
                location.pathname.split("/")[1]
              )
                ? "none"
                : "y mandatory"
            }`,
          }),
        }}
      >
        <Outlet />
        <Footer contentRef={mainRef} />
      </main>
      <div className="absolute bottom-8 right-8">
        {/* <a
          aria-label="Chat on WhatsApp"
          target="_blank"
          rel="noreferrer"
          href="https://wa.me/9818994768"
        >
          <img
            alt="Chat on WhatsApp"
            src="/assets/WhatsAppButtonGreenMedium.png"
            className="w-48"
          />
        </a> */}
      </div>
    </div>
  );
};

export default DefaultLayout;
