import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { Button, Label, Radio, Textarea, TextInput } from "flowbite-react";
import React from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { FaUser } from "react-icons/fa";
import { HiMail } from "react-icons/hi";
import { IoMailOutline } from "react-icons/io5";
import { MdLocalPhone } from "react-icons/md";
import { useParams } from "react-router-dom";
import EMICalculator from "../../_helpers/EMICalculator";
import commaSeparatedNum from "../../_helpers/commaSeparatedNum";
import { enquirySchema } from "../../_helpers/formSchema";
import htmlToText from "../../_helpers/htmlToText";
import { getPropertyFromPropertyCode, postCustomer } from "../../apis";
import Gallery from "../../components/Gallery";
const Contact = () => {
  const { propertyCode } = useParams();

  // eslint-disable-next-line no-unused-vars
  const { data, isLoading } = useQuery({
    queryKey: ["property", propertyCode],
    queryFn: getPropertyFromPropertyCode(propertyCode),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(enquirySchema),
  });
  const [thanks, setThanks] = React.useState(false);

  const handleSubmitForm = async (data) => {
    if (data) {
      await postCustomer(data);
    }
    setThanks(true);
    reset();
  };

  return !isLoading ? (
    <>
      <Helmet>
        <title>{data?.data?.name}</title>
        <meta
          name="description"
          content={htmlToText(data?.data?.description)}
        />
        <meta
          name="keywords"
          content={data?.data?.otherDetails?.map((d) => d.name).join(", ")}
        />
        <meta property="og:title" content={data?.data?.name} />
        <meta property="og:type" content={"website"} />
        <meta
          property="og:url"
          content={`https://gprealestate.com.np/contact/${propertyCode}`}
        />
        <meta property="og:image" content={data?.data?.images?.[0]?.src} />
        <meta
          property="og:description"
          content={htmlToText(data?.data?.description)}
        />
      </Helmet>
      <div className="w-screen min-h-screen bg-slate-100 py-16 grid place-content-center">
        {!thanks ? (
          <div className="grid md:grid-cols-2 gap-16 place-content-center place-items-center md:w-4/5 mx-auto">
            <div>
              <div className="overflow-hidden">
                {/* <img
                src={data?.data?.images?.[0]?.src}
                alt=""
                className="aspect-video object-contain object-center hover:p-0  hover:scale-105 transition-all duration-150 overflow-hidden"
              /> */}
                <Gallery image={data?.data?.images} className="" />
              </div>
              <div className="text-lime-800 space-y-1 mt-6">
                <h1 className="text-xl font-bold">{data?.data?.name}</h1>
                <p className="text-lg font-semibold text-slate-500">
                  {data?.data?.location?.district},{" "}
                  {data?.data?.location?.province}
                </p>
                <h2
                  className={[
                    "text-xl md:text-2xl font-semibold text-lime-600 py-2",
                    // "line-through decoration-red-500",
                  ]}
                >
                  {data?.data?.price?.unit}{" "}
                  {commaSeparatedNum(data?.data?.price?.value)} /-{" "}
                  <span className="pl-4 text-neutral-500 text-base">
                    ({data?.data?.price?.unit}{" "}
                    {commaSeparatedNum(
                      EMICalculator(data?.data?.price?.value, 8, 60, "month")
                        .emi
                    )}{" "}
                    /mo*)
                    <span className="text-xs pl-4 text-neutral-400">
                      *monthly EMI may vary depending different rates
                    </span>
                  </span>
                  {/* <span className="text-red-500">{"Sold"}</span> */}
                </h2>
              </div>
            </div>
            <div className="bg-slate-50 p-4 rounded-md space-y-4">
              <div className="flex gap-x-4 justify-center items-center">
                <IoMailOutline
                  className="p-2 shadow-md rounded-full text-primary"
                  fontSize={"44px"}
                />
                <div>
                  <h2 className="text-xl font-semibold capitalize text-primary">
                    Get in touch
                  </h2>
                  <p className="text-slate-400">
                    Leave your message here and we'll get back to you
                  </p>
                </div>
              </div>
              <form
                className="flex flex-col min-w-sm space-y-2"
                onSubmit={handleSubmit(handleSubmitForm)}
              >
                <div>
                  {/* <div className="mb-2 block">
                  <Label htmlFor="email4" value="Email" />
                </div> */}
                  <TextInput
                    id="email4"
                    type="email"
                    icon={HiMail}
                    placeholder="Email"
                    {...register("email", { required: true })}
                  />
                  {errors.email && (
                    <span className="text-red-700">
                      This field is required.
                    </span>
                  )}
                </div>
                <div>
                  {/* <div className="mb-2 block">
                  <Label htmlFor="name" value="Name" />
                </div> */}
                  <TextInput
                    id="name"
                    type="text"
                    icon={FaUser}
                    placeholder="Full Name"
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <span className="text-red-700">
                      This field is required.
                    </span>
                  )}
                </div>
                <div>
                  {/* <div className="mb-2 block">
                  <Label htmlFor="phone" value="Phone" />
                </div> */}
                  <TextInput
                    id="phone"
                    type="phone"
                    icon={MdLocalPhone}
                    placeholder="Phone Number"
                    {...register("phone", { required: true })}
                  />
                  {errors.phone && (
                    <span className="text-red-700">
                      This field is required.
                    </span>
                  )}
                </div>
                <div>
                  {/* <div className="mb-2 block">
                  <Label htmlFor="message" value="Message" />
                </div> */}
                  <Textarea
                    id="message"
                    placeholder="Leave a message..."
                    rows={3}
                    {...register("message")}
                  />
                </div>
                <fieldset className="flex max-w-md flex-col gap-4 pb-2">
                  <Label htmlFor="customerType" value="I am" />
                  <div className="flex justify-between">
                    <div className="flex items-center gap-2">
                      <Radio
                        id="buyer"
                        name="customerType"
                        value="buyer"
                        defaultChecked
                        {...register("type")}
                      />
                      <Label htmlFor="buyer">Buyer/Tenant</Label>
                    </div>
                    <div className="flex items-center gap-2">
                      <Radio
                        id="agent"
                        name="customerType"
                        value="agent"
                        {...register("type")}
                      />
                      <Label htmlFor="agent">Agent</Label>
                    </div>
                    <div className="flex items-center gap-2">
                      <Radio
                        id="Other"
                        name="customerType"
                        value="other"
                        {...register("type")}
                      />
                      <Label htmlFor="Other">Other</Label>
                    </div>
                  </div>
                </fieldset>{" "}
                <Button type="submit" color="primary">
                  Submit
                </Button>
              </form>
            </div>
          </div>
        ) : (
          <div className="w-screen h-screen flex justify-center items-center">
            <h1 className="text-3xl font-bold text-slate-500">Thank You</h1>
          </div>
        )}
      </div>
    </>
  ) : (
    <>loading...</>
  );
};

export default Contact;
