import { useQuery } from "@tanstack/react-query";
import { getContent, getPartners } from "../../apis";
export default function OurPartner() {
  const { data } = useQuery({
    queryKey: ["partner"],
    queryFn: getPartners,
  });
  const { data: partnerData } = useQuery({
    queryKey: ["content"],
    queryFn: () => getContent(),
  });
  return (
    <>
      <section
        id="why-us"
        className="md:h-screen md:flex md:flex-col md:justify-center"
        style={{ scrollSnapAlign: "center" }}
      >
        <div className={["min-h-[70dvh] grid items-center py-8"].join(" ")}>
          <div className="text-center md:space-y-2 mb-4 md:mb-auto">
            <h2 className="text-2xl md:text-4xl font-semibold mt-8 md:mt-auto text-primary">
              {partnerData?.data?.partner?.mainText || "Our Partner"}
            </h2>
            <p className="text-sm md:text-base font-medium px-4 md:px-auto">
              {partnerData?.data?.partner?.subText ||
                " Our dedicated team works closely with you to understand your specific requirements and goals."}
            </p>
          </div>
          <div className="w-4/5 mx-auto grid grid-cols-2 md:grid-cols-4 gap-4 mb-4 md:mb-auto">
            {data?.data?.results?.map((d) => (
              <div
                className="text-center md:aspect-square flex flex-col items-center justify-center rounded-xl"
                key={d?.id}
              >
                <div className="aspect-square grid place-content-center mb-8 p-8 relative isolate h-52 w-52">
                  <img
                    src={d?.image}
                    alt={d?.image}
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
