import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { CiCalendar } from "react-icons/ci";
import { useParams } from "react-router-dom";
import { getUsers } from "../../../../apis";
import Table from "../../../../components/Table";

const UserInfo = () => {
  const { id } = useParams();
  const { isLoading, data } = useQuery({
    queryKey: ["users"],
    queryFn: () => getUsers(),
  });
  const [show, setShow] = useState("");

  const { pageNum } = useParams();
  const role = JSON.parse(localStorage.getItem("gp_user")).user.role;
  const [page, setPage] = useState(pageNum || 1);
  const user = data?.data?.results?.filter((d) => d._id === id);

  const headers = [
    {
      key: "postedOn",
      title: "Posted On",
    },
    {
      key: "property",
      title: "Property",
    },
    {
      key: "status",
      title: "Status",
    },

    {
      key: "_action",
      title: "Actions",
      //   actions: {
      //     delete: (data) => deleteProp({ id: data?._id }),
      //   },
      role: role,
      // actions: { delete: null },
    },
  ];
  const tab = [
    {
      label: "Overview",
      content: (
        <div>
          <p>Account Information</p>
          <div className="w-40 h-40">
            <img src="" alt="" />
          </div>
          <div>
            {user?.map((u) => (
              <div className="flex gap-x-10">
                <div className="space-y-4">
                  <p>Title</p>
                  <p>Role</p>
                  <p>Phone</p>
                  <p>Email</p>
                </div>
                <div className="space-y-4">
                  <p>{u?.name}</p>
                  <p className=" text-lime-700 font-semibold capitalize">
                    {u?.role}
                  </p>
                  <p>{u?.phone}</p>
                  <p className="text-lime-700 font-semibold">{u?.email}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ),
    },
    {
      label: "Activity Logs",
      content: (
        <div>
          <div>
            <p
              className="flex gap-x-4 items-center"
              onClick={() => setShow("2024/02/23")}
            >
              <CiCalendar fontSize={"20px"} /> 2024/02/23
            </p>
            {show === "2024/02/23" && (
              <div className="pl-8">
                <p>02:56 PM</p>
                <p className="text-lime-600 font-semibold">Enquiry Form</p>
                <div className="flex gap-x-4">
                  <div className="space-y-2">
                    <p>Enquiry Property</p>
                    <p>Property Id</p>
                    <p>Added By</p>
                  </div>
                  <div className="space-y-2">
                    <p className="text-lime-600 font-semibold">
                      House for sale
                    </p>
                    <p className="text-slate-600">2345</p>
                    <p>Bishal gaihre</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      label: "Posted Property",
      content: (
        <div className="overflow-scroll md:overflow-auto scrollbar-hide">
          {}
          <Table
            headers={headers}
            data={[]}
            // checkState={checkState}
            isLoading={isLoading}
            page={page}
            setPage={setPage}
            total={data?.data?.totalPages || 1}
            navigateLink={{ link: `/admin/settings/user/details`, id: true }}
            // withCheckBoxes
            //
          />
        </div>
      ),
    },
  ];
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabClick = (index) => {
    setActiveTabIndex(index);
  };
  const Tab = ({ isActive, label, onClick }) => {
    return (
      <button
        className={`px-4 py-2 focus:outline-none ${
          isActive ? "border-b-2 border-blue-500 font-bold" : "text-gray-500"
        }`}
        onClick={onClick}
      >
        {label}
      </button>
    );
  };
  return (
    <div className="w-full mx-auto mt-4">
      <div className="flex border-b border-gray-200">
        {tab.map((tab, index) => (
          <Tab
            key={index}
            isActive={index === activeTabIndex}
            label={tab.label}
            onClick={() => handleTabClick(index)}
          />
        ))}
      </div>
      <div className="p-4">
        {tab[activeTabIndex] && tab[activeTabIndex].content}
      </div>
    </div>
  );
};

export default UserInfo;
