import { useMutation, useQuery } from "@tanstack/react-query";
import { Avatar, Button } from "flowbite-react";
import { useState } from "react";
import { HiPlus } from "react-icons/hi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAlert } from "../../../../_contexts/AlertContext";
import { deleteUser, getUsers } from "../../../../apis";
import Table from "../../../../components/Table";

function User() {
  const navigate = useNavigate();
  const { pageNum } = useParams();
  const { setAlert } = useAlert();
  const role = JSON.parse(localStorage.getItem("gp_user")).user.role;
  const [page, setPage] = useState(pageNum || 1);
  const { isLoading, data, refetch } = useQuery({
    queryKey: ["users"],
    queryFn: () => getUsers(),
  });
  const { mutate: deleteProp } = useMutation({
    mutationKey: ["user"],
    mutationFn: deleteUser,
    onSuccess: () => {
      setAlert({
        type: "success",
        message: "User Deleted Successfully",
        isOpen: true,
      });
      refetch();
    },
  });

  const headers = [
    {
      key: "name",
      title: "Name",
      render: (data) => (
        <Link
          to={`/admin/settings/user/details/${data?._id}`}
          className="flex gap-x-3 items-center"
        >
          <Avatar
            img={data?.image || `/assets/avatar.png`}
            alt={data?.name}
            rounded={false}
          />{" "}
          {data?.name}
        </Link>
      ),
    },
    {
      key: "phone",
      title: "Phone",
    },
    {
      key: "email",
      title: "Email",
    },
    {
      key: "role",
      title: "Role",
    },
    {
      key: "_action",
      title: "Actions",
      actions: {
        delete: (data) => deleteProp({ id: data?._id }),
      },
      role: role,
      // actions: { delete: null },
    },
  ];

  return (
    <>
      <div className="flex justify-between items-center flex-row md:pb-0 pb-4 md:py-0 py-12">
        <h2 className="md:text-3xl text-2xl font-semibold md:mb-6 mb-0">
          Users
        </h2>
        {role !== "user" && (
          <Button
            outline
            gradientDuoTone="greenToBlue"
            pill
            onClick={() => navigate("/admin/user/create")}
          >
            <HiPlus className="mr-2 h-5 w-5" />
            Add User
          </Button>
        )}
      </div>
      <div className="overflow-scroll md:overflow-auto scrollbar-hide">
        {}
        <Table
          headers={headers}
          data={data?.data?.results}
          // checkState={checkState}
          isLoading={isLoading}
          page={page}
          setPage={setPage}
          total={data?.data?.totalPages || 1}
          // withCheckBoxes
          //
        />
      </div>
    </>
  );
}

export default User;
