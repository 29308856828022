import { useMutation, useQuery } from "@tanstack/react-query";
import { Badge, Button, Label, Modal, Select } from "flowbite-react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import FullDate from "../../../_helpers/date";
import { getLeads, postLead } from "../../../apis";
import Table from "../../../components/Table";
const Lead = () => {
  const { isLoading, data, refetch } = useQuery({
    queryKey: ["leads"],
    queryFn: () => getLeads(),
  });

  const leadData = data?.data
    ?.filter((item) => item?.customer?.name)
    .map((d) => ({
      _id: d?.customer?._id,
      lead: d?.customer?.name,
      source: "Enquiry Form",
      status: d?.customer?.lead,
      phone: d?.customer?.phone,
      message: d?.messages[0].message,
      createdOn: FullDate(d?.customer?.createdAt),
      lastActionOn: FullDate(d?.customer?.updatedAt),
    }));

  const { mutate } = useMutation({
    mutationKey: ["lead"],
    mutationFn: postLead,
    onSuccess: () => {
      refetch();
    },
  });

  const [openModal, setOpenModal] = useState(false);
  const [customerId, setCustomerId] = useState("");

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const { register, handleSubmit } = useForm({
    mode: "onBlur",
    defaultValues: data?.data,
  });

  function onCloseModal() {
    setOpenModal(false);
  }
  const onSubmit = (data) => {
    mutate({ customerId: customerId, data: data });
    setOpenModal(false);
  };
  //
  const { pageNum } = useParams();
  // const { setAlert } = useAlert();
  const role = JSON.parse(localStorage.getItem("gp_user")).user.role;
  const [page, setPage] = useState(pageNum || 1);
  // const user = data?.data?.results?.filter((d) => d._id === id);
  //
  const headers = [
    {
      key: "lead",
      title: "Lead",
      render: (data) => <Link to="/admin/lead/1">{data?.lead}</Link>,
    },

    {
      key: "status",
      title: "Status",
      render: (data) => (
        <Badge
          color={`${
            data?.status === "hot"
              ? "red"
              : data?.status === "cold"
              ? "blue"
              : data?.status === "warm"
              ? "warning"
              : data?.status === "lost"
              ? "gray"
              : "success"
          }`}
          className="flex justify-center p-2"
        >
          {data?.status}
        </Badge>
      ),
    },
    {
      key: "source",
      title: "Source",
    },
    {
      key: "phone",
      title: "Phone",
    },
    {
      key: "message",
      title: "Message",
    },
    {
      key: "createdOn",
      title: "Created On",
    },
    {
      key: "lastActionOn",
      title: "Last Action On",
    },
    {
      key: "_action",
      title: "Actions",
      actions: {
        edit: (data) => {
          setOpenModal(true);
          setCustomerId(data?._id);
        },
      },
      role: role,
      // actions: { delete: null },
    },
  ];
  const tab = [
    {
      label: "All",
      content: (
        <div className="overflow-scroll md:overflow-auto scrollbar-hide">
          {/* {} */}
          <Table
            headers={headers}
            data={leadData}
            // checkState={checkState}
            isLoading={isLoading}
            page={page}
            setPage={setPage}
            total={data?.data?.totalPages || 1}
            // withCheckBoxes
            //
          />
        </div>
      ),
    },
    {
      label: "Hot",
      content: (
        <div className="overflow-scroll md:overflow-auto scrollbar-hide">
          <Table
            headers={headers}
            data={leadData?.filter((d) => d?.status === "hot")}
            // checkState={checkState}
            isLoading={isLoading}
            page={page}
            setPage={setPage}
            total={data?.data?.totalPages || 1}
            // withCheckBoxes
            //
          />
        </div>
      ),
    },
    {
      label: "Warm",
      content: (
        <div className="overflow-scroll md:overflow-auto scrollbar-hide">
          <Table
            headers={headers}
            data={leadData?.filter((d) => d?.status === "warm")}
            // checkState={checkState}
            isLoading={isLoading}
            page={page}
            setPage={setPage}
            total={data?.data?.totalPages || 1}
            // withCheckBoxes
            //
          />
        </div>
      ),
    },
    {
      label: "Cold",
      content: (
        <div className="overflow-scroll md:overflow-auto scrollbar-hide">
          <Table
            headers={headers}
            data={leadData?.filter((d) => d?.status === "cold")}
            // checkState={checkState}
            isLoading={isLoading}
            page={page}
            setPage={setPage}
            total={data?.data?.totalPages || 1}
            // withCheckBoxes
            //
          />
        </div>
      ),
    },
    {
      label: "Closed",
      content: (
        <div className="overflow-scroll md:overflow-auto scrollbar-hide">
          <Table
            headers={headers}
            data={leadData?.filter((d) => d?.status === "closed")}
            // checkState={checkState}
            isLoading={isLoading}
            page={page}
            setPage={setPage}
            total={data?.data?.totalPages || 1}
            // navigateLink={{ link: `/admin/settings/user/details`, id: true }}
            // withCheckBoxes
            //
          />
        </div>
      ),
    },
    {
      label: "Lost",
      content: (
        <div className="overflow-scroll md:overflow-auto scrollbar-hide">
          <Table
            headers={headers}
            data={leadData?.filter((d) => d?.status === "lost")}
            // checkState={checkState}
            isLoading={isLoading}
            page={page}
            setPage={setPage}
            total={data?.data?.totalPages || 1}
            // navigateLink={{ link: `/admin/settings/user/details`, id: true }}
            // withCheckBoxes
            //
          />
        </div>
      ),
    },
  ];

  const handleTabClick = (index) => {
    setActiveTabIndex(index);
  };
  const Tab = ({ isActive, label, onClick }) => {
    return (
      <button
        className={`px-4 py-2 focus:outline-none ${
          isActive ? "border-b-2 border-blue-500 font-bold" : "text-gray-500"
        }`}
        onClick={onClick}
      >
        {label}
      </button>
    );
  };
  return (
    <div className="w-full mx-auto mt-4">
      <div className="flex border-b border-gray-200 justify-between">
        <h2 className="text-2xl font-semibold rounded-xl py-2 px-4text-primary">
          Leads
        </h2>
        <div>
          {tab?.map((tab, index) => (
            <Tab
              key={index}
              isActive={index === activeTabIndex}
              label={tab.label}
              onClick={() => handleTabClick(index)}
            />
          ))}
        </div>
        <Modal show={openModal} size="md" onClose={onCloseModal} popup>
          {/* {} */}
          <Modal.Header>Change Status</Modal.Header>
          <Modal.Body>
            <form className="space-y-6">
              <div>
                <div className="mb-2 block">
                  <Label value="Change Status" />
                </div>
                <Select {...register("lead")}>
                  <option value="hot">Hot</option>
                  <option value="warm">Warm</option>
                  <option value="cold">Cold</option>
                  <option value="closed">Closed</option>
                  <option value="lost">Lost</option>
                </Select>
              </div>
              <div className="flex gap-x-2">
                <Button color="primary" onClick={handleSubmit(onSubmit)}>
                  Change Status
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        ;
      </div>
      <div className="p-4">
        {tab[activeTabIndex] && tab[activeTabIndex].content}
      </div>
    </div>
  );
};

export default Lead;
