import * as Yup from "yup";
import { number, object, string } from "yup";
export const propertySchema = () =>
  object().shape({
    category: string().required(),
    subCategory: string().required(),
    type: string().required(),
    ownerShip: string().required(),
    name: string().required(),
    price: object({
      value: string().required(),
      unit: string().required(),
    }),
    furnishedStatus: string().required(),
    facing: string().required(),
    builtYear: object({
      year: string().required(),
      type: string().required(),
    }),
    coordinate: string().required(),
    description: string().min(100).required(),
    location: Yup.object().shape({
      province: Yup.string().required("Province is required"),
      district: Yup.string().required("District is required"),
      municipal: Yup.string().required("Municipal is required"),
      ward: Yup.string().required("Ward is required"),
    }),
  });

export const contentSchema = object().shape({
  hero: object({
    mainText: string().required(),
    subText: string().required(),
  }),
  featured: object({
    mainText: string().required(),
    subText: string().required(),
  }),
  cities: object({
    mainText: string().required(),
    subText: string().required(),
  }),
  whyUs: object({
    mainText: string().required(),
    subText: string().required(),
  }),
  aboutUs: object({
    mainText: string().required(),
    subText: string().required(),
    contentMainText: string().required(),
    content: string().required(),
  }),
});

export const enquirySchema = object().shape({
  email: string().email().required(),
  name: string().required(),
  phone: number()
    .positive("phone number must be postive")
    .integer("Phone number must be integer")
    .required("Phone number is required."),
});
