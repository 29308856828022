import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getMe } from "../../apis";
import Loader from "../../components/Loader";
import ScrollToTop from "../../components/ScrollToTop";
import SidebarComponent from "./Sidebar";

const AdminLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoading, data } = useQuery({
    queryKey: ["me"],
    queryFn: getMe,
  });

  const getUser = useCallback(() => {
    let user = data?.data;
    if (!isLoading && !user) {
      navigate("/login");
    }
  }, [data, isLoading, navigate]);

  useEffect(() => {
    getUser();
  }, [getUser]);
  useEffect(() => {
    if (location.pathname === "/admin") {
      navigate("/admin/dashboard");
    }
  }, [location, navigate]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) return <Loader />;

  return (
    <>
      <Helmet>
        <ScrollToTop />
        <title>Admin | GP Real Estate</title>
      </Helmet>
      <main className="min-h-screen md:flex block">
        {data?.data && <SidebarComponent />}
        <section className="md:grow p-4 overflow-hidden">
          <Outlet />
        </section>
      </main>
    </>
  );
};

export default AdminLayout;
