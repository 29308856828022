import { useMutation, useQuery } from "@tanstack/react-query";
import { Button } from "flowbite-react";
import { useState } from "react";
import { HiPlus } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import { useAlert } from "../../../_contexts/AlertContext";
import { deleteTeam } from "../../../apis";
import Table from "../../../components/Table";
function SEO() {
  const navigate = useNavigate();
  const { pageNum } = useParams();
  const { setAlert } = useAlert();
  const role = JSON.parse(localStorage.getItem("gp_user")).user.role;
  const [page, setPage] = useState(pageNum || 1);
  const { data, refetch } = useQuery({
    queryKey: ["seo"],
    // queryFn: () => getSeo(),
  });
  const { mutate: deleteProp } = useMutation({
    mutationKey: ["deleteSeo"],
    mutationFn: deleteTeam,
    onSuccess: () => {
      setAlert({
        type: "success",
        message: "Seo Deleted Successfully",
        isOpen: true,
      });
      refetch();
    },
  });

  const teamData = data?.data?.results;
  const headers = [
    {
      key: "title",
      title: "Title",
    },
    {
      key: "propertyId",
      title: "PropertyId",
    },
    {
      key: "description",
      title: "Description",
    },
    {
      key: "keywords",
      title: "Keywords",
    },
    {
      key: "_action",
      title: "Actions",
      actions: {
        edit: (data) => navigate(`/admin/update/team/${data?._id}`),
        delete: (data) => deleteProp({ id: data?._id }),
      },
      role: role,
    },
  ];

  // const checkState = useState([]);

  return (
    <>
      <div className="flex justify-between items-center flex-row md:pb-0 pb-4 md:py-0 py-12">
        <h2 className="md:text-3xl text-2xl font-semibold md:mb-6 mb-0">SEO</h2>
        {role !== "user" && (
          <Button
            outline
            gradientDuoTone="greenToBlue"
            pill
            onClick={() => navigate("/admin/create/seo")}
          >
            <HiPlus className="mr-2 h-5 w-5" />
            Add SEO
          </Button>
        )}
      </div>
      <div className="overflow-scroll md:overflow-auto scrollbar-hide">
        <Table
          headers={headers}
          data={teamData}
          // checkState={checkState}
          isLoading={false}
          page={page}
          setPage={setPage}
          total={1}
          // withCheckBoxes
          //
        />
      </div>
    </>
  );
}

export default SEO;
