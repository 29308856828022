import { Button, Navbar } from "flowbite-react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import DropDown from "../../components/DropDown";

export default function NavbarWithCTAButton({ hideHead = false }) {
  const navigate = useNavigate();
  return (
    <Navbar
      className={[
        "fixed z-30 w-[100dvw] shadow-big ",
        hideHead ? "-translate-y-full" : "translate-y-0",
        "transition-transform duration-500",
      ].join(" ")}
    >
      <Navbar.Brand to="/" as={Link}>
        <img
          alt="GP Real Estate Logo"
          className="md:mr-3 h-9"
          src="/assets/gp_logo.png"
        />
        <span className="text-base md:text-xl sm:inline self-center whitespace-nowrap text-lime-700  font-semibold dark:text-white">
          GP Real Estate Services
        </span>
      </Navbar.Brand>
      <div className="flex md:order-2">
        <Button
          className="md:block hidden"
          color="primary"
          onClick={() => navigate("/listing")}
        >
          Get started
        </Button>
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        <Navbar.Link as={Link} to="/">
          Home
        </Navbar.Link>
        <Navbar.Link>
          <DropDown
            label="Properties"
            items={[
              {
                key: 3,
                title: "House",
                path: "/listing?category=house",
              },
              {
                key: 1,
                title: "Apartments",
                path: "/listing?category=apartment",
              },
              {
                key: 2,
                title: "Land",
                path: "/listing?category=land",
              },
            ]}
          />
        </Navbar.Link>
        <Navbar.Link as={Link} to="/about">
          About
        </Navbar.Link>
        <Navbar.Link href="#footer">Contact</Navbar.Link>
        <Navbar.Link>
          <DropDown
            label="Tools"
            items={[
              {
                key: 4,
                title: "EMI Calculator",
                path: "/emi-calc",
              },
            ]}
          />
        </Navbar.Link>
      </Navbar.Collapse>
    </Navbar>
  );
}
