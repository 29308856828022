import { Dropdown as DD } from 'flowbite-react';
import { Link } from 'react-router-dom';

function DropDown({ label, items }) {
	return (
		<DD placement="bottom" inline label={label}>
			{items?.map((item) => (
				<DD.Item as={Link} to={item?.path} key={item?.key}>
					{item?.title}
				</DD.Item>
			))}
		</DD>
	);
}

export default DropDown;
