export default function FullDate(timestamp){
    const date = new Date(timestamp);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
      const formattedDate= new Intl.DateTimeFormat('en-US', options).format(date);
      return formattedDate.replace(/\//g, '-').replace(',', '');
  };
  